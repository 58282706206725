// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextFieldComponent-module__text-field-wrap__EE2z9{width:100%}.TextFieldComponent-module__text-field-wrap__EE2z9 .TextField_labelPosition_top{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/UI/TextField/TextFieldComponent.module.scss"],"names":[],"mappings":"AAAA,mDACE,UAAA,CAEA,gFACE,UAAA","sourcesContent":[".text-field-wrap {\r\n  width: 100%;\r\n\r\n  & :global(.TextField_labelPosition_top) {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-field-wrap": `TextFieldComponent-module__text-field-wrap__EE2z9`
};
export default ___CSS_LOADER_EXPORT___;
