// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmptySchedule-module__body-empty-schedule__JgxwN{width:100%;height:200px;display:flex;flex-direction:column;align-items:center;justify-content:center}.EmptySchedule-module__default-schedule-schedule__pOM1P{display:flex;align-items:center;justify-content:center;width:100%;height:150px}.EmptySchedule-module__default-schedule-schedule__pOM1P img{width:90%}.EmptySchedule-module__empty-text-schedule__sCWdQ{font-size:18px;color:#868686}`, "",{"version":3,"sources":["webpack://./src/components/EmptyScheduleComponent/EmptySchedule.module.scss"],"names":[],"mappings":"AAAA,kDACE,UAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,wDACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,YAAA,CAEA,4DACE,SAAA,CAIJ,kDACE,cAAA,CACA,aAAA","sourcesContent":[".body-empty-schedule {\r\n  width: 100%;\r\n  height: 200px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.default-schedule-schedule {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  width: 100%;\r\n  height: 150px;\r\n\r\n  img {\r\n    width: 90%;\r\n  }\r\n}\r\n\r\n.empty-text-schedule {\r\n  font-size: 18px;\r\n  color: #868686;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body-empty-schedule": `EmptySchedule-module__body-empty-schedule__JgxwN`,
	"default-schedule-schedule": `EmptySchedule-module__default-schedule-schedule__pOM1P`,
	"empty-text-schedule": `EmptySchedule-module__empty-text-schedule__sCWdQ`
};
export default ___CSS_LOADER_EXPORT___;
