import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import s from './GaugeComponent.module.scss';
import { Gauge } from '@consta/charts/Gauge';
import { useTranslation } from 'react-i18next';
export const GaugeComponent = ({ percent, isText = false, }) => {
    const { t } = useTranslation();
    const options = {
        percent: percent / 100,
        range: {
            color: percent === 0 ? 'rgba(211, 211, 211, 1)' : 'rgba(0, 120, 210, 1)',
            width: 4,
        },
        axis: {
            tickLine: null,
            label: null,
        },
        indicator: {
            pointer: {
                style: {
                    stroke: '#D0D0D0',
                    lineWidth: 2,
                },
            },
            pin: {
                style: {
                    stroke: '#D0D0D0',
                    r: 3.2,
                    lineWidth: 2,
                },
            },
        },
        gaugeStyle: {
            lineCap: 'round',
        },
    };
    return (_jsxs("div", { className: s['wrap-gauge'], children: [_jsx(Gauge, { ...options }), _jsxs("div", { className: `${isText ? s['wrap-text'] : s['accuracy-value']}`, children: [_jsxs("span", { children: [Math.round(percent), "%"] }), isText && _jsx("span", { children: t('Accuracy') })] })] }));
};
