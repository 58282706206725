// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BusinessMetric-module__businessMetric__bcJMH{padding:24px;background-color:#fff;box-shadow:0px 4px 24px -10px rgba(0,88,137,.4);border-radius:12px;display:flex;flex-direction:column;gap:24px}.BusinessMetric-module__metric-label__loKrp{font-size:20px;font-weight:600;line-height:24px;letter-spacing:0em;color:rgba(0,32,51,.6)}.BusinessMetric-module__items__AEK0A{display:flex;gap:24px;flex-wrap:wrap}`, "",{"version":3,"sources":["webpack://./src/components/BusinessMetric/BusinessMetric.module.scss"],"names":[],"mappings":"AAAA,8CACE,YAAA,CACA,qBAAA,CACA,+CAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,4CACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,qCACE,YAAA,CACA,QAAA,CACA,cAAA","sourcesContent":[".businessMetric {\r\n  padding: 24px;\r\n  background-color: #FFFFFF;\r\n  box-shadow: 0px 4px 24px -10px rgba(0, 88, 137, 0.40);\r\n  border-radius: 12px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 24px;\r\n}\r\n\r\n.metric-label {\r\n  font-size: 20px;\r\n  font-weight: 600;\r\n  line-height: 24px;\r\n  letter-spacing: 0em;\r\n  color: rgba(0, 32, 51, 0.6)\r\n}\r\n\r\n.items {\r\n  display: flex;\r\n  gap: 24px;\r\n  flex-wrap: wrap;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"businessMetric": `BusinessMetric-module__businessMetric__bcJMH`,
	"metric-label": `BusinessMetric-module__metric-label__loKrp`,
	"items": `BusinessMetric-module__items__AEK0A`
};
export default ___CSS_LOADER_EXPORT___;
