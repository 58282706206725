import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HOST_API_URL } from '../constants/common.constants';
export const historyRecommendationsApi = createApi({
    reducerPath: 'historyRecommendationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: HOST_API_URL,
        prepareHeaders: (headers) => {
            const encodedCredentials = JSON.parse(localStorage.getItem('encodedCredentials'));
            if (encodedCredentials) {
                headers.set('Authorization', `Basic ${encodedCredentials}`);
            }
            return headers;
        },
    }),
    tagTypes: ['get_history_recommendations'],
    endpoints: (builder) => ({
        getRecommendationsHistory: builder.query({
            query: ({ typeRec, page, limit, lang }) => ({
                url: `/prediction_back/api/v1/recommendations/recommendation_stories/?type_rec=${typeRec}&page=${page}&limit=${limit}&lang=${lang}`,
            }),
            transformResponse: (res) => {
                const typedResponse = res;
                return typedResponse;
            },
        }),
        getHistoryExcel: builder.query({
            query: ({ params }) => ({
                url: `/prediction_back/api/v1/recommendations/recommendation_stories_to_excel/`,
                params: params,
            }),
        }),
        changeRecommendationsHistory: builder.mutation({
            query: ({ params }) => ({
                method: 'PUT',
                url: `/prediction_back/api/v1/recommendations/recommendation_story_change/`,
                params: params,
            }),
        }),
        deleteRecommendationsHistory: builder.mutation({
            query: ({ params }) => ({
                method: 'DELETE',
                url: `/prediction_back/api/v1/recommendations/recommendation_story_delete/`,
                params: params,
            }),
        }),
    }),
});
export const { useGetRecommendationsHistoryQuery, useGetHistoryExcelQuery, useChangeRecommendationsHistoryMutation, useDeleteRecommendationsHistoryMutation, } = historyRecommendationsApi;
