import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import filterSlice from './Slice/filterSlice';
import userSlice from './Slice/userSlice';
import { usersApi } from '../api/usersApi';
import { registryDocuments } from '../api/registryDocumentsApi';
import { consumptionApi } from '../api/consumptionApi';
import { recommendationsApi } from '../api/recommendationsApi';
import { businessMetricsApi } from '../api/businessMetricsApi';
import { historyRecommendationsApi } from '../api/historyRecommendationsApi';
export const store = configureStore({
    reducer: {
        [usersApi.reducerPath]: usersApi.reducer,
        [registryDocuments.reducerPath]: registryDocuments.reducer,
        [consumptionApi.reducerPath]: consumptionApi.reducer,
        [recommendationsApi.reducerPath]: recommendationsApi.reducer,
        [businessMetricsApi.reducerPath]: businessMetricsApi.reducer,
        [historyRecommendationsApi.reducerPath]: historyRecommendationsApi.reducer,
        filter: filterSlice,
        user: userSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(usersApi.middleware, registryDocuments.middleware, consumptionApi.middleware, recommendationsApi.middleware, businessMetricsApi.middleware, historyRecommendationsApi.middleware),
});
setupListeners(store.dispatch);
