import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo, useState, useEffect } from 'react';
import { getChartDataLastMonth, getChartDataUniqueYears, } from '../../../utils/chart.util';
import s from './ScheduleComponent.module.scss';
import { addListSchedulesMissingMonths, cumulativeFormatDate, filterListSchedulesByYears, getListSchedulesLastMonthOldYear, getQuarterData, getQuarterListSchedules, processListSchedules, } from './scheduleComponent.util';
import { EmptyScheduleComponent } from '../../EmptyScheduleComponent/EmptyScheduleComponent';
import { SWITCHES_CONSUMPTION_RU, SWITCHES_CONSUMPTION_EN, } from './ScheduleComponent.constants';
import { ScheduleConsumption } from '../ScheduleConsumption/ScheduleConsumption';
import { Switch } from '@consta/uikit/Switch';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../../constants/enums/locale.enum';
dayjs.locale('ru');
export const ScheduleComponent = memo(({ switchFilterYear, data, axisYName, oldYear = false, paddingLeft = false, paddingLeftOwn, paddingLeftAnotherChart, onReady, isQuarter = false, isCumulative = false, }) => {
    const { t } = useTranslation();
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const [showLines, setShowLines] = useState({
        blue: true,
        grey: true,
        cumulative: false,
    });
    const switchYearLength = Object.keys(switchFilterYear).filter((year) => switchFilterYear[year]).length;
    const [switches, setSwitches] = useState([]);
    useEffect(() => {
        const newSwitches = isCumulative
            ? isLangRu
                ? [
                    {
                        key: 'cumulative',
                        label: t('CumulativeSchedule'),
                    },
                    ...SWITCHES_CONSUMPTION_RU,
                ]
                : [
                    {
                        key: 'cumulative',
                        label: t('CumulativeSchedule'),
                    },
                    ...SWITCHES_CONSUMPTION_EN,
                ]
            : isLangRu
                ? SWITCHES_CONSUMPTION_RU
                : SWITCHES_CONSUMPTION_EN;
        setSwitches(newSwitches);
    }, [isCumulative, isLangRu, t]);
    const dataLine = useMemo(() => {
        if (!data.scheduleData) {
            return [];
        }
        const processedData = processListSchedules(showLines.cumulative ? data.accumulativeScheduleData : data.scheduleData);
        const filteredData = filterListSchedulesByYears(processedData, Object.keys(switchFilterYear).filter((year) => switchFilterYear[year]));
        const lastMonth = oldYear
            ? getListSchedulesLastMonthOldYear(processedData)
            : getChartDataLastMonth(processedData);
        const filteredDataByMonth = filteredData.filter((item) => item.x && lastMonth && item.x <= lastMonth);
        const dataWithMissingMonths = addListSchedulesMissingMonths(filteredDataByMonth);
        const filteredDataByLine = dataWithMissingMonths.filter((item) => item.line === null || showLines[item.line]);
        if (isQuarter && showLines.cumulative) {
            const dateQuarter = getQuarterData(filteredDataByLine);
            return cumulativeFormatDate(dateQuarter, switchYearLength, isQuarter);
        }
        else if (isQuarter) {
            return getQuarterListSchedules(filteredDataByLine);
        }
        if (showLines.cumulative) {
            return cumulativeFormatDate(filteredDataByLine, switchYearLength);
        }
        else {
            return filteredDataByLine;
        }
    }, [
        data,
        switchFilterYear,
        showLines,
        oldYear,
        isQuarter,
        switchYearLength,
    ]);
    /**
     * Функция для установки отображения линий.
     * @function
     * @param {boolean} item - значение отображения.
     * @param {string} line - линия отображения.
     * @returns {void}
     */
    const changeSwitch = (item, line) => {
        setShowLines((prevShowLines) => ({
            ...prevShowLines,
            [line]: item,
        }));
    };
    const uniqueYears = getChartDataUniqueYears(dataLine);
    const appendPadding = paddingLeftOwn &&
        paddingLeftAnotherChart &&
        paddingLeftAnotherChart > paddingLeftOwn
        ? [0, 0, 0, paddingLeftAnotherChart - paddingLeftOwn]
        : undefined;
    return (_jsxs("div", { className: s['schedule-container'], "data-testid": 'schedule-container', children: [_jsx("div", { className: s['switch-wrap-schedule'], children: switches.map(({ key, label }) => (_jsx(Switch, { size: 'm', checked: showLines[key], onChange: ({ checked }) => changeSwitch(checked, key), label: label, className: s[`switch-${key}`], "data-testid": `switch-${key}` }, key))) }), dataLine.length ? (_jsxs("div", { className: s['wrap-schedule-container'], style: {
                    width: paddingLeft
                        ? `${switchYearLength === 1
                            ? 94.8
                            : switchYearLength === 2
                                ? 96.8
                                : 98}%`
                        : '100%',
                    paddingLeft: paddingLeft
                        ? `${switchYearLength === 1
                            ? 3.5
                            : switchYearLength === 2
                                ? 5.2
                                : 5.9}%`
                        : '0%',
                }, children: [_jsx("div", { className: s['text-left-schedule'], style: {
                            left: paddingLeft
                                ? `${switchYearLength === 1
                                    ? 0.9
                                    : switchYearLength === 2
                                        ? 2.7
                                        : 2.7}%`
                                : '-1%',
                        }, children: axisYName }), _jsx(ScheduleConsumption, { onReady: onReady, appendPadding: appendPadding, isQuarter: isQuarter, dataLine: dataLine, isLangRu: isLangRu }), _jsx("div", { className: s['year-label'], children: uniqueYears.map((year) => (_jsx("div", { className: s['year'], children: year }, year))) })] })) : (_jsx(EmptyScheduleComponent, {}))] }));
});
