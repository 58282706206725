import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, memo, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import s from './LanguageComponent.module.scss';
import RuFlag from '../../assets/resource/ru.svg';
import EnFlag from '../../assets/resource/en.svg';
import { Locale } from '../../constants/enums/locale.enum';
import { INITIAL_FILTERS_ALL_VALUE } from '../../constants/initial.constants';
export const LanguageComponent = memo(({ setValueFiltersAll }) => {
    const { i18n } = useTranslation();
    const [isOpenLanguage, setIsOpenLanguage] = useState(false);
    const [isRuLanguage, setIsRuLanguage] = useState(true);
    const languageBodyRef = useRef(null);
    const handleClickOutside = (event) => {
        if (languageBodyRef.current &&
            !languageBodyRef.current.contains(event.target)) {
            setIsOpenLanguage(false);
        }
    };
    useEffect(() => {
        if (isOpenLanguage) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpenLanguage]);
    const changeLanguage = useCallback((lng) => {
        switch (lng) {
            case Locale.RUS:
                i18n.changeLanguage('ru');
                break;
            case Locale.ENG:
                i18n.changeLanguage('en');
                break;
            default:
                i18n.changeLanguage('ru');
        }
        setIsRuLanguage(lng === Locale.RUS);
        setIsOpenLanguage(false);
        localStorage.setItem('lang', lng);
    }, [i18n]);
    useEffect(() => {
        const languageLocal = localStorage.getItem('lang');
        if (languageLocal) {
            changeLanguage(languageLocal);
        }
    }, [changeLanguage]);
    const changeLang = (lang) => {
        changeLanguage(lang);
        setValueFiltersAll(INITIAL_FILTERS_ALL_VALUE);
    };
    return (_jsxs("div", { className: s['language-body'], ref: languageBodyRef, children: [_jsxs("div", { className: s['language-wrap'], onClick: () => setIsOpenLanguage((prevState) => !prevState), children: [_jsx("img", { src: isRuLanguage ? RuFlag : EnFlag, alt: '\u042F\u0437\u044B\u043A' }), _jsx("span", { children: isRuLanguage ? 'Ru' : 'En' })] }), _jsxs("div", { className: `${s['language-wrap-change']} ${isOpenLanguage && s['language-wrap-change-opened']}`, children: [_jsxs("div", { className: s['language-change'], onClick: () => {
                            changeLang(Locale.ENG);
                        }, children: [_jsx("img", { src: EnFlag, alt: '\u042F\u0437\u044B\u043A' }), _jsx("span", { children: "En" })] }), _jsxs("div", { className: s['language-change'], onClick: () => {
                            changeLang(Locale.RUS);
                        }, children: [_jsx("img", { src: RuFlag, alt: '\u042F\u0437\u044B\u043A' }), _jsx("span", { children: "Ru" })] })] })] }));
});
