import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { TextFieldComponent } from '../UI/TextField/TextFieldComponent';
import { DatePickerComponent } from '../UI/DatePicker/DataPickerComponent';
import { useGetDocTypesQuery } from '../../api/registryDocumentsApi';
import { SelectComponent } from '../UI/Select/SelectComponent';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../constants/enums/locale.enum';
export const LeftFilterMenuDocument = ({ valueFiltersAll, setValueFiltersAll, }) => {
    const lang = localStorage.getItem('lang') || Locale.RUS;
    const { t } = useTranslation();
    const { data: listTypeDoc } = useGetDocTypesQuery(lang);
    const [listDocumentType, setListDocumentType] = useState([]);
    /**
     * Эффект, выполняющий обновление списка типов документов на основе объекта listTypeDoc.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        const newListDocType = [];
        if (listTypeDoc) {
            Object.keys(listTypeDoc).forEach((key, index) => {
                const value = listTypeDoc[key];
                newListDocType.push({
                    label: value,
                    request: key,
                    id: (index + 1).toString(),
                });
            });
        }
        setListDocumentType(newListDocType);
    }, [listTypeDoc]);
    /**
     * Функция для обновления фильтров документов.
     * @param {string | Date | { label: string, request: string, id?: string }} newValue - Новое значение для фильтра.
     * @param {string} changeName - Имя фильтра, который нужно обновить.
     * @returns {void} - Ничего не возвращает.
     */
    const changeFilter = (newValue, changeName) => {
        setValueFiltersAll((prevState) => ({
            ...prevState,
            registerDocuments: {
                ...prevState.registerDocuments,
                [changeName]: newValue,
            },
        }));
    };
    return (_jsxs("div", { className: 'left-filter-wrap', "data-testid": 'left-filter-wrap', children: [_jsx("div", { className: 'form-wrap', children: _jsx(TextFieldComponent, { value: valueFiltersAll.registerDocuments.file_name, type: 'text', changeFilter: changeFilter, filter: 'file_name', label: t('nameSelectFilterDoc'), placeholder: t('nameSelectFilterDoc') }) }), _jsx("div", { className: 'form-wrap', children: _jsx(SelectComponent, { items: listDocumentType, value: valueFiltersAll.registerDocuments.doc_type, label: t('DocumentTypeFilterDoc'), placeholder: t('DocumentTypeFilterDoc'), changeFilter: changeFilter, filter: 'doc_type' }) }), _jsx("div", { className: 'form-wrap form-wrap-date-picker-color', children: _jsx(DatePickerComponent, { value: valueFiltersAll.registerDocuments.created_date_from, type: 'date', changeFilter: changeFilter, filter: 'created_date_from', label: t('SystemLoadingPeriodFilterDoc'), placeholder: t('DateFromFilterDoc') }) }), _jsx("div", { className: 'form-wrap form-wrap-date-picker-color', children: _jsx(DatePickerComponent, { value: valueFiltersAll.registerDocuments.created_date_to, type: 'date', changeFilter: changeFilter, filter: 'created_date_to', label: t('SystemLoadingPeriodFilterDoc'), placeholder: t('DateToFilterDoc') }) })] }));
};
