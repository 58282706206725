import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState, useRef } from 'react';
import s from './HistoryRecommendation.module.scss';
import { Tabs } from '@consta/uikit/Tabs';
import { Table } from '@consta/uikit/Table';
import dayjs from 'dayjs';
import { Pagination } from '@consta/uikit/Pagination';
import { File } from '@consta/uikit/File';
import { Tooltip } from '@consta/uikit/Tooltip';
import { useTranslation } from 'react-i18next';
import { HISTORY_TABS_RU, HISTORY_TABS_EN, ORDER_HIST_COLUMNS_RU, ORDER_HIST_COLUMNS_EN, } from './HistoryRecommendation.constants';
import { useGetRecommendationsHistoryQuery } from '../../api/historyRecommendationsApi';
import { TypeRec } from '../../constants/enums/typeRec.enum';
import { LoaderComponent } from '../../components/UI/Loader/LoaderComponent';
import { downloadHistoryRecommendation } from './historyRecommendation.util';
import { Locale } from '../../constants/enums/locale.enum';
export const HistoryRecommendation = () => {
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const [listHistory, setListHistory] = useState([]);
    const [activeTab, setActiveTab] = useState(isLangRu
        ? HISTORY_TABS_RU[0]
        : HISTORY_TABS_EN[0]);
    const lang = localStorage.getItem('lang') || Locale.RUS;
    const [pagesDocument, setPagesDocument] = useState(1);
    const [maxPage, setMaxPage] = useState(0);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const fileRef = useRef(null);
    const { t } = useTranslation();
    const { data: fetchedRecHistoryList, refetch: refetchRecHistory, isLoading, } = useGetRecommendationsHistoryQuery({
        typeRec: TypeRec.ADDITIONAL_ORDER,
        page: pagesDocument,
        limit: 10,
        lang,
    });
    useEffect(() => {
        setActiveTab(isLangRu
            ? HISTORY_TABS_RU[0]
            : HISTORY_TABS_EN[0]);
    }, [isLangRu]);
    /**
     * Функция для обработки изменения номера страницы.
     * @function
     * @param {number} pageNumber - Номер страницы, на который нужно переключиться (начиная с 0).
     * @returns {void}
     */
    const handleChange = (pageNumber) => {
        setPagesDocument(pageNumber + 1);
    };
    /**
     * Эффект, вызывающий функцию refetchDocuments при изменении определенных зависимостей.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        refetchRecHistory();
    }, [pagesDocument, refetchRecHistory]);
    /**
     * Функция для форматирования строк
     * @function
     * @param {IHistoryRecommendationRow[]} arr - данные для форматирования.
     * @returns {IProcessedHistoryRecommendationRow} - отформатированные данные
     */
    const getInitialStateList = useCallback((arr) => {
        return arr.map((item) => {
            return {
                ...item,
                created_date: item.created_date !== null
                    ? dayjs(item.created_date).format('MM.YYYY')
                    : t('Absent'),
                dt_start: item.dt_start !== null
                    ? dayjs(item.dt_start).format('MM.YYYY')
                    : t('Absent'),
                delivery_date: item.delivery_date !== null
                    ? dayjs(item.delivery_date).format('MM.YYYY')
                    : t('Absent'),
                dt_end: item.dt_end !== null
                    ? dayjs(item.dt_end).format('MM.YYYY')
                    : t('Absent'),
                bmu: item.bmu !== null ? parseInt(item.bmu).toString() : t('Absent'),
            };
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setListHistory, isLangRu]);
    /**
     * Эффект, выполняющий обновление списка историй рекомендаций и количества страниц.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (fetchedRecHistoryList) {
            setListHistory(getInitialStateList(fetchedRecHistoryList.docs));
            setMaxPage(fetchedRecHistoryList.total_num_pages);
        }
    }, [fetchedRecHistoryList, getInitialStateList]);
    /**
     * Функция для изменения таба
     * @function
     * @param {{e: React.MouseEvent<Element, MouseEvent>; value: ITabValue}} selectedTab - данные строки.
     * @returns {void}
     */
    const handleTabChange = (selectedTab) => {
        setActiveTab(selectedTab.value);
    };
    const download = useCallback(async () => {
        try {
            setIsDownloading(true);
            await downloadHistoryRecommendation();
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setIsDownloading(false);
        }
    }, []);
    return (_jsxs("div", { className: s['history-wrap'], "data-testid": 'history-wrap', children: [_jsxs("div", { className: s['history-header'], children: [_jsx("div", { className: s['history-tabs'], children: _jsx(Tabs, { value: activeTab, onChange: handleTabChange, items: isLangRu ? HISTORY_TABS_RU : HISTORY_TABS_EN, getItemLabel: (item) => item.label, className: 'tabs' }) }), !isLoading && (_jsxs("div", { ref: fileRef, onMouseMove: () => setTooltipVisible(true), onMouseLeave: () => setTooltipVisible(false), className: s['consumption-attachment'], children: [_jsx(File, { onClick: isDownloading ? undefined : download, loadingWithProgressSpin: true, loading: isDownloading, size: 's', extension: 'xlsx' }), tooltipVisible && (_jsx(Tooltip, { direction: 'rightCenter', isInteractive: false, anchorRef: fileRef, children: _jsx("span", { className: s['consumption-attachment-tooltip'], children: isDownloading ? t('Loading') : t('UploadExcel') }) }))] }))] }), isLoading ? (_jsx(LoaderComponent, {})) : (_jsxs("div", { className: s['history-table-wrap'], children: [_jsx("div", { className: s['table-scroller'], children: listHistory.length ? (_jsx(Table, { className: s['history-table'], columns: isLangRu ? ORDER_HIST_COLUMNS_RU : ORDER_HIST_COLUMNS_EN, rows: listHistory, stickyHeader: true })) : (_jsx("div", { children: t('noData') })) }), _jsx(Pagination, { className: s['history-pagination'], totalPages: maxPage, currentPage: pagesDocument - 1, onChange: handleChange, hotkeys: {
                            nextPage: {
                                label: '',
                                values: ['Alt', 'ArrowRight'],
                            },
                            prevPage: {
                                label: '',
                                values: ['Alt', 'ArrowLeft'],
                            },
                        } })] }))] }));
};
