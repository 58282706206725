// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonResetFilter-module__reset-button__fBfXN{width:100%;height:40px;display:flex;align-items:center;justify-content:center;background-color:#ecf1f4;border-radius:6px;color:#0078d2;font-size:18px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/UI/ButtonResetFilter/ButtonResetFilter.module.scss"],"names":[],"mappings":"AAAA,+CACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,wBAAA,CACA,iBAAA,CACA,aAAA,CACA,cAAA,CACA,cAAA","sourcesContent":[".reset-button {\r\n  width: 100%;\r\n  height: 40px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  background-color: rgb(236, 241, 244);\r\n  border-radius: 6px;\r\n  color: rgba(0, 120, 210, 1);\r\n  font-size: 18px;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reset-button": `ButtonResetFilter-module__reset-button__fBfXN`
};
export default ___CSS_LOADER_EXPORT___;
