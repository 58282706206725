import axios from 'axios';
import { HOST_API_URL } from '../../constants/common.constants';
import { downloadFile, parseContentDisposition } from '../../utils/file.util';
import { Locale } from '../../constants/enums/locale.enum';
export const downloadHistoryRecommendation = async () => {
    const encodedCredentials = localStorage.getItem('encodedCredentials');
    const lang = localStorage.getItem('lang') || Locale.RUS;
    const response = await axios.get(`${HOST_API_URL}/prediction_back/api/v1/recommendations/recommendation_stories_to_excel/?lang=${lang}`, {
        headers: {
            Authorization: `Basic ${encodedCredentials}`,
        },
        responseType: 'arraybuffer',
    });
    const filename = decodeURIComponent(parseContentDisposition(response.headers['content-disposition']) || '');
    downloadFile(response.data, filename || 'history-recommendation.xlsx');
};
/**
 * Функция для фильтрации от/до.
 * @function
 * @param {number | null} value - данные для фильтрации.
 * @param {{min?: number | string; max?: number | string}} filterValue - значения фильтра.
 * @returns {boolean} - булевое значение соответствия фильтру.
 */
export const historyRecommendationRangeFilterer = (value, filterValue) => {
    const minDefined = filterValue.min;
    const maxDefined = filterValue.max;
    if (minDefined && !maxDefined) {
        return Number(value) >= Number(filterValue.min);
    }
    if (!minDefined && maxDefined) {
        return Number(value) <= Number(filterValue.max);
    }
    if (!(minDefined || maxDefined)) {
        return true;
    }
    return (Number(value) <= Number(filterValue.max) &&
        Number(value) >= Number(filterValue.min));
};
