import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import s from './ScheduleConsumption.module.scss';
import { getListSchedulesLineColor, quarterFormatter, monthFormatter, yLabelFormatter, recommendationsSchedulesFormatter, quarterTooltipTitleFormatter, monthTooltipTitleFormatter, } from '../Schedule/scheduleComponent.util';
import { Line } from '@consta/charts/Line';
export const ScheduleConsumption = memo(({ dataLine, onReady, appendPadding, isQuarter, isLangRu }) => {
    return (_jsx(Line, { className: s['schedule'], data: dataLine, xField: 'x', yField: 'massa', seriesField: 'line', smooth: true, legend: false, onReady: onReady, appendPadding: appendPadding, connectNulls: false, animation: { appear: { duration: 900 } }, color: (datum) => getListSchedulesLineColor(datum) // Используем функцию для определения цвета линии}
        , xAxis: {
            tickCount: dataLine.length,
            label: {
                formatter: (text) => isQuarter
                    ? quarterFormatter(text, isLangRu)
                    : monthFormatter(text, isLangRu), // Форматируем даты для меток оси x
            },
        }, yAxis: {
            max: Math.max(...dataLine.map((item) => item.massa)) + 40,
            label: {
                formatter: (text) => yLabelFormatter(text, isLangRu),
            },
        }, tooltip: {
            formatter: (datum) => recommendationsSchedulesFormatter(datum, isLangRu),
            title(title) {
                return isQuarter
                    ? quarterTooltipTitleFormatter(title, isLangRu)
                    : monthTooltipTitleFormatter(title, isLangRu);
            },
            //Фильтрация пустых значений в тултипе
            customItems: (originalItems) => {
                return originalItems.filter((el) => {
                    return el.value !== null;
                });
            },
            //отображение тултипа если есть хотябы одно значение
            showContent(datum) {
                return datum.some((el) => el['value']);
            },
        }, style: { width: '100%' } }));
}, (prevPops, nextProps) => {
    return JSON.stringify(prevPops) === JSON.stringify(nextProps);
});
