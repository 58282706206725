import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { changeFilters, changeSwitch, changeFilterValueDocument, changeFiltersConsumption, changeFiltersBusinessMetrics, } from '../../store/Slice/filterSlice';
import { INITIAL_FILTER, INITIAL_FILTERS_DOCUMENT, INITIAL_FILTERS_ALL_VALUE, INITIAL_VALUE_SWITCH, } from '../../constants/initial.constants';
import { ButtonResetFilter } from '../UI/ButtonResetFilter/ButtonResetFilter';
import { LeftFilterMenuDocument } from '../LeftFilterMenuDocument/LeftFilterMenuDocument';
import { LanguageComponent } from '../LanguageComponent/LanguageComponent';
import { LinkHeaderRoute } from '../LinkHeaderRoute/LinkHeaderRoute';
import { LeftFilterMenu } from '../LeftFilterMenu/LeftFilterMenu';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { changePermissions, changeRole } from '../../store/Slice/userSlice';
import { useGetUserPermissionsQuery } from '../../api/usersApi';
import ArrowSvg from '../../assets/resource/arrow.svg';
import UserSvg from '../../assets/resource/user.svg';
import Logo from '../../assets/resource/logo.svg';
import { useLocation } from 'react-router-dom';
import './index.scss';
import { IconExit } from '@consta/icons/IconExit';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { getRusRoles } from '../../constants/enums/roles.enum';
import { Locale } from '../../constants/enums/locale.enum';
export const Headers = () => {
    const { t } = useTranslation();
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { permissions, role } = useAppSelector((state) => state.user);
    const [filterType, setFilterType] = useState('');
    const [isOpenLogout, setIsOpenLogout] = useState(false);
    const [valueFiltersAll, setValueFiltersAll] = useState(INITIAL_FILTERS_ALL_VALUE);
    const [valueSwitch, setValueSwitch] = useState(INITIAL_VALUE_SWITCH);
    const lang = localStorage.getItem('lang') || Locale.RUS;
    const encodedCredentials = JSON.parse(localStorage.getItem('encodedCredentials'));
    const user = JSON.parse(localStorage.getItem('user'));
    const { data: fetchedPermissions } = useGetUserPermissionsQuery({ userId: user?.id, encodedCredentials, lang }, {
        skip: !user,
    });
    /**
     * Эффект для установки разрешений в стейт при перезагрузке страницы.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        let permissionsArray = [];
        let userRole = '';
        if (fetchedPermissions) {
            permissionsArray = Object.values(fetchedPermissions)[0];
            userRole = getRusRoles(Object.keys(fetchedPermissions)[0]);
        }
        if (fetchedPermissions && permissionsArray.length && user && user?.id) {
            dispatch(changePermissions(permissionsArray));
            dispatch(changeRole(userRole));
        }
    }, [dispatch, fetchedPermissions, permissions, user]);
    /**
     * Эффект для установки значения типа фильтра на основе текущего пути.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        const currentUrl = location.pathname.substring(1);
        setFilterType(currentUrl);
    }, [location.pathname]);
    /**
     * Эффект для обновления фильтров с помощью Redux-диспетчера при изменении значения фильтров.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        dispatch(changeFilters(valueFiltersAll.recommendations));
    }, [valueFiltersAll.recommendations, dispatch]);
    /**
     * Эффект для обновления фильтров с помощью Redux-диспетчера при изменении значения фильтров.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        dispatch(changeFiltersConsumption(valueFiltersAll.consumption));
    }, [valueFiltersAll.consumption, dispatch]);
    /**
     * Эффект для обновления фильтров с помощью Redux-диспетчера при изменении значения фильтров.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        dispatch(changeFiltersBusinessMetrics({
            ...valueFiltersAll.business_metrics,
            period: changeDate(valueFiltersAll.business_metrics.period),
        }));
    }, [valueFiltersAll.business_metrics, dispatch]);
    /**
     * Эффект для обновления фильтров с помощью Redux-диспетчера при изменении значения фильтров.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        dispatch(changeSwitch(valueSwitch));
    }, [dispatch, valueSwitch]);
    /**
     * Эффект для обновления фильтров с помощью Redux-диспетчера при изменении значения фильтров.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        const updatedFilters = {
            ...valueFiltersAll.registerDocuments,
            created_date_from: changeDate(valueFiltersAll.registerDocuments.created_date_from),
            created_date_to: changeDate(valueFiltersAll.registerDocuments.created_date_to),
            doc_type: valueFiltersAll.registerDocuments?.doc_type?.request,
        };
        dispatch(changeFilterValueDocument(updatedFilters));
    }, [dispatch, valueFiltersAll.registerDocuments]);
    /**
     * Функция для форматирования даты в строку в формате 'YYYY-MM-DD'.
     * @function
     * @param {Date | null} date - Дата для форматирования.
     * @returns {string} - Отформатированная дата в строковом формате 'YYYY-MM-DD'.
     */
    const changeDate = (date) => {
        return date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : null;
    };
    /**
     * Функция для изменения состояния блока выхода из системы (логаут).
     * @function
     * @returns {void}
     */
    const changeLogoutBlock = () => {
        setIsOpenLogout((prevState) => !prevState);
    };
    /**
     * Функция для сброса значений фильтров в исходное состояние в зависимости от типа фильтра.
     * @function
     * @returns {void}
     */
    const resetFilter = () => {
        switch (filterType) {
            case 'recommendations':
                setValueFiltersAll((prevState) => ({
                    ...prevState,
                    recommendations: INITIAL_FILTER,
                }));
                break;
            case 'consumption':
                setValueFiltersAll((prevState) => ({
                    ...prevState,
                    consumption: INITIAL_FILTER,
                }));
                break;
            case 'registerDocuments':
                setValueFiltersAll((prevState) => ({
                    ...prevState,
                    registerDocuments: INITIAL_FILTERS_DOCUMENT,
                }));
                break;
            case 'business_metrics':
                setValueFiltersAll((prevState) => ({
                    ...prevState,
                    business_metrics: INITIAL_FILTER,
                }));
                break;
        }
        setValueSwitch(INITIAL_VALUE_SWITCH);
    };
    /**
     * Функция для выполнения запроса на выход из системы (logout).
     * Удаляет все данные из localStorage и перезагружает страницу для выхода пользователя.
     * @function
     * @returns {void}
     */
    const logoutRequest = () => {
        localStorage.clear();
        window.location.reload();
    };
    const nameRoleLanguage = (role) => {
        if (isLangRu)
            return role;
        switch (role) {
            case 'Администратор':
                return 'Administrator';
            case 'Специалист':
                return 'Specialist';
            case 'Загрузчик данных':
                return 'Data Loader';
            case 'Руководитель':
                return 'Supervisor';
            default:
                return 'Unknown role';
        }
    };
    const nameUser = (userInfo) => {
        return userInfo?.last_name + ' ' + userInfo?.first_name;
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'headers-wrap', "data-testid": 'headers-wrap', children: [_jsx("div", { className: 'link-route', children: _jsx(LinkHeaderRoute, { permissions: permissions }) }), _jsxs("div", { className: 'headers-lang-user', children: [_jsx("div", { className: 'headers-logo', children: _jsx("img", { src: Logo, alt: '' }) }), _jsx(LanguageComponent, { setValueFiltersAll: setValueFiltersAll }), _jsxs("div", { className: 'headers-user', children: [_jsxs("div", { onClick: changeLogoutBlock, className: 'headers-user-wrap', "data-testid": 'headers-user-wrap', children: [_jsx("img", { className: 'user-img', src: UserSvg, alt: '\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C' }), _jsxs("div", { className: 'user-info', children: [_jsx("span", { children: nameUser(user) }), _jsx("span", { children: nameRoleLanguage(role) })] }), _jsx("img", { className: 'user-arrow-action', src: ArrowSvg, alt: '\u0441\u0442\u0440\u0435\u043B\u043A\u0430' })] }), isOpenLogout && (_jsxs("div", { onClick: logoutRequest, className: 'logout-wrap', children: [_jsx("div", { children: t('GoOut') }), _jsx(IconExit, { view: 'brand', size: 'm' })] }))] })] })] }), _jsxs("div", { className: 'menu-wrap', children: [filterType !== 'historyRecommendation' && (_jsxs("div", { className: 'filter-wrap', children: [filterType !== 'registerDocuments' ? (_jsx(LeftFilterMenu, { filterType: filterType, valueSwitch: valueSwitch, setValueSwitch: setValueSwitch, valueFiltersAll: valueFiltersAll, setValueFiltersAll: setValueFiltersAll })) : (_jsx(LeftFilterMenuDocument, { valueFiltersAll: valueFiltersAll, setValueFiltersAll: setValueFiltersAll })), _jsx("div", { className: 'wrap-reset-filter', onClick: resetFilter, children: _jsx(ButtonResetFilter, { textButton: t('ResetFilters') }) })] })), _jsx("div", { className: `wrap-body ${filterType === 'historyRecommendation'
                            ? 'wrap-body-history'
                            : filterType === 'business_metrics'
                                ? 'wrap-body-business-metrics'
                                : ''}`, children: _jsx(Outlet, {}) })] })] }));
};
