// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LegendsColor-module__legend-wrap__ZUOKQ{display:flex;width:auto;height:auto;align-items:center;gap:4px}.LegendsColor-module__legend-wrap__ZUOKQ span{color:rgba(0,32,51,.6);font-size:14px;font-weight:400;line-height:8.82px}.LegendsColor-module__legend-circle__PT1jm{width:16px;height:16px;border-radius:50%}.LegendsColor-module__legend-line__iBR3j{width:16px;height:2px}`, "",{"version":3,"sources":["webpack://./src/components/LegendsColor/LegendsColor.module.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,OAAA,CAEA,8CACE,sBAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CAIJ,2CACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,yCACE,UAAA,CACA,UAAA","sourcesContent":[".legend-wrap {\r\n  display: flex;\r\n  width: auto;\r\n  height: auto;\r\n  align-items: center;\r\n  gap: 4px;\r\n\r\n  span {\r\n    color: rgba(0, 32, 51, 0.6);\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    line-height: 8.82px;\r\n  }\r\n}\r\n\r\n.legend-circle {\r\n  width: 16px;\r\n  height: 16px;\r\n  border-radius: 50%;\r\n}\r\n\r\n.legend-line{\r\n  width: 16px;\r\n  height: 2px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legend-wrap": `LegendsColor-module__legend-wrap__ZUOKQ`,
	"legend-circle": `LegendsColor-module__legend-circle__PT1jm`,
	"legend-line": `LegendsColor-module__legend-line__iBR3j`
};
export default ___CSS_LOADER_EXPORT___;
