import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, useRef, useCallback } from 'react';
import { useGetScheduleConsumptionQuery, useGetForecastCurrentYearQuery, useGetForecastLastYearQuery, } from '../../api/consumptionApi';
import { ConsumptionLastYear } from '../../components/ConsumptionLastYear/ConsumptionLastYear';
import { ScheduleComponent } from '../../components/UI/Schedule/ScheduleComponent';
import { INITIAL_DISPLAY_MODE_RU, INITIAL_DISPLAY_MODE_EN, INITIAL_STRING_REQUEST, INITIAL_TABS_CONSUMPTION_RU, INITIAL_TABS_CONSUMPTION_EN, INITIAL_CONSUMPTION_LAST_YEAR, } from '../../constants/initial.constants';
import { Forecast } from '../../components/Forecast/Forecast';
import { createQueryString, createQueryStringFromObject, } from '../../utils/utils';
import { LoaderComponent } from '../../components/UI/Loader/LoaderComponent';
import { TabsComponent } from '../../components/UI/Tabs/TabsComponent';
import { useAppSelector } from '../../store/hook';
import s from './ConsumptionForecast.module.scss';
import { DatePicker } from '@consta/uikit/DatePicker';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Tooltip } from '@consta/uikit/Tooltip';
import dayjs from 'dayjs';
import { downloadConsumptionForecast } from './consumptionForecast.util';
import { File } from '@consta/uikit/File';
import { Placeholder } from '../../components/Placeholder/Placeholder';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../constants/enums/locale.enum';
export const ConsumptionForecast = () => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const maxDate = dayjs().subtract(1, 'year').toDate();
    const { filtersConsumption, switchValue } = useAppSelector((state) => state.filter);
    const [isDownloading, setIsDownloading] = useState(false);
    const fileRef = useRef(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [valueTabs, setValueTabs] = useState(isLangRu
        ? INITIAL_TABS_CONSUMPTION_RU[0]
        : INITIAL_TABS_CONSUMPTION_EN[0]);
    const [displayMode, setDisplayMode] = useState(isLangRu
        ? INITIAL_DISPLAY_MODE_RU[0]
        : INITIAL_DISPLAY_MODE_EN[0]);
    const [isFilterAll, setIsFilterAll] = useState(false);
    const [consumptionLastYearFilter, setConsumptionLastYearFilter] = useState(INITIAL_CONSUMPTION_LAST_YEAR);
    const [errorDate, setErrorDate] = useState(null);
    const [forecastListCurrentYear, setForecastListCurrentYear] = useState([]);
    const lang = localStorage.getItem('lang') || Locale.RUS;
    const [stringRequest, setStringRequest] = useState(INITIAL_STRING_REQUEST);
    const [part_1List, setPart_1List] = useState({
        scheduleData: [],
        accumulativeScheduleData: [],
    });
    const [part_2List, setPart_2List] = useState({
        scheduleData: [],
        accumulativeScheduleData: [],
    });
    const [loading, setLoading] = useState(true);
    const [paddings, setPaddings] = useState({ currentYear: 0, lastYear: 0 });
    useEffect(() => {
        setValueTabs(isLangRu
            ? INITIAL_TABS_CONSUMPTION_RU[0]
            : INITIAL_TABS_CONSUMPTION_EN[0]);
        setDisplayMode(isLangRu
            ? INITIAL_DISPLAY_MODE_RU[0]
            : INITIAL_DISPLAY_MODE_EN[0]);
    }, [isLangRu]);
    /**
     * Callback-функция для определения отступа графика за текущий год.
     * @function
     * @param {any} options - параметры графика.
     * @returns {void}
     */
    const registerPaddingCurrentYear = useCallback((options) => {
        const chartPadding = options.chart?.autoPadding;
        if (chartPadding?.left) {
            setPaddings((prev) => {
                return {
                    ...prev,
                    currentYear: chartPadding.left,
                };
            });
        }
        else {
            setPaddings((prev) => {
                return {
                    ...prev,
                    currentYear: 0,
                };
            });
        }
    }, []);
    /**
     * Callback-функция для определения отступа графика за предыдущие годы.
     * @function
     * @param {any} options - параметры графика.
     * @returns {void}
     */
    const registerPaddingLastYear = useCallback((options) => {
        const chartPadding = options.chart?.autoPadding;
        if (chartPadding?.left) {
            setPaddings((prev) => {
                return {
                    ...prev,
                    lastYear: chartPadding.left,
                };
            });
        }
        else {
            setPaddings((prev) => {
                return {
                    ...prev,
                    lastYear: 0,
                };
            });
        }
    }, []);
    const { data: listSchedules, refetch: listSchedulesRefetch, isFetching: isFetchingListSchedules, } = useGetScheduleConsumptionQuery(stringRequest.stringRequestSchedule, {
        skip: !isFilterAll,
    });
    const { data: forecastCurrentYear, refetch: forecastCurrentYearRefetch, isFetching: isFetchingCurrentYear, } = useGetForecastCurrentYearQuery(stringRequest.stringRequestCurrentYear, {
        skip: !isFilterAll,
    });
    const { data: forecastLastYear, refetch: forecastLastYearRefetch, isFetching: isFetchingLastYear, } = useGetForecastLastYearQuery(stringRequest.stringRequestLastYear, {
        skip: !isFilterAll,
    });
    useEffect(() => {
        if (!isFetchingListSchedules ||
            !isFetchingLastYear ||
            !isFetchingCurrentYear) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [isFetchingLastYear, isFetchingCurrentYear, isFetchingListSchedules]);
    /**
     * Callback-функция для определения видимости элемента на основе значений фильтров и переключателей.
     * @function
     * @returns {boolean} - Возвращает true, если элемент должен быть видимым, и false в противном случае.
     */
    const changeIsVisible = useCallback(() => {
        if (filtersConsumption.commodityGroup === '' ||
            !Object.values(switchValue).includes(true)) {
            return false;
        }
        else {
            return true;
        }
    }, [switchValue, filtersConsumption]);
    /**
     * Эффект, устанавливающий состояние isFilterAll на основе значения, возвращаемого функцией changeIsVisible.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        setIsFilterAll(changeIsVisible);
    }, [changeIsVisible]);
    /**
     * Эффект, устанавливающий список прогнозов на текущий год на основе данных из forecastCurrentYear.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (forecastCurrentYear) {
            setForecastListCurrentYear(Object.keys(forecastCurrentYear));
            setLoading(false);
        }
        else {
            setForecastListCurrentYear([]);
            setLoading(false);
        }
    }, [forecastCurrentYear]);
    /**
     * Функция для форматирования списка графика (listPart).
     * @function
     * @param {IListSchedules[]} listPart - Список объектов типа IListSchedules.
     * @returns {IListSchedules[]} - Отформатированный список объектов типа IListSchedules.
     */
    const formatListSchedulesPart = (listPart) => {
        return listPart.flat().map((item) => ({
            ...item,
            month: `${item.year}-${Number(item.month) > 9 ? `${item.month}` : `0${item.month}`}`,
        }));
    };
    /**
     * Эффект, форматирующий и устанавливающий состояния для списков part_1 и part_2 на основе данных из listSchedules.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (listSchedules?.part_1?.length) {
            const scheduleData = formatListSchedulesPart(listSchedules.part_1);
            const accumulativeScheduleData = formatListSchedulesPart(listSchedules.part_1_cumulative);
            setPart_1List({ scheduleData, accumulativeScheduleData });
            setLoading(false);
        }
        else {
            setPart_1List({ scheduleData: [], accumulativeScheduleData: [] });
            setLoading(false);
        }
        if (listSchedules?.part_2?.length) {
            const scheduleData = formatListSchedulesPart(listSchedules.part_2);
            const accumulativeScheduleData = formatListSchedulesPart(listSchedules.part_2_cumulative);
            setPart_2List({ scheduleData, accumulativeScheduleData });
            setLoading(false);
        }
        else {
            setPart_2List({ scheduleData: [], accumulativeScheduleData: [] });
            setLoading(false);
        }
    }, [listSchedules]);
    /**
     * Эффект, выполняющий формирование строки запроса (stringRequest)
     * на основе фильтровых значений (filtersConsumption), переключателей (switchValue), (valueTabs) и (consumptionLastYearFilter).
     * @function
     * @returns {void}
     */
    useEffect(() => {
        setLoading(true);
        const account_unit = valueTabs === 'БЕИ' || valueTabs === 'BUOM'
            ? 'account_unit=bei&'
            : 'account_unit=rub&';
        const group_mtrs = filtersConsumption.commodityGroup !== ''
            ? `group_mtrs=${filtersConsumption.commodityGroup.id}&`
            : '';
        const analog_groups = filtersConsumption.synthesisGroup !== ''
            ? createQueryString(filtersConsumption.synthesisGroup, 'analog_groups')
            : '';
        const bus_units = filtersConsumption.choiceDO !== ''
            ? createQueryString(filtersConsumption.choiceDO, 'bus_units')
            : '';
        const years = createQueryStringFromObject(switchValue, 'years');
        const years_2 = consumptionLastYearFilter.consumptionIsLastYear
            ? dayjs(consumptionLastYearFilter.year).format('YYYY')
            : '';
        setStringRequest({
            stringRequestSchedule: `${account_unit}${group_mtrs}${analog_groups}&${bus_units}&${years}${years_2 ? `&years_2=${years_2}` : ''}&lang=${lang}`,
            stringRequestCurrentYear: `${account_unit}${group_mtrs}${analog_groups}&${bus_units}&lang=${lang}`,
            stringRequestLastYear: `${account_unit}${group_mtrs}${analog_groups}&${bus_units}${years_2 ? `&years=${years_2}` : ''}&lang=${lang}`,
            stringRequestDownload: `${account_unit}${group_mtrs}${analog_groups}&${bus_units}&${years}&lang=${lang}`,
        });
    }, [filtersConsumption, switchValue, valueTabs, consumptionLastYearFilter, lang]);
    const download = useCallback(async () => {
        setLoading(true);
        try {
            setIsDownloading(true);
            await downloadConsumptionForecast(stringRequest.stringRequestDownload);
            setLoading(false);
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setIsDownloading(false);
            setLoading(false);
        }
    }, [stringRequest.stringRequestDownload]);
    /**
     * Функция для изменения фильтра потребления.
     * @function
     * @param {boolean | DatePickerPropValue<"year">} newValue - Новое значение фильтра.
     * @param {string} nameFilter - Название фильтра, который требуется изменить.
     * @returns {void}
     */
    const changeFIlterConsumption = (newValue, nameFilter) => {
        if (nameFilter === 'year')
            setErrorDate(null);
        setConsumptionLastYearFilter((prevState) => {
            return {
                ...prevState,
                [nameFilter]: newValue,
            };
        });
    };
    /**
     * Callback-функция для обработки ошибки неправильно указанной даты.
     * Устанавливает сообщение об ошибке в состояние errorDate.
     * @function
     * @returns {void}
     */
    const onErrorDate = () => {
        setErrorDate(t('IncorrectDate'));
        setLoading(false);
    };
    /**
     * Эффект, выполняющий запросы на сервер при изменении состояния isFilterAll(true).
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (isFilterAll) {
            listSchedulesRefetch();
            forecastCurrentYearRefetch();
            forecastLastYearRefetch();
        }
    }, [
        listSchedulesRefetch,
        stringRequest,
        isFilterAll,
        forecastCurrentYearRefetch,
        forecastLastYearRefetch,
    ]);
    const measureUnit = valueTabs === 'БЕИ' || valueTabs === 'BUOM'
        ? filtersConsumption.commodityGroup?.unit
            ? filtersConsumption.commodityGroup?.unit
            : t('tons')
        : t('rubles');
    return (_jsx(_Fragment, { children: !isFilterAll ? (_jsx(Placeholder, { title: t('fillAllFilters'), isImage: true })) : (_jsx(_Fragment, { children: _jsxs("div", { className: s['wrap-consumption'], "data-testid": 'wrap-consumption', children: [_jsxs("div", { className: s['consumption-heading'], children: [_jsxs("div", { className: s['tabs-wrap-consumption'], children: [_jsx(TabsComponent, { value: valueTabs, changeTabs: setValueTabs, items: isLangRu
                                            ? INITIAL_TABS_CONSUMPTION_RU
                                            : INITIAL_TABS_CONSUMPTION_EN }), _jsx(TabsComponent, { value: displayMode, changeTabs: setDisplayMode, items: isLangRu ? INITIAL_DISPLAY_MODE_RU : INITIAL_DISPLAY_MODE_EN })] }), _jsxs("div", { ref: fileRef, onMouseMove: () => setTooltipVisible(true), onMouseLeave: () => setTooltipVisible(false), className: s['consumption-attachment'], children: [_jsx(File, { onClick: isDownloading ? undefined : download, loadingWithProgressSpin: true, loading: isDownloading, size: 's', extension: 'xlsx' }), tooltipVisible && (_jsx(Tooltip, { direction: 'upLeft', isInteractive: false, anchorRef: fileRef, children: _jsx("span", { className: s['consumption-attachment-tooltip'], children: isDownloading ? t('Loading') : t('UploadExcel') }) }))] })] }), _jsx("div", { className: s['forecasts-consumption-wrap'], children: forecastListCurrentYear.length &&
                            forecastListCurrentYear.map((key, index) => (_jsx(Forecast, { forecast: forecastCurrentYear[key], quantityName: measureUnit, quantityMonth: index === 0 ? '3' : index === 1 ? '6' : '12', label: index === 0 ? t('months') : t('monthsMuch') }, key))) }), _jsx("div", { className: s['schedule-body'], children: _jsx(ScheduleComponent, { switchFilterYear: switchValue, data: part_1List, axisYName: measureUnit, onReady: registerPaddingCurrentYear, paddingLeftOwn: paddings.currentYear, paddingLeftAnotherChart: paddings.lastYear, isQuarter: displayMode === 'Квартал' || displayMode === 'Quarter', isCumulative: true }) }), _jsxs("div", { className: s['consumption-last-year-wrap'], children: [_jsxs("div", { className: s['consumption-last-year-action'], children: [_jsx("div", { children: _jsx(Checkbox, { size: 'l', checked: consumptionLastYearFilter.consumptionIsLastYear, onChange: () => changeFIlterConsumption(!consumptionLastYearFilter.consumptionIsLastYear, 'consumptionIsLastYear'), label: t('ConsumptionPreviousYears'), className: s['consumption-last-year-checkbox'], "data-testid": 'consumption-last-year-checkbox' }) }), _jsxs("div", { children: [_jsx(DatePicker, { type: 'year', value: consumptionLastYearFilter.year, onChange: ({ value }) => changeFIlterConsumption(value, 'year'), maxDate: maxDate, ref: ref, status: errorDate ? 'alert' : undefined, onError: onErrorDate, className: s['consumption-last-year-datePicker'] }), errorDate && (_jsx(Tooltip, { status: 'alert', direction: 'rightCenter', anchorRef: ref, children: errorDate }))] })] }), consumptionLastYearFilter.consumptionIsLastYear &&
                                forecastLastYear && (_jsx("div", { "data-testid": 'consumption-last-year', children: _jsx(ConsumptionLastYear, { year: consumptionLastYearFilter.year, errorDate: errorDate, quantityName: measureUnit, accuracy: forecastLastYear.accuracy_metric, massaFact: forecastLastYear.massa_fact, massaPredict: forecastLastYear.massa_predict, dataSchedule: part_2List, paddingLeftAnotherChart: paddings.currentYear, paddingLeftOwn: paddings.lastYear, onReady: registerPaddingLastYear, isQuarter: displayMode === 'Квартал' || displayMode === 'Quarter' }) }))] }), loading && (_jsx("div", { className: s['overlay'], children: _jsx("div", { className: s['loader-wrap'], children: _jsx(LoaderComponent, {}) }) }))] }) })) }));
};
