import dayjs from 'dayjs';
import 'dayjs/locale/ru';
dayjs.locale('ru');
/**
 * Функция для получения уникальных значений годов.
 * @function
 * @param {TProcessedChartItem[]} data - данные для графика.
 * @returns {string[]} - уникальные года.
 */
export const getChartDataUniqueYears = (data) => {
    return Array.from(new Set(data.map((item) => String(item.year))));
};
/**
 * Функция для определения последнего месяца в данных для графика.
 * @function
 * @param {TProcessedChartItem[]} data - данные для графика.
 * @returns {string | undefined} - последний месяц, если он есть.
 */
export const getChartDataLastMonth = (data) => {
    return data[data.length - 1]?.x;
};
