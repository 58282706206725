// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiSelect-module__multiselect__k3vaq .select{width:332px}.MultiSelect-module__multiselect__k3vaq .Select-Delimiter{width:0}.MultiSelect-module__multiselect__k3vaq .Select-ClearIndicator{display:none}.MultiSelect-module__multiselect__k3vaq .Text_size_m{font-size:14px}.MultiSelect-module__multiselect-visible__fwk1V{position:relative}.MultiSelect-module__multiselect-visible__fwk1V .Select-ControlValue{display:none}.MultiSelect-module__textInput__HfPfE{position:absolute;top:39px;left:8px}`, "",{"version":3,"sources":["webpack://./src/components/UI/MultiSelect/MultiSelect.module.scss"],"names":[],"mappings":"AACE,gDACE,WAAA,CAGF,0DACE,OAAA,CAGF,+DACE,YAAA,CAGF,qDACE,cAAA,CAIJ,gDACE,iBAAA,CAEA,qEACE,YAAA,CAIJ,sCACE,iBAAA,CACA,QAAA,CACA,QAAA","sourcesContent":[".multiselect {\r\n  & :global(.select) {\r\n    width: 332px;\r\n  }\r\n\r\n  & :global(.Select-Delimiter) {\r\n    width: 0;\r\n  }\r\n\r\n  & :global(.Select-ClearIndicator) {\r\n    display: none;\r\n  }\r\n\r\n  & :global(.Text_size_m) {\r\n    font-size: 14px;\r\n  }\r\n}\r\n\r\n.multiselect-visible {\r\n  position: relative;\r\n\r\n  & :global(.Select-ControlValue) {\r\n    display: none;\r\n  }\r\n}\r\n\r\n.textInput {\r\n  position: absolute;\r\n  top: 39px;\r\n  left: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiselect": `MultiSelect-module__multiselect__k3vaq`,
	"multiselect-visible": `MultiSelect-module__multiselect-visible__fwk1V`,
	"textInput": `MultiSelect-module__textInput__HfPfE`
};
export default ___CSS_LOADER_EXPORT___;
