import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState, memo } from 'react';
import { RecommendationsModelingModal } from '../../components/RecommendationsModelingModal/RecommendationsModelingModal';
import { ButtonTooltipComponent } from '../ButtonTooltipComponent/ButtonTooltipComponent';
import { formatNumber, getFormatNumberQuantity } from '../../utils/utils';
import { InfoModalComponent } from '../UI/InfoModal/InfoModalComponent';
import { HOST_API_URL } from '../../constants/common.constants';
import Basket from '../../assets/resource/basketCart.svg';
import Truck from '../../assets/resource/trackSuplu.svg';
import s from './DOSynthesisRecommendations.module.scss';
import { Switch } from '@consta/uikit/Switch';
import dayjs from 'dayjs';
import axios from 'axios';
import { useAppSelector } from '../../store/hook';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../constants/enums/locale.enum';
export const DOSynthesisRecommendations = memo(({ item, setListRecommendationsSynthesisDO, refetchForecastsBalances, refetchRecommendationsDOList, setLoading, unit, }) => {
    const { t } = useTranslation();
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const [isOpenRecModelingModal, setIsOpenRecModelingModal] = useState(false);
    const encodedCredentials = JSON.parse(localStorage.getItem('encodedCredentials'));
    const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
    const { permissions } = useAppSelector((state) => state.user);
    const editPermission = permissions.includes('api.add_recommendation');
    const lang = localStorage.getItem('lang') || Locale.RUS;
    /**
     * Функция запроса на сервер по смене состояния switch карточки рекомендаций синтеза и ДО.
     * @function
     * @returns {void}
     */
    const changeSwitchRequest = () => {
        setLoading(true);
        axios
            .put(`${HOST_API_URL}/prediction_back/api/v1/recommendations/active_recommendations/?recommendations_type=for_groups&set_active=${!item.is_active}&id_list=${item.id}&lang=${lang}`, null, {
            headers: {
                Authorization: `Basic ${encodedCredentials}`,
            },
        })
            .then(() => successfulChangeSwitch())
            .finally(() => setLoading(false));
    };
    /**
     * Функция меняющие состояние switch, повторный запрос данных с сервера и закрытие модального окна.
     * @function
     * @returns {void}
     */
    const successfulChangeSwitch = () => {
        setListRecommendationsSynthesisDO((prevState) => {
            const updateList = prevState.map((itemPrev) => {
                if (itemPrev.id === item.id) {
                    return { ...itemPrev, is_active: !item.is_active };
                }
                return itemPrev;
            });
            return updateList;
        });
        refetchForecastsBalances();
        changeModalIsOpenInfo();
    };
    /**
     * Callback функция для изменения состояния открытия модального окна.
     * @function
     * @returns {void}
     */
    const changeModalIsOpenInfo = useCallback(() => {
        setIsOpenModalInfo((prevState) => !prevState);
    }, []);
    /**
     * Открывает или закрывает модальное окно редактирования моделирования.
     * @function
     * @returns {void}
     */
    const changeRecModelingModalOpen = () => {
        setIsOpenRecModelingModal((prevState) => !prevState);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s['synthesis-DO-block'], "data-testid": 'synthesis-DO-block', children: [_jsxs("div", { className: s['synthesis-DO-action'], children: [editPermission && (_jsxs("div", { className: s['synthesis-DO-header'], children: [_jsx(Switch, { size: 's', checked: item.is_active, onChange: changeModalIsOpenInfo, label: t('IncludeInCalculation'), className: s['switch-style-DO'], "data-testid": 'switch-style-DO' }), _jsxs("div", { className: s['synthesis-DO-btn-wrapper'], children: [_jsx(ButtonTooltipComponent, { changeRecModelingModalOpen: changeRecModelingModalOpen, text: t('SimulateValue'), typeTooltip: 'EDIT', styleButton: 'btn-edit-recommendations' }), item.use_user_data && (_jsx(ButtonTooltipComponent, { text: t('RecommendedValueChanged'), typeTooltip: 'INFO', styleButton: '' }))] })] })), _jsxs("div", { className: s['synthesis-DO-quantity-order'], children: [_jsxs("div", { children: [t('Order'), ' ', item.use_user_data && item.user_massa !== null
                                                ? formatNumber(item.user_massa)
                                                : formatNumber(item.massa), ' ', item.use_user_data && item.user_massa !== null
                                                ? getFormatNumberQuantity(item.user_massa, true, isLangRu)
                                                : getFormatNumberQuantity(item.massa, true, isLangRu), unit] }), !editPermission && item.use_user_data && (_jsx(ButtonTooltipComponent, { text: t('RecommendedValueChanged'), typeTooltip: 'INFO', styleButton: '' }))] })] }), _jsxs("div", { className: s['synthesis-DO-info'], children: [_jsxs("div", { className: s['synthesis-DO-order-wrap'], children: [_jsxs("div", { className: s['synthesis-DO-order-info'], children: [_jsx("img", { src: Basket, alt: '' }), _jsx("span", { children: t('OrderOne') })] }), _jsx("span", { className: s['synthesis-DO-data'], children: dayjs(item.order_date)
                                            .locale(isLangRu ? 'ru' : 'en')
                                            .format('MMMM YYYY') })] }), _jsxs("div", { className: s['synthesis-DO-order-wrap'], children: [_jsxs("div", { className: s['synthesis-DO-order-info'], children: [_jsx("img", { src: Truck, alt: '' }), _jsx("span", { children: t('Supply') })] }), _jsx("span", { className: s['synthesis-DO-data'], children: item.user_delivery_date && item.use_user_data
                                            ? dayjs(item.user_delivery_date)
                                                .locale(isLangRu ? 'ru' : 'en')
                                                .format('MMMM YYYY')
                                            : dayjs(item.delivery_date)
                                                .locale(isLangRu ? 'ru' : 'en')
                                                .format('MMMM YYYY') })] })] })] }), isOpenRecModelingModal && (_jsx(RecommendationsModelingModal, { recommendation: item, unit: unit, isModalOpen: isOpenRecModelingModal, changeOpenRecommendation: changeRecModelingModalOpen, refetchRecommendationsDOList: refetchRecommendationsDOList })), isOpenModalInfo && (_jsx(InfoModalComponent, { testId: 'infoModal', isModalOpen: isOpenModalInfo, changeModalIsOpen: changeModalIsOpenInfo, isSaveInfo: true, handleFileUpload: changeSwitchRequest, text: item.is_active
                    ? t('excludeCalculationRecommendation')
                    : t('enableCalculationRecommendation') }))] }));
});
