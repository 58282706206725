// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MetricDifference-module__metric-difference__Pkh7V{display:flex;align-items:center;gap:8px;padding-top:24px;border-top:1px solid rgba(0,32,51,.05);color:#b4b4b4;font-size:14px;font-weight:400;line-height:14px;letter-spacing:0em}.MetricDifference-module__metric-difference__Pkh7V .MetricDifference-module__difference__ztxP0{font-weight:500;display:flex;align-items:center;gap:4px}.MetricDifference-module__metric-difference__Pkh7V .MetricDifference-module__difference-positive__xoTPT{color:#09b37b}.MetricDifference-module__metric-difference__Pkh7V .MetricDifference-module__difference-negative__AQsd9{color:#eb3333}.MetricDifference-module__metric-difference__Pkh7V .MetricDifference-module__difference-negative__AQsd9 svg{transform:rotate(180deg)}`, "",{"version":3,"sources":["webpack://./src/components/MetricDifference/MetricDifference.module.scss"],"names":[],"mappings":"AAAA,mDACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,gBAAA,CACA,sCAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CAEA,+FACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,wGACE,aAAA,CAGF,wGACE,aAAA,CAEA,4GACE,wBAAA","sourcesContent":[".metric-difference {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 8px;\r\n  padding-top: 24px;\r\n  border-top: 1px solid rgba(0, 32, 51, 0.05);\r\n  color: rgba(180, 180, 180, 1);\r\n  font-size: 14px;\r\n  font-weight: 400;\r\n  line-height: 14px;\r\n  letter-spacing: 0em;\r\n\r\n  .difference {\r\n    font-weight: 500;\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 4px;\r\n\r\n    &-positive {\r\n      color: rgba(9, 179, 123, 1)\r\n    }\r\n\r\n    &-negative {\r\n      color: rgba(235, 51, 51, 1);\r\n\r\n      svg {\r\n        transform: rotate(180deg);\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"metric-difference": `MetricDifference-module__metric-difference__Pkh7V`,
	"difference": `MetricDifference-module__difference__ztxP0`,
	"difference-positive": `MetricDifference-module__difference-positive__xoTPT`,
	"difference-negative": `MetricDifference-module__difference-negative__AQsd9`
};
export default ___CSS_LOADER_EXPORT___;
