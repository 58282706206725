import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HOST_API_URL } from '../constants/common.constants';
export const recommendationsApi = createApi({
    reducerPath: 'recommendationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: HOST_API_URL,
        prepareHeaders: (headers) => {
            const encodedCredentials = JSON.parse(localStorage.getItem('encodedCredentials'));
            if (encodedCredentials) {
                headers.set('Authorization', `Basic ${encodedCredentials}`);
            }
            return headers;
        },
    }),
    tagTypes: ['get_recommendations'],
    endpoints: (builder) => ({
        getBusUnitBusiness: builder.query({
            query: ({ pageName, lang }) => ({
                url: `/prediction_back/api/v1/business_metrics/bus_units/?section=${pageName}&page=1&limit=30&lang=${lang}`,
            }),
        }),
        getGroupsMaterialBusiness: builder.query({
            query: ({ pageName, lang }) => ({
                url: `/prediction_back/api/v1/business_metrics/groups_of_materials/?section=${pageName}&page=1&limit=20&lang=${lang}`,
            }),
        }),
        getAnalogGroupsBusiness: builder.query({
            query: (filter) => ({
                url: `/prediction_back/api/v1/forecasts/analog_groups/?page=1&limit=40`,
                params: filter,
            }),
        }),
        getDecisionPeriods: builder.query({
            query: (filter) => ({
                url: `/prediction_back/api/v1/recommendations/decision_periods/`,
                params: filter,
            }),
        }),
        getForecastsBalances: builder.query({
            query: (filter) => ({
                url: `/prediction_back/api/v1/recommendations/forecasts_and_balances/`,
                params: filter,
            }),
            providesTags: ['get_recommendations'],
        }),
        getRecommendationsDO: builder.query({
            query: ({ filter }) => ({
                url: `/prediction_back/api/v1/recommendations/recommendations_ag_bu_info/`,
                params: filter,
            }),
            providesTags: ['get_recommendations'],
        }),
        getRecommendationsNonGuaranteed: builder.query({
            query: (filter) => ({
                url: `/prediction_back/api/v1/recommendations/recommendations_ag_info/?card_type=non_guaranteed`,
                params: filter,
            }),
            providesTags: ['get_recommendations'],
        }),
        getRecommendationsWithoutMoving: builder.query({
            query: (filter) => ({
                url: `/prediction_back/api/v1/recommendations/recommendations_ag_info/?card_type=without_moving`,
                params: filter,
            }),
            providesTags: ['get_recommendations'],
        }),
        getRecommendationsCardInfo: builder.query({
            query: (filter) => ({
                url: `/prediction_back/api/v1/recommendations/detail_redistribution_card_info/`,
                params: filter,
            }),
            providesTags: ['get_recommendations'],
        }),
        getWarehousesList: builder.query({
            query: (filter) => ({
                url: `/prediction_back/api/v1/recommendations/warehouses/?page=1&limit=50`,
                params: filter,
            }),
        }),
        putRecommendationsUpdate: builder.mutation({
            query: ({ body, lang }) => ({
                method: 'PUT',
                url: `/prediction_back/api/v1/recommendations/update_recommendations_ag_bu_info/?lang=${lang}`,
                params: body,
            }),
            invalidatesTags: ['get_recommendations'],
        }),
        getRecommendationsCreate: builder.mutation({
            query: ({ body, lang }) => ({
                method: 'POST',
                url: `/prediction_back/api/v1/recommendations/recommendations_create/?lang=${lang}`,
                body: body,
            }),
            invalidatesTags: ['get_recommendations'],
        }),
        getRecommendationsDelete: builder.mutation({
            query: ({ urlFilter }) => ({
                method: 'DELETE',
                url: `/prediction_back/api/v1/recommendations/recommendations_delete/`,
                params: urlFilter,
            }),
            invalidatesTags: ['get_recommendations'],
        }),
    }),
});
export const { useGetDecisionPeriodsQuery, useGetForecastsBalancesQuery, useGetRecommendationsDOQuery, useGetRecommendationsCardInfoQuery, useGetRecommendationsCreateMutation, useGetRecommendationsDeleteMutation, useGetRecommendationsNonGuaranteedQuery, useGetRecommendationsWithoutMovingQuery, useGetWarehousesListQuery, useGetAnalogGroupsBusinessQuery, useGetBusUnitBusinessQuery, useGetGroupsMaterialBusinessQuery, usePutRecommendationsUpdateMutation, } = recommendationsApi;
