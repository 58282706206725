// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectComponent-module__select__RHZZx{width:100%}.SelectComponent-module__select__RHZZx .Select-Delimiter{width:0}.SelectComponent-module__select__RHZZx .Select-ClearIndicator{display:none}.SelectComponent-module__select__RHZZx .Text_size_m{font-size:14px}`, "",{"version":3,"sources":["webpack://./src/components/UI/Select/SelectComponent.module.scss"],"names":[],"mappings":"AAAA,uCACE,UAAA,CAEA,yDACE,OAAA,CAGF,8DACE,YAAA,CAGF,oDACE,cAAA","sourcesContent":[".select {\r\n  width: 100%;\r\n\r\n  & :global(.Select-Delimiter) {\r\n    width: 0;\r\n  }\r\n\r\n  & :global(.Select-ClearIndicator) {\r\n    display: none;\r\n  }\r\n\r\n  & :global(.Text_size_m) {\r\n    font-size: 14px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `SelectComponent-module__select__RHZZx`
};
export default ___CSS_LOADER_EXPORT___;
