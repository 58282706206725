export const TYPE_ORDER_RU = [
    'Потребление',
    'Рекомендации',
    'Перераспределения',
    'Заказы РЦ',
    'Заказы ДО',
    'Остатки на начало периода РЦ',
    'Остатки на начало периода ДО',
];
export const TYPE_ORDER_EN = [
    'Demand',
    'Recommendations',
    'Redistributions',
    'Orders DC',
    'Orders BU',
    'Inventory level at the beginning of the period DC',
    'Inventory level at the beginning of the period BU',
];
export const COLUMN_COLOR_LIST_RU = [
    {
        label: 'Остатки фактические всего на конец периода',
        color: 'rgba(2, 85, 147, 1)',
        type: 'line',
        name: 'line',
    },
    {
        label: 'Остатки прогнозные всего на конец периода',
        color: 'rgb(143 36 13)',
        type: 'line',
        name: 'line',
    },
    {
        label: 'Рекомендации',
        color: 'rgba(75, 194, 130, 1)',
        type: 'circle',
        name: 'recommendations',
    },
    {
        label: 'Перераспределения',
        color: 'rgba(244, 115, 87, 1)',
        type: 'circle',
        name: 'redistributions',
    },
    {
        label: 'Заказы РЦ',
        color: 'rgba(43, 191, 238, 1)',
        type: 'circle',
        name: 'detailedView',
    },
    {
        label: 'Заказы ДО',
        color: 'rgba(0, 120, 210, 1)',
        type: 'circle',
        name: 'detailedView',
    },
    {
        label: 'Остатки на начало периода РЦ',
        color: 'rgba(241, 185, 75, 1)',
        type: 'circle',
        name: 'detailedView',
    },
    {
        label: 'Остатки на начало периода ДО',
        color: 'rgba(134, 121, 52, 1)',
        type: 'circle',
        name: 'detailedView',
    },
    {
        label: 'Потребление',
        color: 'rgba(214, 48, 167, 1)',
        type: 'circle',
        name: 'detailedView',
    },
];
export const COLUMN_COLOR_LIST_EN = [
    {
        label: 'Total actual inventory level at the end of the period',
        color: 'rgba(2, 85, 147, 1)',
        type: 'line',
        name: 'line',
    },
    {
        label: 'Total forecast inventory level at the end of the period',
        color: 'rgb(143 36 13)',
        type: 'line',
        name: 'line',
    },
    {
        label: 'Recommendations',
        color: 'rgba(75, 194, 130, 1)',
        type: 'circle',
        name: 'recommendations',
    },
    {
        label: 'Redistributions',
        color: 'rgba(244, 115, 87, 1)',
        type: 'circle',
        name: 'redistributions',
    },
    {
        label: 'Orders DC',
        color: 'rgba(43, 191, 238, 1)',
        type: 'circle',
        name: 'detailedView',
    },
    {
        label: 'Orders BU',
        color: 'rgba(0, 120, 210, 1)',
        type: 'circle',
        name: 'detailedView',
    },
    {
        label: 'Inventory level at the beginning of the period DC',
        color: 'rgba(241, 185, 75, 1)',
        type: 'circle',
        name: 'detailedView',
    },
    {
        label: 'Inventory level at the beginning of the period BU',
        color: 'rgba(134, 121, 52, 1)',
        type: 'circle',
        name: 'detailedView',
    },
    {
        label: 'Demand',
        color: 'rgba(214, 48, 167, 1)',
        type: 'circle',
        name: 'detailedView',
    },
];
export const SWITCHES_RECOMMENDATIONS_SCHEDULE_RU = [
    { key: 'recommendations', label: 'Рекомендации' },
    { key: 'redistributions', label: 'Перераспределения' },
    { key: 'detailedView', label: 'Детализированный вид' },
    { key: 'makingDecisions', label: 'Периоды принятия решений' },
];
export const SWITCHES_RECOMMENDATIONS_SCHEDULE_EN = [
    { key: 'recommendations', label: 'Recommendations' },
    { key: 'redistributions', label: 'Redistributions' },
    { key: 'detailedView', label: 'Detailed view' },
    { key: 'makingDecisions', label: 'Decision periods' },
];
