import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { formatNumber, getFormatNumberQuantity } from '../../../utils/utils';
dayjs.locale('ru');
/**
 * Функция для форматирования данных для графика на основе данных listSchedules.
 * @function
 * @param {IListSchedules[]} data - Данные для форматирования.
 * @returns {TProcessedListSchedulesPart[]} - Отформатированные данные с установленной осью X.
 */
export const processListSchedules = (data) => {
    return data.map((item) => ({
        ...item,
        x: item.month,
    }));
};
/**
 * Функция для фильтрации данных по году на основе значений из меню.
 * @function
 * @param {TProcessedListSchedulesPart[]} data - данные для фильтрации.
 * @param {string[]} years - значения годов в меню.
 * @returns {TProcessedListSchedulesPart[]} - отфильтрованные значения.
 */
export const filterListSchedulesByYears = (data, years) => {
    if (years.length > 0) {
        return data.filter((item) => {
            const year = dayjs(item.month).format('YYYY');
            return years.includes(year);
        });
    }
    else {
        return [];
    }
};
/**
 * Функция для определения последнего месяца в данных для графика для предыдущих годов.
 * @function
 * @param {TProcessedListSchedulesPart[]} data - данные для графика.
 * @returns {string | undefined} - последний месяц, если он есть.
 */
export const getListSchedulesLastMonthOldYear = (data) => {
    const sortData = data.sort((a, b) => {
        const monthA = parseInt(a.x.split('-')[1]);
        const monthB = parseInt(b.x.split('-')[1]);
        return monthB - monthA;
    });
    return sortData[0]?.x;
};
/**
 * Функция для заполнения графика при остуствии данных за месяц пустыми значениями.
 * @function
 * @param {TProcessedListSchedulesPart[]} data - данные для графика.
 * @param {string[]} years - годы.
 * @returns {TProcessedListSchedulesPart[]} - дополненные данные для графика.
 */
export const addListSchedulesMissingMonths = (data) => {
    if (data.length > 0) {
        data.sort((a, b) => dayjs(a.x).valueOf() - dayjs(b.x).valueOf());
        const lastYear = dayjs(data[data.length - 1].x).year();
        const lastMonth = dayjs(data[data.length - 1].x).month() +
            1;
        for (let month = lastMonth + 1; month <= 12; month++) {
            const x = `${lastYear}-${month.toString().padStart(2, '0')}`;
            data.push({
                month: x,
                x,
                massa: null,
                line: null,
                year: lastYear,
            });
        }
    }
    return data;
};
/**
 * Функция для получения цвета линии графика.
 * @function
 * @param {TBaseProcessedListSchedulesPart} datum - данные линии.
 * @returns {string} - цвет линии.
 */
export const getListSchedulesLineColor = (datum) => {
    switch (datum.line) {
        case 'blue':
            return '#0078D2';
        case 'grey':
            return '#868686';
        default:
            return '#0078D2';
    }
};
/**
 * Функция для форматирования данных для тултипа графика.
 * @function
 * @param {Datum} datum - Данные для форматирования.
 * @returns {TProcessedRecommendationsScheduleItem[]} - Отформатированные данные с установленной осью X.
 */
export const recommendationsSchedulesFormatter = (datum, isLangRu) => {
    const value = datum['massa'];
    const name = datum['line'] === 'blue'
        ? isLangRu
            ? 'Прогноз'
            : 'Forecast'
        : isLangRu
            ? 'Факт'
            : 'Actual';
    const numValue = Number(value);
    if (numValue) {
        const quantity = getFormatNumberQuantity(numValue, false, isLangRu);
        const formattedValue = quantity
            ? `${formatNumber(numValue)} ${quantity}`
            : formatNumber(numValue);
        return {
            name,
            value: formattedValue,
        };
    }
    return {
        name,
        value,
    };
};
/**
 * Функция для получение квартала.
 * @function
 * @param {string} month - Данные для форматирования.
 * @returns {number} - квартал.
 */
const getQuarter = (month) => {
    return Math.floor((dayjs(month).month() + 3) / 3);
};
/**
 * Функция для разбиения данных по кварталам.
 * @function
 * @param {TProcessedListSchedulesPart[]} data - Данные для форматирования.
 * @returns {TBaseProcessedListSchedulesPart[]} - данные по кварталам.
 */
export const getQuarterListSchedules = (data) => {
    const dataByQuarters = data.reduce((accum, item) => {
        const quarter = `${item.year} ${getQuarter(item.month)}`;
        const line = item.line;
        if (accum[quarter]) {
            if (accum[quarter][line]) {
                return {
                    ...accum,
                    [quarter]: {
                        ...accum[quarter],
                        [line]: item.massa
                            ? item.massa + accum[quarter][line]
                            : accum[quarter][line],
                    },
                };
            }
            return {
                ...accum,
                [quarter]: {
                    ...accum[quarter],
                    [line]: item.massa,
                },
            };
        }
        return {
            ...accum,
            [quarter]: {
                [line]: item.massa,
            },
        };
    }, {});
    const processedData = Object.keys(dataByQuarters).reduce((accum, key) => {
        const quarterData = dataByQuarters[key];
        Object.keys(quarterData).forEach((line) => {
            accum.push({
                line,
                x: key,
                massa: quarterData[line] || null,
                year: Number(key.split(' ')[0]),
            });
        });
        return accum;
    }, []);
    return processedData;
};
export const getQuarterData = (data) => {
    // Получим текущую дату
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3);
    // Создадим объект для хранения результатов
    const quarterData = {};
    // Пройдемся по каждому элементу в массиве данных
    data.forEach((item) => {
        const year = item.year;
        const quarter = Math.ceil(Number(item.month.split('-')[1]) / 3);
        const key = `${year} ${quarter}`;
        if (!quarterData[key]) {
            quarterData[key] = { grey: null, blue: 0 };
        }
        if (item.line === 'grey') {
            if (year < currentYear ||
                (year === currentYear && quarter <= currentQuarter)) {
                quarterData[key].grey = item.massa || 0;
            }
        }
        else if (item.line === 'blue') {
            quarterData[key].blue = item.massa || 0;
        }
    });
    const result = [];
    for (const key in quarterData) {
        const [year, quarter] = key.split(' ');
        const grey = quarterData[key].grey;
        const blue = quarterData[key].blue;
        result.push({
            line: 'grey',
            x: `${year} ${quarter}`,
            massa: grey,
            year: Number(year),
        }, { line: 'blue', x: `${year} ${quarter}`, massa: blue, year: Number(year) });
    }
    return result;
};
/**
 * Функция для форматирования лейблов по месяцам.
 * @function
 * @param {string} text - Данные для форматирования.
 * @returns {string} - текст для лейбла.
 */
export const monthFormatter = (text, isLangRu = true) => {
    return text
        ? dayjs(text)
            .locale(isLangRu ? 'ru' : 'en')
            .format('MMM')
        : '';
};
/**
 * Функция для форматирования лейблов по кварталам.
 * @function
 * @param {string} text - Данные для форматирования.
 * @returns {string} - текст для лейбла.
 */
export const quarterFormatter = (text, isLangRu = true) => {
    const [, qurater] = text.split(' ');
    return qurater ? `${qurater} ${isLangRu ? 'квартал' : 'quarter'}` : '';
};
/**
 * Функция для форматирования титула тултипа по кварталам.
 * @function
 * @param {string} text - Данные для форматирования.
 * @returns {string} - текст для титула.
 */
export const quarterTooltipTitleFormatter = (text, isLangRu) => {
    const [year, quarter] = text.split(' ');
    return `${quarter} ${isLangRu ? 'квартал' : 'quarter'} ${year}`;
};
/**
 * Функция для форматирования титула тултипа по месяцам.
 * @function
 * @param {string} text - Данные для форматирования.
 * @returns {string} - текст для титула.
 */
export const monthTooltipTitleFormatter = (text, isLangRu) => {
    const [year] = text.split('-');
    const month = dayjs(text)
        .locale(isLangRu ? 'ru' : 'en')
        .format('MMMM');
    return `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
};
/**
 * Функция для форматирования чисел оси Y.
 * @function
 * @param {string} value - Данные для форматирования.
 * @returns {string} - полученное значение лейбла.
 */
export const yLabelFormatter = (value, isLangRu) => {
    const num = Number(value);
    if (num) {
        const quantity = getFormatNumberQuantity(num, false, isLangRu);
        return quantity ? `${formatNumber(num)} ${quantity}` : formatNumber(num);
    }
    return value;
};
/**
 * Функция для форматирования данных и фильтрации массива.
 * @function
 * @param {any[]} data - Исходные данные для обработки.
 * @param {number} lengthYear - Длина года для определения вставки данных.
 * @param {boolean} isQuarter - Флаг, указывающий, используется ли квартальный формат.
 * @returns {TProcessedListSchedulesPart[]} - Отфильтрованный массив данных.
 */
export const cumulativeFormatDate = (data, lengthYear, isQuarter = false) => {
    let filterLastFactMonth = data;
    const currentDate = new Date();
    const currentYear = Number(dayjs(currentDate).format('YYYY'));
    if (!isQuarter) {
        filterLastFactMonth = data.filter((item) => {
            if (item.line === 'grey') {
                const itemDate = new Date(item.x);
                return itemDate <= currentDate;
            }
            return true;
        });
    }
    if (lengthYear > 1) {
        const indexDataYear = filterLastFactMonth.findIndex((item) => item.year === Number(dayjs(new Date()).format('YYYY')) + 1);
        filterLastFactMonth.splice(indexDataYear, 0, {
            line: 'blue',
            massa: null,
            month: '',
            x: '',
            year: currentYear + 1,
        });
    }
    return filterLastFactMonth;
};
