import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HOST_API_URL } from '../constants/common.constants';
export const businessMetricsApi = createApi({
    reducerPath: 'businessMetricsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: HOST_API_URL,
        prepareHeaders: (headers) => {
            const encodedCredentials = JSON.parse(localStorage.getItem('encodedCredentials'));
            if (encodedCredentials) {
                headers.set('Authorization', `Basic ${encodedCredentials}`);
            }
            return headers;
        },
    }),
    tagTypes: ['get_business_metrics'],
    endpoints: (builder) => ({
        getBusinessMetrics: builder.query({
            query: ({ filter }) => ({
                url: `/prediction_back/api/v1/business_metrics/get_business_metrics/`,
                params: filter,
            }),
            providesTags: ['get_business_metrics'],
        }),
    }),
});
export const { useGetBusinessMetricsQuery } = businessMetricsApi;
