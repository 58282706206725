import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useRef, useState } from 'react';
import s from './ButtonTooltipComponent.module.scss';
import { Tooltip } from '@consta/uikit/Tooltip';
import { IconEdit } from '@consta/icons/IconEdit';
import { IconAlert } from '@consta/icons/IconAlert';
export const ButtonTooltipComponent = memo(({ changeRecModelingModalOpen, text, typeTooltip, styleButton }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const infoRef = useRef(null);
    const openEditModal = () => {
        if (typeTooltip === 'INFO')
            return;
        if (changeRecModelingModalOpen)
            changeRecModelingModalOpen();
    };
    return (_jsxs("button", { className: s[styleButton], onClick: openEditModal, ref: infoRef, onMouseMove: () => setTooltipVisible(true), onMouseLeave: () => setTooltipVisible(false), children: [typeTooltip === 'EDIT' && _jsx(IconEdit, { size: 's' }), typeTooltip === 'INFO' && _jsx(IconAlert, { size: 's', view: 'warning' }), typeTooltip === 'REVERT' && (_jsxs("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '27', height: '27', fill: 'inherit', children: [_jsx("path", { fill: 'currentColor', d: 'M7.11 18.743a.712.712 0 0 1 1.003.108c2.541 3.175 7.194 3.693 10.369 1.152 3.174-2.542 3.687-7.195 1.145-10.37-2.39-2.985-6.647-3.615-9.786-1.567l1.028 1.283c.159.199.2.47.105.707a.716.716 0 0 1-.216.298.678.678 0 0 1-.339.146l-4.73.724a.714.714 0 0 1-.774-.967l1.74-4.459a.715.715 0 0 1 1.224-.184L8.946 6.95c3.756-2.541 8.913-1.812 11.797 1.79 3.034 3.79 2.42 9.341-1.371 12.376-3.79 3.034-9.342 2.42-12.376-1.371a.712.712 0 0 1 .113-1.002Z' }), _jsx("path", { fill: 'currentColor', d: 'M12 18.273V11h2.457c.57 0 1.037.103 1.4.309.364.204.634.48.81.827.174.348.262.737.262 1.165 0 .429-.088.818-.263 1.168-.173.35-.44.63-.802.838-.363.207-.827.31-1.392.31H12.71v-.782h1.733c.39 0 .705-.067.941-.202.237-.135.409-.317.515-.547.109-.232.164-.494.164-.785 0-.291-.055-.552-.164-.781-.106-.23-.28-.41-.518-.54-.24-.133-.557-.199-.952-.199h-1.548v6.492H12Z' })] })), tooltipVisible && (_jsx(Tooltip, { direction: 'upLeft', isInteractive: false, anchorRef: infoRef, children: _jsx("span", { className: s['tooltip-text'], children: text }) }))] }));
});
