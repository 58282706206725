// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinkHeaderRoute-module__link__p63vq{position:relative;font-size:14px;font-weight:400;color:rgba(0,32,51,.6);transition:all 200ms ease-in}.LinkHeaderRoute-module__link__p63vq div{position:absolute;top:37px;width:100%;height:2px;background-color:#0078d2;opacity:0;transition:all 200ms ease-in}.LinkHeaderRoute-module__active-link__TazjP{position:relative;font-size:14px;font-weight:500;color:#002033;transition:all 200ms ease-in}.LinkHeaderRoute-module__active-link__TazjP div{opacity:1;transition:all 200ms ease-in}`, "",{"version":3,"sources":["webpack://./src/components/LinkHeaderRoute/LinkHeaderRoute.module.scss"],"names":[],"mappings":"AAAA,qCACE,iBAAA,CACA,cAAA,CACA,eAAA,CACA,sBAAA,CACA,4BAAA,CAEA,yCACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,UAAA,CACA,wBAAA,CACA,SAAA,CACA,4BAAA,CAIJ,4CACE,iBAAA,CACA,cAAA,CACA,eAAA,CACA,aAAA,CACA,4BAAA,CAEA,gDACE,SAAA,CACA,4BAAA","sourcesContent":[".link {\r\n  position: relative;\r\n  font-size: 14px;\r\n  font-weight: 400;\r\n  color: rgba(0, 32, 51, 0.6);\r\n  transition: all 200ms ease-in;\r\n\r\n  div {\r\n    position: absolute;\r\n    top: 37px;\r\n    width: 100%;\r\n    height: 2px;\r\n    background-color: #0078D2;\r\n    opacity: 0;\r\n    transition: all 200ms ease-in;\r\n  }\r\n}\r\n\r\n.active-link {\r\n  position: relative;\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n  color: #002033;\r\n  transition: all 200ms ease-in;\r\n\r\n  div {\r\n    opacity: 1;\r\n    transition: all 200ms ease-in;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `LinkHeaderRoute-module__link__p63vq`,
	"active-link": `LinkHeaderRoute-module__active-link__TazjP`
};
export default ___CSS_LOADER_EXPORT___;
