// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Forecast-module__forecast-wrap__G1FUM{height:100%;display:flex;flex-direction:column;justify-content:space-between;box-shadow:0px 0px 7px 0px rgba(0,88,137,.2);border-radius:8px;padding:8px 16px;flex:1;gap:12px}.Forecast-module__forecast-month__HYgkG{display:flex;flex-direction:column;color:rgba(0,32,51,.6)}.Forecast-module__forecast-month__HYgkG span:first-child{font-weight:500px}.Forecast-module__forecast-month__HYgkG span:last-child{font-size:12px}.Forecast-module__forecast-quantity__SDn70{display:flex;gap:12px}.Forecast-module__forecast-quantity__SDn70 span:first-child{font-size:24px;color:#0078d2;line-height:18px}.Forecast-module__forecast-quantity__SDn70 span:last-child{color:rgba(0,32,51,.6);line-height:22px}`, "",{"version":3,"sources":["webpack://./src/components/Forecast/Forecast.module.scss"],"names":[],"mappings":"AAAA,uCACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,4CAAA,CACA,iBAAA,CACA,gBAAA,CACA,MAAA,CACA,QAAA,CAGF,wCACE,YAAA,CACA,qBAAA,CACA,sBAAA,CAEA,yDACE,iBAAA,CAGF,wDACE,cAAA,CAIJ,2CACE,YAAA,CACA,QAAA,CAEA,4DACE,cAAA,CACA,aAAA,CACA,gBAAA,CAGF,2DACE,sBAAA,CACA,gBAAA","sourcesContent":[".forecast-wrap {\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n  box-shadow: 0px 0px 7px 0px rgba(0, 88, 137, 0.2);\r\n  border-radius: 8px;\r\n  padding: 8px 16px;\r\n  flex: 1;\r\n  gap: 12px\r\n}\r\n\r\n.forecast-month {\r\n  display: flex;\r\n  flex-direction: column;\r\n  color: rgba(0, 32, 51, 0.6);\r\n\r\n  span:first-child {\r\n    font-weight: 500px;\r\n  }\r\n\r\n  span:last-child {\r\n    font-size: 12px;\r\n  }\r\n}\r\n\r\n.forecast-quantity {\r\n  display: flex;\r\n  gap: 12px;\r\n\r\n  span:first-child {\r\n    font-size: 24px;\r\n    color: rgba(0, 120, 210, 1);\r\n    line-height: 18px;\r\n  }\r\n\r\n  span:last-child {\r\n    color: rgba(0, 32, 51, 0.6);\r\n    line-height: 22px\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forecast-wrap": `Forecast-module__forecast-wrap__G1FUM`,
	"forecast-month": `Forecast-module__forecast-month__HYgkG`,
	"forecast-quantity": `Forecast-module__forecast-quantity__SDn70`
};
export default ___CSS_LOADER_EXPORT___;
