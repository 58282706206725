import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useCallback, memo } from 'react';
import s from './DatePickerComponent.module.scss';
import { enUS, ru } from 'date-fns/locale';
import { DatePicker } from '@consta/uikit/DatePicker';
import { Locale } from '../../../constants/enums/locale.enum';
export const DatePickerComponent = memo(({ value, changeFilter, type, label, placeholder, filter, icon = true, disabled = false, errorDate, onErrorDate, minDate, }) => {
    const [langRu, setLangRu] = useState(true);
    const examinationLang = useCallback(() => {
        const lang = localStorage.getItem('lang');
        setLangRu(lang === Locale.RUS);
    }, []);
    useEffect(() => {
        setInterval(() => {
            examinationLang();
        }, 1000);
    }, [examinationLang]);
    const iconStyles = label
        ? 'date-picker-icon-labeled'
        : 'date-picker-icon';
    return (_jsxs("div", { className: s['date-picker-wrap'], children: [_jsx(DatePicker, { value: value, onChange: ({ value }) => changeFilter(value, filter), type: type, label: label, placeholder: placeholder, disabled: disabled, onError: onErrorDate, status: errorDate ? 'alert' : undefined, minDate: minDate, locale: langRu ? ru : enUS }), icon && (_jsx("div", { className: s[iconStyles], children: _jsxs("svg", { width: '23', height: '24', viewBox: '0 0 23 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', children: [_jsx("path", { d: 'M18.2083 4H4.79167C3.73312 4 2.875 4.89543 2.875 6V20C2.875 21.1046 3.73312 22 4.79167 22H18.2083C19.2669 22 20.125 21.1046 20.125 20V6C20.125 4.89543 19.2669 4 18.2083 4Z', stroke: 'currentColor', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }), _jsx("path", { d: 'M15.3333 2V6', stroke: 'currentColor', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }), _jsx("path", { d: 'M7.66666 2V6', stroke: 'currentColor', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }), _jsx("path", { d: 'M2.875 10H20.125', stroke: 'currentColor', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' })] }) }))] }));
});
