import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HOST_API_URL } from '../constants/common.constants';
export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: HOST_API_URL,
    }),
    tagTypes: ['get_users'],
    endpoints: (builder) => ({
        getLogin: builder.mutation({
            query: ({ encodedCredentials, lang }) => ({
                url: `/prediction_back/api/v1/login/?lang=${lang}`,
                method: 'POST',
                headers: {
                    Authorization: `Basic ${encodedCredentials}`,
                    'Content-Type': 'application/vnd.google-earth.kml+xml; charset=utf-8',
                },
            }),
        }),
        getUsers: builder.query({
            query: ({ userId, lang }) => `/prediction_back/api/v1/users/${userId}/?lang=${lang}`,
            providesTags: ['get_users'],
            transformResponse: (res) => {
                return res.docs[0];
            },
        }),
        getUserPermissions: builder.query({
            query: ({ userId, encodedCredentials, lang }) => ({
                url: `/prediction_back/api/v1/users/get_user_permission?user_id=${userId}&lang=${lang}`,
                headers: {
                    Authorization: `Basic ${encodedCredentials}`,
                    'Content-Type': 'application/vnd.google-earth.kml+xml; charset=utf-8',
                },
            }),
        }),
    }),
});
export const { useGetUsersQuery, useGetLoginMutation, useGetUserPermissionsQuery, } = usersApi;
