/**
 * Функция для скачивания файла.
 * @function
 * @param {Blob} data - файл.
 * @param {string} filename - Имя файла.
 * @param {string} mimeType - тип.
 * @returns {void}
 */
export const downloadFile = (data, filename, mimeType) => {
    const blob = new Blob([data], {
        type: mimeType || 'application/octet-stream',
    });
    const blobUrl = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobUrl;
    tempLink.setAttribute('download', filename || 'unknown file');
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobUrl);
};
/**
 * Функция парсинга имени файла.
 * @function
 * @param {string | undefined} contentDisposition - значение contentDisposition в хедере результата загрузки файла.
 * @returns {string | undefined} - имя файла если оно есть
 */
export const parseContentDisposition = (contentDisposition) => {
    return contentDisposition
        ? contentDisposition
            .split(';')
            .find((filenameString) => filenameString.includes('filename='))
            ?.replace('filename=', '')
            .trim()
        : undefined;
};
