// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GaugeComponent-module__wrap-gauge__x_dhD{position:relative;width:100px;height:100px}.GaugeComponent-module__wrap-text__jgRYI{margin-top:-20px;display:flex;flex-direction:column;justify-content:center;align-items:center}.GaugeComponent-module__wrap-text__jgRYI span:first-child{font-size:14px;color:rgba(0,32,51,.85)}.GaugeComponent-module__wrap-text__jgRYI span:last-child{font-size:14px;white-space:nowrap;color:rgba(0,32,51,.6)}.GaugeComponent-module__accuracy-value__CTxTJ{position:absolute;top:78px;left:0;right:0;font-size:18px;font-weight:600;line-height:13px;text-align:center;color:#0078d2}`, "",{"version":3,"sources":["webpack://./src/components/UI/Gauge/GaugeComponent.module.scss"],"names":[],"mappings":"AAAA,0CACE,iBAAA,CACA,WAAA,CACA,YAAA,CAGF,yCACE,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,0DACE,cAAA,CACA,uBAAA,CAGF,yDACE,cAAA,CACA,kBAAA,CACA,sBAAA,CAIJ,8CACE,iBAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CAEA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,aAAA","sourcesContent":[".wrap-gauge {\r\n  position: relative;\r\n  width: 100px;\r\n  height: 100px;\r\n}\r\n\r\n.wrap-text {\r\n  margin-top: -20px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n\r\n  span:first-child {\r\n    font-size: 14px;\r\n    color: rgba(0, 32, 51, 0.85);\r\n  }\r\n\r\n  span:last-child {\r\n    font-size: 14px;\r\n    white-space: nowrap;\r\n    color: rgba(0, 32, 51, 0.6);\r\n  }\r\n}\r\n\r\n.accuracy-value {\r\n  position: absolute;\r\n  top: 78px;\r\n  left: 0;\r\n  right: 0;\r\n\r\n  font-size: 18px;\r\n  font-weight: 600;\r\n  line-height: 13px;\r\n  text-align: center;\r\n  color: rgba(0, 120, 210, 1);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-gauge": `GaugeComponent-module__wrap-gauge__x_dhD`,
	"wrap-text": `GaugeComponent-module__wrap-text__jgRYI`,
	"accuracy-value": `GaugeComponent-module__accuracy-value__CTxTJ`
};
export default ___CSS_LOADER_EXPORT___;
