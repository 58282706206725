import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { LinkRoute } from './components/LinkRoute/LinkRoute';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import { useTranslation } from 'react-i18next';
export const App = () => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        // Обновите заголовок страницы при смене языка
        document.title = t('title');
    }, [i18n.language, t]);
    return (_jsx(Theme, { preset: presetGpnDefault, children: _jsx(LinkRoute, {}) }));
};
