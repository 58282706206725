import dayjs from 'dayjs';
export const INITIAL_FILTER = {
    commodityGroup: '',
    synthesisGroup: '',
    choiceDO: '',
};
export const INITIAL_FORMS_DOCUMENT = {
    doc_type: '',
    active_from: null,
};
export const INITIAL_STRING_REQUEST = {
    stringRequestSchedule: '',
    stringRequestCurrentYear: '',
    stringRequestLastYear: '',
    stringRequestDownload: '',
};
export const INITIAL_SHOW_LINES = {
    recommendations: true,
    makingDecisions: false,
    redistributions: true,
    detailedView: true,
};
export const INITIAL_FILTERS_DOCUMENT = {
    file_name: null,
    doc_type: null,
    created_date_from: null,
    created_date_to: null,
};
export const INITIAL_FILTERS_ALL_VALUE = {
    business_metrics: {
        ...INITIAL_FILTER,
        period: new Date(),
    },
    recommendations: INITIAL_FILTER,
    consumption: INITIAL_FILTER,
    registerDocuments: INITIAL_FILTERS_DOCUMENT,
};
export const INITIAL_VALUE_SWITCH = {
    '2023': true,
    '2024': false,
};
export const INITIAL_TABS_VALUE = ['Трубы НКТ', 'Труба обсадная'];
export const INITIAL_TABS_CONSUMPTION_RU = ['БЕИ', 'Рубли'];
export const INITIAL_TABS_CONSUMPTION_EN = ['BUOM', 'Rubles'];
export const INITIAL_DISPLAY_MODE_RU = ['Месяц', 'Квартал'];
export const INITIAL_DISPLAY_MODE_EN = ['Month', 'Quarter'];
export const INITIAL_TABS_STOCK_RU = [
    'Суммарные остатки',
    'По складам',
];
export const INITIAL_TABS_STOCK_EN = ['Total stock', 'By warehouses'];
export const INITIAL_ENTRANCE_USER_DATA = {
    login: '',
    password: '',
};
export const INITIAL_CONSUMPTION_LAST_YEAR = {
    consumptionIsLastYear: false,
    year: dayjs().subtract(1, 'year').toDate(),
};
export const INITIAL_GROUP_FILTERS_DATA = {
    groupMaterialList: [],
    groupAnalogsList: [],
    busUnitsDocList: [],
};
export const INITIAL_BM_FILTERS_DATA = {
    ...INITIAL_GROUP_FILTERS_DATA,
    period: null,
};
