import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { LoaderComponent } from '../Loader/LoaderComponent';
import { ButtonComponent } from '../Button/ButtonComponent';
import s from './InfoModalComponent.module.scss';
import { Modal } from '@consta/uikit/Modal';
import { useTranslation } from 'react-i18next';
export const InfoModalComponent = memo(({ isModalOpen, changeModalIsOpen, text, isSaveInfo = false, handleFileUpload, error = false, missingColumns, title, cancelButtonName, successButtonName, testId, loading, }) => {
    const { t } = useTranslation();
    return (_jsxs(Modal, { isOpen: isModalOpen, hasOverlay: true, onEsc: changeModalIsOpen, "data-testid": testId, children: [loading && (_jsx("div", { className: s['overlay'], children: _jsx("div", { className: s['loader-wrap'], children: _jsx(LoaderComponent, {}) }) })), _jsxs("div", { className: `${s['wrap']} ${error && s['wrap-error']}`, children: [_jsxs("div", { className: s['modal-info-wrap'], children: [_jsx("div", { className: s['info-wrap-header'], children: title ? title : t('InformationWindowModal') }), _jsx("div", { className: s['text-modal'], children: text }), error && (_jsx("ul", { className: s['list-missing-columns'], children: missingColumns.map((item, index) => (_jsx("li", { children: item }, index))) })), error && (_jsx("div", { className: s['text-modal'], children: t('AddFileColumns') }))] }), _jsxs("div", { className: s['info-buttons'], children: [isSaveInfo && (_jsxs(_Fragment, { children: [_jsx("div", { className: s['info-button-wrap'], children: _jsx(ButtonComponent, { view: 'ghost', clickFunction: changeModalIsOpen, textButton: cancelButtonName ? cancelButtonName : t('Cancel') }) }), _jsx("div", { className: s['info-button-wrap'], children: _jsx(ButtonComponent, { clickFunction: handleFileUpload, textButton: successButtonName ? successButtonName : t('Confirm') }) })] })), !isSaveInfo && (_jsx("div", { className: s['info-button-wrap'], children: _jsx(ButtonComponent, { clickFunction: changeModalIsOpen, textButton: '\u041E\u043A' }) }))] })] })] }));
});
