import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { ScheduleComponent } from '../UI/Schedule/ScheduleComponent';
import { formatNumber, getFormatNumberQuantity } from '../../utils/utils';
import { GaugeComponent } from '../UI/Gauge/GaugeComponent';
import s from './ConsumptionLastYear.module.scss';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
export const ConsumptionLastYear = memo(({ year, errorDate, quantityName, accuracy, dataSchedule, massaFact, massaPredict, ...props }) => {
    const { t } = useTranslation();
    const yearOld = dayjs(year).year();
    const lang = 'RUS' === localStorage.getItem('lang');
    return (_jsx(_Fragment, { children: errorDate ? (_jsx("div", { "data-testid": 'errorDate', children: t('FillDatesCorrectly') })) : (_jsxs("div", { className: s['last-year-wrap'], "data-testid": 'last-year-wrap', children: [_jsx("div", { className: s['last-year-info-wrap'], children: _jsxs("div", { className: s['last-year-info'], children: [_jsxs("div", { className: s['last-year-consumption'], children: [_jsx("div", { className: s['year-consumption-title'], children: t('Actual') }), _jsxs("div", { className: s['year-consumption-wrap'], children: [_jsx("span", { children: formatNumber(massaFact) }), _jsx("span", { children: getFormatNumberQuantity(massaFact, true, lang) +
                                                    quantityName })] })] }), _jsxs("div", { className: s['last-year-consumption'], children: [_jsx("div", { className: s['year-consumption-title'], children: t('Forecast') }), _jsxs("div", { className: s['year-consumption-wrap'], children: [_jsx("span", { children: formatNumber(massaPredict) }), _jsx("span", { children: getFormatNumberQuantity(massaPredict, true, lang) +
                                                    quantityName })] })] }), _jsx("div", { className: s['gauge-component-wrap'], children: _jsx(GaugeComponent, { percent: accuracy, isText: true }) })] }) }), _jsx("div", { className: s['schedule-body'], children: _jsx(ScheduleComponent, { switchFilterYear: { [yearOld]: true }, data: dataSchedule, axisYName: quantityName, oldYear: true, isCumulative: true, ...props }) })] })) }));
});
