import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import s from './BusinessMetricItem.module.scss';
import { MetricDifference } from '../MetricDifference/MetricDifference';
import { formatNumber, getFormatNumberQuantity } from '../../utils/utils';
import { GaugeComponent } from '../../components/UI/Gauge/GaugeComponent';
import { Locale } from '../../constants/enums/locale.enum';
export const BusinessMetricItem = ({ data }) => {
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    return (_jsxs("div", { className: s['businessMetricItem'], children: [_jsxs("div", { className: `${s['item-info']} ${data.isAccuracy ? s['item-info-accuracy'] : ''}`, children: [_jsx("h6", { className: s['label'], children: data.label_main }), data.isAccuracy ? (_jsx(GaugeComponent, { percent: data.value_main })) : (_jsxs("div", { className: s['value-wrap'], children: [_jsxs("div", { className: s['value'], children: [data.value_main ? formatNumber(data.value_main) : 0, data.isPercent && '%'] }), data.unit && !data.isPercent && (_jsxs("span", { className: s['unit'], children: [getFormatNumberQuantity(data.value_main, true, isLangRu), data.unit] }))] }))] }), data.label_extra ? (_jsx(MetricDifference, { valueExtra: data.value_extra, labelExtra: data.label_extra })) : null] }));
};
