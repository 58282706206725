import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import './index.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { Locale } from '../../constants/enums/locale.enum';
dayjs.locale('ru');
const dayInMont = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const MalignDecisionsSchedule = memo(({ switchFilterYear, decisionPeriodsList }) => {
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const switchYearLength = Object.keys(switchFilterYear).filter((year) => switchFilterYear[year]).length;
    const widthCell = switchYearLength === 1 ? 7.44 : switchYearLength === 2 ? 3.72 : 2.5;
    /**
     * Генерирует массив месяцев на основе активных лет из объекта years.
     * @function
     * @param {Object} years - Объект с активными годами (предположительно с булевыми значениями).
     * @returns {string[]} - Массив строк, содержащий даты начала каждого месяца для активных лет.
     */
    const generateMonthsArray = (years) => {
        const months = [];
        Object.entries(years).forEach(([year, isSelected]) => {
            if (isSelected) {
                for (let month = 0; month < 12; month++) {
                    const date = new Date(`${year}-${month + 1}-01`);
                    months.push(`${date}`);
                }
            }
        });
        return months;
    };
    const selectedMonths = generateMonthsArray(switchFilterYear);
    /**
     * Массив уникальных годов на основе фильтра switchFilterYear.
     * @type {string[]}
     */
    const uniqueYears = Object.keys(switchFilterYear).filter((year) => switchFilterYear[year]);
    return (_jsx("div", { className: 'wrap-timeline-grid', "data-testid": 'wrap-timeline-grid', children: _jsxs("div", { className: 'timeline-grid-body', children: [_jsx("div", { children: decisionPeriodsList.map((item, indexData) => (_jsxs("div", { className: `timeline-grid timeline-grid-columns ${indexData % 2 === 0 ? 'odd' : ''}`, style: {
                            gridTemplateColumns: `10.4% repeat(36, ${widthCell}%)`,
                        }, children: [_jsx("div", { className: 'grid-item grid-sticky-column', children: _jsx("div", { className: 'timeline-first-column', children: _jsx("div", { children: item.decision }) }) }), selectedMonths.map((_month, index) => {
                                /**
                                 * График ганта, высчитывание заполненности месяцев по полученным данным.
                                 */
                                const isBlock = item?.start_date?.length;
                                const yearStart = String(dayjs(item.start_date?.[index]).year());
                                const yearEnd = String(dayjs(item.end_date?.[index]).year());
                                const filterYear = Object.keys(switchFilterYear).filter((year) => switchFilterYear[year]);
                                const yearValid = !filterYear.includes(yearStart) &&
                                    !filterYear.includes(yearEnd);
                                if (!isBlock || !item.start_date?.[index] || yearValid) {
                                    return (_jsx("div", { className: 'grid-item' }, index + item.line + indexData));
                                }
                                const isOneNewYear = filterYear.length === 2 && yearStart === '2024';
                                let start = dayjs(item.start_date?.[index]).month() +
                                    (isOneNewYear ? 12 : 0);
                                const startDay = (Number(dayjs(item.start_date?.[index], 'MM.DD.YYYY').format('DD')) *
                                    100) /
                                    dayInMont[dayjs(item.start_date?.[index]).month()] /
                                    100;
                                const endDay = (Number(dayjs(item.end_date?.[index], 'MM.DD.YYYY').format('DD')) *
                                    100) /
                                    dayInMont[dayjs(item.end_date?.[index]).month()] /
                                    100;
                                let monthsDiff = dayjs(item.end_date[index]).diff(dayjs(item.start_date[index]), 'month') + endDay;
                                if (filterYear[0] === '2024' && yearStart === '2023') {
                                    start = 0;
                                    monthsDiff =
                                        dayjs(item.end_date[index]).diff(dayjs('01.01.2024'), 'month') + endDay;
                                }
                                const widthCellBlock = switchYearLength === 1
                                    ? 7.43
                                    : switchYearLength === 2
                                        ? 3.72
                                        : 2.5;
                                const left = 10.4 +
                                    (start +
                                        (Number(dayjs(item.start_date?.[index], 'MM.DD.YYYY').format('DD')) === 1
                                            ? 0
                                            : startDay)) *
                                        widthCellBlock;
                                const width = widthCellBlock * monthsDiff;
                                const top = indexData * 55 + 17;
                                const background = indexData === 2 || indexData === 6 || indexData === 7
                                    ? 'rgba(43, 191, 238, 1)'
                                    : indexData === 5
                                        ? 'rgba(241, 185, 75, 1)'
                                        : 'rgba(0, 120, 210, 1)';
                                return (_jsx("div", { className: 'grid-item', children: _jsx("div", { className: 'timeline-line', style: {
                                            width: `${width}%`,
                                            left: `${left}%`,
                                            top: `${top}px`,
                                            background: background,
                                        } }) }, index + item.line + indexData));
                            })] }, item.line))) }), _jsxs("div", { className: 'timeline-grid-header timeline-grid-columns-prob', style: {
                        gridTemplateColumns: `10.4% repeat(36, ${widthCell}%)`,
                        left: switchYearLength === 1
                            ? '-3.7%'
                            : switchYearLength === 2
                                ? '-2%'
                                : '-1.2%',
                    }, children: [_jsx("div", { className: 'grid-item-bottom grid-sticky-column grid-sticky-column-title grid-item-border' }), selectedMonths.map((item, index) => (_jsx("div", { className: 'grid-item-bottom grid-item-border', children: dayjs(item)
                                .locale(isLangRu ? 'ru' : 'en')
                                .format('MMM') }, index)))] }), _jsx("div", { className: 'year-label', children: uniqueYears.map((year) => (_jsx("div", { className: 'year', children: year }, year))) })] }) }));
});
