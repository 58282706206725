import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import s from './MetricDifference.module.scss';
import { ReactComponent as ArrowSvg } from '../../assets/resource/metric-arrow.svg';
export const MetricDifference = ({ valueExtra, labelExtra, }) => {
    const isPositive = valueExtra > 0;
    const isZero = valueExtra === 0;
    return (_jsxs("div", { className: s['metric-difference'], children: [_jsxs("div", { className: `${s['difference']} ${isPositive
                    ? s['difference-positive']
                    : isZero
                        ? ''
                        : s['difference-negative']}`, children: [_jsx(ArrowSvg, {}), isPositive && '+', valueExtra, "%"] }), _jsx("span", { children: "/" }), _jsx("div", { children: labelExtra })] }));
};
