import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { useDeleteDocumentsIdMutation, useGetDocTypesQuery, useGetDocumentsQuery, usePutDocumentsIdMutation, } from '../../api/registryDocumentsApi';
import { InfoModalComponent } from '../../components/UI/InfoModal/InfoModalComponent';
import { DocumentInfo } from '../../components/DocumentInfo/DocumentInfo';
import { FileAddModal } from '../../components/FileAddModal/FileAddModal';
import { LoaderComponent } from '../../components/UI/Loader/LoaderComponent';
import AddDoc from '../../assets/resource/addDoc.svg';
import { useAppSelector } from '../../store/hook';
import s from './RegisterDocuments.module.scss';
import { Pagination } from '@consta/uikit/Pagination';
import { Checkbox } from '@consta/uikit/Checkbox';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../constants/enums/locale.enum';
export const RegisterDocuments = React.memo(() => {
    const { t } = useTranslation();
    const { filterValueDocument } = useAppSelector((state) => state.filter);
    const [listDocumentType, setListDocumentType] = useState([]);
    const [listDocuments, setListDocuments] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pagesDocument, setPagesDocument] = useState(1);
    const [checkAll, setCheckAll] = useState(false);
    const [maxPage, setMaxPage] = useState(0);
    const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
    const [textInfoModal, setTextInfoModal] = useState('');
    const [loading, setLoading] = useState(false);
    const lang = localStorage.getItem('lang') || Locale.RUS;
    const { data: listDocumentsRequest, refetch: refetchDocuments, isFetching: loadingListDocuments, } = useGetDocumentsQuery({
        filter: filterValueDocument,
        page: pagesDocument,
        lang,
    });
    const { data: listTypeDoc, isFetching: loadingListTypeDoc } = useGetDocTypesQuery(lang);
    const [putDocumentAccount, { isLoading: loadingPutDocument }] = usePutDocumentsIdMutation();
    const [deleteDocument, { isLoading: loadingDeleteDocument }] = useDeleteDocumentsIdMutation();
    /**
     * Эффект, устанавливающий значение loading при запросе на сервер.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        const isLoading = loadingListDocuments ||
            loadingListTypeDoc ||
            loadingPutDocument ||
            loadingDeleteDocument;
        setLoading(isLoading ? true : false);
    }, [
        loadingListDocuments,
        loadingListTypeDoc,
        loadingPutDocument,
        loadingDeleteDocument,
    ]);
    const { permissions } = useAppSelector((state) => state.user);
    const editPermission = permissions.includes('api.add_document');
    /**
     * Эффект, устанавливающий значение pagesDocument в 1 при изменении фильтров filterValueDocument.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        setLoading(true);
        setPagesDocument(1);
    }, [filterValueDocument]);
    /**
     * Эффект, вызывающий функцию refetchDocuments при изменении определенных зависимостей.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        setLoading(true);
        refetchDocuments();
    }, [filterValueDocument, refetchDocuments, pagesDocument, listTypeDoc]);
    /**
     * Callback-функция для изменения свойств элементов массива arr.
     * @function
     * @param {IDocumentsList[]} arr - Массив объектов типа IDocumentsList, который требуется изменить.
     * @returns {IDocumentsList[]} - Массив объектов типа IDocumentsList с измененными свойствами created_date и doc_type.
     */
    const changeArrListDoc = useCallback((arr) => {
        return arr.map((item) => ({
            ...item,
            created_date: dayjs(item.created_date).format('DD.MM.YYYY'),
            doc_type: listTypeDoc[item.doc_type],
        }));
    }, [listTypeDoc]);
    /**
     * Эффект, выполняющий обновление списка документов и связанных свойств.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (listDocumentsRequest && listTypeDoc) {
            setListDocuments(changeArrListDoc(listDocumentsRequest.docs));
            setMaxPage(listDocumentsRequest.total_num_pages);
            setCheckAll(listDocumentsRequest.is_all_active);
        }
        setLoading(false);
    }, [listDocumentsRequest, listTypeDoc, changeArrListDoc]);
    /**
     * Эффект, выполняющий обновление списка типов документов на основе объекта listTypeDoc.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        const newListDocType = [];
        if (listTypeDoc) {
            Object.keys(listTypeDoc).forEach((key, index) => {
                const value = listTypeDoc[key];
                newListDocType.push({
                    label: value,
                    request: key,
                    id: (index + 1).toString(),
                });
            });
        }
        setListDocumentType(newListDocType);
    }, [listTypeDoc]);
    /**
     * Callback-функция для установки состояния "выбрано" для всех документов.
     * @function
     * @returns {void}
     */
    const allCheckedDocuments = useCallback(() => {
        const body = { set_active: String(!checkAll), lang };
        putDocumentAccount({ body });
    }, [checkAll, putDocumentAccount, lang]);
    /**
     * Функция меняет состояние открытия модального окна.
     * @function
     * @returns {void}
     */
    const changeModalIsOpenFile = useCallback(() => {
        setIsModalOpen((prevState) => !prevState);
    }, []);
    /**
     * Функция меняет состояние открытия модального окна.
     * @function
     * @returns {void}
     */
    const changeModalIsOpenInfo = useCallback(() => {
        setIsOpenModalInfo((prevState) => !prevState);
    }, []);
    /**
     * Callback-функция для проверки и обновления текущей страницы в списке документов после удаления элементов.
     * @function
     * @returns {void}
     */
    const checkDeleteList = useCallback(() => {
        if (listDocuments.length === 1 && pagesDocument !== 1) {
            setPagesDocument((prevState) => prevState - 1);
        }
    }, [listDocuments, setPagesDocument, pagesDocument]);
    /**
     * Callback-функция для удаления документа по указанному идентификатору (id).
     * @function
     * @param {string} id - Идентификатор документа, который требуется удалить.
     * @returns {void}
     */
    const deleteDocumentId = useCallback((id) => {
        setLoading(true);
        deleteDocument({ id, lang }).then(() => {
            setLoading(false);
            checkDeleteList();
            changeModalIsOpenInfo();
            setTextInfoModal('Удаление файла произошло успешно.');
        });
    }, [deleteDocument, changeModalIsOpenInfo, checkDeleteList, lang]);
    /**
     * Callback-функция для добавления и обновления состояния документа в учетной записи.
     * @function
     * @param {string} id - Идентификатор документа, который требуется добавить или обновить.
     * @param {boolean} checked - Состояние документа (выбрано или нет).
     * @returns {void}
     */
    const addDocumentIdAccount = useCallback((id, checked) => {
        const body = { doc_list: [id], set_active: !checked, lang };
        putDocumentAccount({ body });
    }, [putDocumentAccount, lang]);
    /**
     * Функция для обработки изменения номера страницы.
     * @function
     * @param {number} pageNumber - Номер страницы, на который нужно переключиться (начиная с 0).
     * @returns {void}
     */
    const handleChange = (pageNumber) => {
        setPagesDocument(pageNumber + 1);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s['documents-wrap'], "data-testid": 'documents-wrap', children: [editPermission && (_jsxs("div", { className: s['actions-wrap-document'], children: [_jsx(Checkbox, { size: 'l', checked: checkAll, onChange: allCheckedDocuments }), _jsxs("div", { onClick: changeModalIsOpenFile, className: s['add-document'], "data-testid": 'add-document', children: [_jsx("img", { src: AddDoc, alt: '\u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0430' }), _jsx("div", { className: s['add-document-tooltip'], children: t('UploadDocumentRegisterDocuments') })] })] })), _jsxs("div", { className: s['info-documents-wrap'], children: [_jsx("div", { className: s['info-documents'], children: listDocuments &&
                                    listDocuments.map((item) => (_jsx(DocumentInfo, { documentInfo: item, deleteDocumentId: deleteDocumentId, addDocumentIdAccount: addDocumentIdAccount }, item.id))) }), _jsx(Pagination, { totalPages: maxPage, currentPage: pagesDocument - 1, onChange: handleChange, hotkeys: {
                                    nextPage: {
                                        label: '',
                                        values: ['Alt', 'ArrowRight'],
                                    },
                                    prevPage: {
                                        label: '',
                                        values: ['Alt', 'ArrowLeft'],
                                    },
                                } })] }), loading && (_jsx("div", { className: s['overlay'], children: _jsx("div", { className: s['loader-wrap'], children: _jsx(LoaderComponent, {}) }) }))] }), isModalOpen && (_jsx(FileAddModal, { isModalOpen: isModalOpen, changeModalIsOpen: changeModalIsOpenFile, listDocumentType: listDocumentType })), isOpenModalInfo && (_jsx(InfoModalComponent, { isModalOpen: isOpenModalInfo, changeModalIsOpen: changeModalIsOpenInfo, text: textInfoModal }))] }));
});
