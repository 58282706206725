import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NavLink } from 'react-router-dom';
import s from './LinkHeaderRoute.module.scss';
import { useTranslation } from 'react-i18next';
const setActive = ({ isActive }) => isActive ? `${s['link']} ${s['active-link']}` : s['link'];
export const LinkHeaderRoute = ({ permissions, }) => {
    const { t } = useTranslation();
    const filteredLinks = () => {
        const filtered = [];
        if (permissions.includes('api.view_forecast')) {
            filtered.push(_jsxs(NavLink, { to: '/consumption', className: setActive, "data-testid": 'consumption', children: [t('ConsumptionForecast'), _jsx("div", {})] }, 'consumption'));
        }
        if (permissions.includes('api.view_recommendation')) {
            filtered.push(_jsxs(NavLink, { to: '/recommendations', className: setActive, "data-testid": 'recommendations', children: [t('Recommendations'), _jsx("div", {})] }, 'recommendations'));
        }
        if (permissions.includes('api.view_businessmetrics')) {
            filtered.push(_jsxs(NavLink, { to: '/business_metrics', className: setActive, "data-testid": 'business_metrics', children: [t('BusinessMetrics'), _jsx("div", {})] }, 'business_metrics'));
        }
        if (permissions.includes('api.view_recommendation')) {
            filtered.push(_jsxs(NavLink, { to: '/historyRecommendation', className: setActive, "data-testid": 'historyRecommendation', children: [t('RecommendationHistory'), _jsx("div", {})] }, 'historyRecommendation'));
        }
        if (permissions.includes('api.view_document')) {
            filtered.push(_jsxs(NavLink, { to: '/registerDocuments', className: setActive, "data-testid": 'registerDocuments', children: [t('RegisterDocuments'), _jsx("div", {})] }, 'register_documents'));
        }
        return filtered;
    };
    return _jsx(_Fragment, { children: filteredLinks() });
};
