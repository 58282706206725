import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState, memo } from 'react';
import { SynthesisRecommendations } from '../SynthesisRecommendations/SynthesisRecommendations';
import { InfoModalComponent } from '../UI/InfoModal/InfoModalComponent';
import { formatNumber, getFormatNumberQuantity } from '../../utils/utils';
import Arrow from '../../assets/resource/arrow-right.svg';
import Truck from '../../assets/resource/trackSuplu.svg';
import { useAppSelector } from '../../store/hook';
import { HOST_API_URL } from '../../constants/common.constants';
import s from './SynthesisRecommendationsList.module.scss';
import { Switch } from '@consta/uikit/Switch';
import dayjs from 'dayjs';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../constants/enums/locale.enum';
export const SynthesisRecommendationsList = memo(({ item, refetchNonGuaranteed, refetchWithoutMoving, refetchForecastsBalances, unit, }) => {
    const { t } = useTranslation();
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const encodedCredentials = JSON.parse(localStorage.getItem('encodedCredentials'));
    const { filterValue, switchValue } = useAppSelector((state) => state.filter);
    const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
    const [isOpenRightRecommendations, setIsOpenRightRecommendations] = useState(false);
    const [stringRequest, setStringRequest] = useState('');
    const [supplyList, setSupplyList] = useState([]);
    const { permissions } = useAppSelector((state) => state.user);
    const editPermission = permissions.includes('api.add_recommendation');
    const lang = localStorage.getItem('lang') || Locale.RUS;
    /**
     * Эффект, обновляющий список периодов поставок на основе объекта item.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (item?.periods?.length) {
            const monthsSupply = Array.from(new Set(item?.periods.map((item) => dayjs(item)
                .locale(isLangRu ? 'ru' : 'en')
                .format('MMMM YYYY'))));
            setSupplyList(monthsSupply);
        }
        else {
            setSupplyList([]);
        }
    }, [item, isLangRu]);
    /**
     * Эффект, обновляющий строку запроса на основе фильтров и переключателей.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        const card_type = item.recommendationName === 'Негарантированные запасы РЦ' ||
            item.recommendationName === 'Not guaranteed stocks DC'
            ? 'recommendations_type=non_guaranteed&'
            : 'recommendations_type=without_moving&';
        const group_mtrs = filterValue.commodityGroup !== ''
            ? `group_mtrs=${filterValue.commodityGroup.id}&`
            : '';
        const analog_groups = filterValue.synthesisGroup !== ''
            ? `analog_groups=${filterValue.synthesisGroup.id}&`
            : '';
        const bus_units = filterValue.choiceDO !== ''
            ? `bus_units=${filterValue.choiceDO.id}&`
            : '';
        setStringRequest(() => `${card_type}${group_mtrs}${analog_groups}${bus_units}`);
    }, [filterValue, switchValue, item]);
    /**
     * Открывает или закрывает рекомендации синтеза.
     * @function
     * @returns {void}
     */
    const changeSwitchRequest = () => {
        axios
            .put(`${HOST_API_URL}/prediction_back/api/v1/recommendations/active_recommendations/?set_active=${!item.is_active}&${stringRequest}&lang=${lang}`, null, {
            headers: {
                Authorization: `Basic ${encodedCredentials}`,
            },
        })
            .then((data) => {
            if (data.status === 200) {
                refetchNonGuaranteed();
                refetchWithoutMoving();
                refetchForecastsBalances();
                if (isOpenModalInfo) {
                    changeModalIsOpenInfo();
                }
            }
        });
    };
    /**
     * Открывает или закрывает рекомендации синтеза.
     * @function
     * @returns {void}
     */
    const openSynthesisRecommendations = () => {
        setIsOpenRightRecommendations((prevState) => !prevState);
    };
    /**
     * Меняет состояние открытия модального окна с информацией.
     * @function
     * @returns {void}
     */
    const changeModalIsOpenInfo = useCallback(() => {
        setIsOpenModalInfo((prevState) => !prevState);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s['synthesis-recommendations-block'], "data-testid": 'synthesis-recommendations-block', children: [_jsxs("div", { className: s['synthesis-recommendations-min'], children: [_jsxs("div", { className: s['recommendations-info'], children: [_jsxs("div", { className: s['recommendations-info-quantity'], children: [_jsx("span", { children: item.recommendations_distributions
                                                    ? formatNumber(item.recommendations_distributions)
                                                    : 0 }), _jsxs("span", { children: [getFormatNumberQuantity(item.recommendations_distributions, true, isLangRu), unit] })] }), _jsx("div", { className: s['recommendations-name-text'], children: _jsx("span", { children: item.recommendationName }) })] }), _jsxs("div", { onClick: openSynthesisRecommendations, className: s['synthesis-recommendations-actions'], children: [_jsx("span", { children: t('Redistribute') }), _jsx("img", { src: Arrow, alt: '\u0421\u0442\u0440\u0435\u043B\u043A\u0430' })] })] }), item.booked_for_bu && (_jsxs("div", { className: s['synthesis-recommendations-max'], children: [editPermission && (_jsx("div", { className: s['recommendations-switch'], children: _jsx(Switch, { size: 's', checked: item.is_active, onChange: changeModalIsOpenInfo, label: t('IncludeInCalculation'), className: s['switch-style-recommendations'] }) })), _jsxs("div", { className: s['recommendations-booking'], children: [_jsxs("span", { children: [t('ReservedForDC'), " "] }), _jsxs("span", { children: [formatNumber(item.booked_for_bu), ' ', getFormatNumberQuantity(item.booked_for_bu, true, isLangRu), unit] })] }), _jsx("div", { className: s['recommendations-supply-wrap'], children: !!supplyList?.length &&
                                    supplyList.map((dataString) => (_jsxs("div", { className: s['recommendations-supply'], children: [_jsxs("div", { className: s['recommendations-supply-info'], children: [_jsx("img", { src: Truck, alt: '' }), _jsx("span", { children: t('Supply') })] }), _jsx("div", { className: s['recommendations-supply-date'], children: _jsx("span", { children: dataString }) })] }, dataString))) })] }))] }), isOpenRightRecommendations && (_jsx(SynthesisRecommendations, { isModalOpen: isOpenRightRecommendations, changeOpenRecommendation: openSynthesisRecommendations, changeSwitchRequest: changeSwitchRequest, item: item, unit: unit })), isOpenModalInfo && (_jsx(InfoModalComponent, { isModalOpen: isOpenModalInfo, changeModalIsOpen: changeModalIsOpenInfo, isSaveInfo: true, handleFileUpload: changeSwitchRequest, text: item.is_active
                    ? t('excludeCalculationRecommendation')
                    : t('enableCalculationRecommendation') }))] }));
});
