import axios from 'axios';
import { HOST_API_URL } from '../../constants/common.constants';
import { downloadFile, parseContentDisposition } from '../../utils/file.util';
import { Locale } from '../../constants/enums/locale.enum';
export const downloadConsumptionForecast = async (params) => {
    const encodedCredentials = localStorage.getItem('encodedCredentials');
    const lang = localStorage.getItem('lang') || Locale.RUS;
    const response = await axios.get(`${HOST_API_URL}/prediction_back/api/v1/forecasts/forecast_to_xlsx/?${params}&lang=${lang}`, {
        headers: {
            Authorization: `Basic ${encodedCredentials}`,
        },
        responseType: 'arraybuffer',
    });
    const filename = decodeURIComponent(parseContentDisposition(response.headers['content-disposition']) || '');
    downloadFile(response.data, filename || 'forecast.xlsx');
};
