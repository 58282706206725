import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { BusinessMetric } from '../../components/BusinessMetric/BusinessMetric';
import { createQueryString, extractYearMonthDay, generateRenderKey, } from '../../utils/utils';
import { useGetBusinessMetricsQuery } from '../../api/businessMetricsApi';
import { useAppSelector } from '../../store/hook';
import { Placeholder } from '../../components/Placeholder/Placeholder';
import s from './BusinessMetrics.module.scss';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../constants/enums/locale.enum';
export const BusinessMetrics = memo(() => {
    const { t } = useTranslation();
    const { filtersBusinessMetrics } = useAppSelector((state) => state.filter);
    const [isFilterAll, setIsFilterAll] = useState(false);
    const [fetchErr, setFetchErr] = useState('');
    const [metricList, setMetricList] = useState([]);
    const [stringRequest, setStringRequest] = useState('');
    const lang = localStorage.getItem('lang') || Locale.RUS;
    const { data: metricListGetQuery, error } = useGetBusinessMetricsQuery({
        filter: stringRequest,
    }, {
        pollingInterval: 3000,
        skip: !isFilterAll,
    });
    useEffect(() => {
        if (Array.isArray(metricListGetQuery?.result)) {
            setMetricList(metricListGetQuery.result);
            setFetchErr('');
        }
        else {
            setMetricList([]);
        }
        if (error) {
            if ('status' in error) {
                setFetchErr(error.status);
            }
        }
    }, [metricListGetQuery, error]);
    /**
     * Эффект, устанавливающий состояние isFilterAll на основе значения, возвращаемого функцией changeIsVisible.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        setIsFilterAll(!!filtersBusinessMetrics.commodityGroup &&
            !!filtersBusinessMetrics.synthesisGroup &&
            !!filtersBusinessMetrics.choiceDO &&
            !!filtersBusinessMetrics.period);
    }, [filtersBusinessMetrics]);
    /**
     * Эффект, выполняющий формирование строки запроса (stringRequest)
     * на основе фильтровых значений (filtersBusinessMetrics), переключателей (switchValue), (valueTabs) и (consumptionLastYearFilter).
     * @function
     * @returns {void}
     */
    useEffect(() => {
        const group_mtrs = filtersBusinessMetrics.commodityGroup !== ''
            ? `group_mtrs=${filtersBusinessMetrics.commodityGroup.id}&`
            : '';
        const analog_groups = filtersBusinessMetrics.synthesisGroup !== ''
            ? createQueryString(filtersBusinessMetrics.synthesisGroup, 'analog_groups')
            : '';
        const bus_units = filtersBusinessMetrics.choiceDO !== ''
            ? createQueryString(filtersBusinessMetrics.choiceDO, 'bus_units')
            : '';
        const { year, month } = extractYearMonthDay(filtersBusinessMetrics.period);
        setStringRequest(`?${group_mtrs}${analog_groups}&${bus_units}&year=${year}&month=${month}&lang=${lang}`);
    }, [filtersBusinessMetrics, lang]);
    const checkContent = () => {
        let classList = s['business-metrics'];
        if (isFilterAll && !metricList.length) {
            return classList + s[' business-metrics-contented'];
        }
        return classList;
    };
    const getPlaceholder = () => {
        if (!isFilterAll) {
            return _jsx(Placeholder, { title: t('NothingFound'), isImage: true });
        }
        if (isFilterAll && !metricList.length) {
            return _jsx(Placeholder, { title: t('NothingFound'), isImage: false });
        }
        if (isFilterAll && fetchErr === 'FETCH_ERROR') {
            return _jsx(Placeholder, { title: t('ServerError'), isImage: false });
        }
        return null;
    };
    return (_jsx("div", { className: checkContent(), "data-testid": 'businessMetrics', children: getPlaceholder() ||
            metricList.map((metric, index) => (_jsx(BusinessMetric, { data: metric }, generateRenderKey(metric.metric_label, index)))) }));
});
