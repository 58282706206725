import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    user: null,
    permissions: [],
    role: '',
};
const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        changeUser(state, action) {
            state.user = action.payload;
        },
        changePermissions(state, action) {
            state.permissions = action.payload;
        },
        changeRole(state, action) {
            state.role = action.payload;
        },
    },
});
export default userSlice.reducer;
export const { changeUser, changePermissions, changeRole } = userSlice.actions;
export const selectUserSession = (state) => state.user;
