// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonTooltipComponent-module__tooltip-text__KJz3O{color:#000}.ButtonTooltipComponent-module__btn-edit-recommendations__tCMG4{color:rgba(0,32,51,.6);margin-right:4px}.ButtonTooltipComponent-module__btn-edit-recommendations__tCMG4:hover{color:#000}.ButtonTooltipComponent-module__revert-btn__Z2FTZ{color:rgba(0,32,51,.6);margin-right:4px}.ButtonTooltipComponent-module__revert-btn__Z2FTZ:hover{transition:.5s all;transform:rotate(-360deg);color:#000}`, "",{"version":3,"sources":["webpack://./src/components/ButtonTooltipComponent/ButtonTooltipComponent.module.scss"],"names":[],"mappings":"AAAA,oDACE,UAAA,CAGF,gEACE,sBAAA,CACA,gBAAA,CAEA,sEACE,UAAA,CAIJ,kDACE,sBAAA,CACA,gBAAA,CAEA,wDACE,kBAAA,CACA,yBAAA,CACA,UAAA","sourcesContent":[".tooltip-text {\r\n  color: black;\r\n}\r\n\r\n.btn-edit-recommendations {\r\n  color: rgba(0, 32, 51, 0.6);\r\n  margin-right: 4px;\r\n\r\n  &:hover {\r\n    color: black;\r\n  }\r\n}\r\n\r\n.revert-btn {\r\n  color: rgba(0, 32, 51, 0.6);\r\n  margin-right: 4px;\r\n\r\n  &:hover {\r\n    transition: 0.5s all;\r\n    transform: rotate(-360deg);\r\n    color: black;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip-text": `ButtonTooltipComponent-module__tooltip-text__KJz3O`,
	"btn-edit-recommendations": `ButtonTooltipComponent-module__btn-edit-recommendations__tCMG4`,
	"revert-btn": `ButtonTooltipComponent-module__revert-btn__Z2FTZ`
};
export default ___CSS_LOADER_EXPORT___;
