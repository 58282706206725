import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_FILTER, INITIAL_FILTERS_DOCUMENT, INITIAL_VALUE_SWITCH, } from '../../constants/initial.constants';
const initialState = {
    filterValue: INITIAL_FILTER,
    filtersConsumption: INITIAL_FILTER,
    filtersBusinessMetrics: INITIAL_FILTER,
    filterValueDocument: INITIAL_FILTERS_DOCUMENT,
    switchValue: INITIAL_VALUE_SWITCH,
};
const filterSlice = createSlice({
    name: 'filters',
    initialState: initialState,
    reducers: {
        changeFilters(state, action) {
            state.filterValue = action.payload;
        },
        changeFiltersConsumption(state, action) {
            state.filtersConsumption = action.payload;
        },
        changeFiltersBusinessMetrics(state, action) {
            state.filtersBusinessMetrics = action.payload;
        },
        changeSwitch(state, action) {
            state.switchValue = action.payload;
        },
        changeFilterValueDocument(state, action) {
            state.filterValueDocument = action.payload;
        },
    },
});
export default filterSlice.reducer;
export const { changeFilters, changeSwitch, changeFilterValueDocument, changeFiltersConsumption, changeFiltersBusinessMetrics, } = filterSlice.actions;
export const selectFilterSession = (state) => state.filter;
