import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HOST_API_URL } from '../constants/common.constants';
export const registryDocuments = createApi({
    reducerPath: 'registryDocumentsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: HOST_API_URL,
        prepareHeaders: (headers) => {
            const encodedCredentials = JSON.parse(localStorage.getItem('encodedCredentials'));
            if (encodedCredentials) {
                headers.set('Authorization', `Basic ${encodedCredentials}`);
            }
            return headers;
        },
    }),
    tagTypes: ['get_documents'],
    endpoints: (builder) => ({
        getDocuments: builder.query({
            query: ({ filter, page, lang }) => ({
                url: `/prediction_back/api/v1/documents/?page=${page}&limit=10&lang=${lang}`,
                params: filter,
            }),
            providesTags: ['get_documents'],
        }),
        getDocTypes: builder.query({
            query: (lang) => `/prediction_back/api/v1/doc_types/?lang=${lang}`,
        }),
        postDocuments: builder.mutation({
            query: ({ formData, body }) => {
                const { exist_id, ...params } = body;
                return {
                    url: '/prediction_back/api/v1/documents/',
                    method: exist_id ? 'PUT' : 'POST',
                    params: exist_id
                        ? {
                            ...params,
                            document_id: exist_id,
                        }
                        : params,
                    body: formData,
                };
            },
            invalidatesTags: ['get_documents'],
        }),
        putDocumentsId: builder.mutation({
            query: ({ body }) => ({
                url: '/prediction_back/api/v1/active_documents/',
                method: 'PUT',
                params: body,
            }),
            invalidatesTags: ['get_documents'],
        }),
        deleteDocumentsId: builder.mutation({
            query: ({ id, lang }) => ({
                url: `/prediction_back/api/v1/documents/?document_id=${id}&lang=${lang}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['get_documents'],
        }),
    }),
});
export const { useGetDocumentsQuery, useDeleteDocumentsIdMutation, usePutDocumentsIdMutation, useGetDocTypesQuery, usePostDocumentsMutation, } = registryDocuments;
