import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { DatePickerComponent } from '../UI/DatePicker/DataPickerComponent';
import { usePutRecommendationsUpdateMutation } from '../../api/recommendationsApi';
import { TextFieldComponent } from '../UI/TextField/TextFieldComponent';
import CancelIcon from '../../assets/resource/cancle-icon.svg';
import { ButtonComponent } from '../UI/Button/ButtonComponent';
import s from './RecommendationsModelingModal.module.scss';
import { Switch } from '@consta/uikit/Switch';
import { Modal } from '@consta/uikit/Modal';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../constants/enums/locale.enum';
dayjs.locale('ru');
export const RecommendationsModelingModal = ({ recommendation, isModalOpen, unit, changeOpenRecommendation, refetchRecommendationsDOList, }) => {
    const { t } = useTranslation();
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const deliveryDate = dayjs(recommendation.delivery_date)
        .locale(isLangRu ? 'ru' : 'rn')
        .format('MM.YYYY');
    const [updateRecommendations] = usePutRecommendationsUpdateMutation();
    const [isErrorDate, setIsErrorDate] = useState(false);
    const [customValues, setCustomValues] = useState({
        isCustomValues: recommendation.use_user_data,
        customMassa: recommendation.user_massa?.toString() || '0',
        customDate: getValidDate(recommendation.user_delivery_date),
    });
    const minDate = dayjs().subtract(0, 'month').toDate();
    const lang = localStorage.getItem('lang') || Locale.RUS;
    /**
     * Предоставляет дату в ответ на строку с датой.
     * @function
     * @param {string | null} dateString - Строка с предполагаемой датой.
     * @returns {Date} - Возвращается текущая дата, если исходная строка не является корректной датой или null.
     */
    function getValidDate(dateString) {
        if (dateString === null)
            return undefined;
        const realDate = new Date(dateString);
        if (realDate.toString() !== 'Invalid Date')
            return new Date(dateString);
        return new Date();
    }
    /**
     * Изменяет значение поля ввода в зависимости от переданного значения.
     * @function
     * @param {any} newValue - Новое значение фильтра.
     * @param {string} changeName - Имя фильтра для обновления.
     */
    const changeFilter = (newValue, changeName) => {
        if (changeName === 'customDate')
            setIsErrorDate(false);
        setCustomValues((prevState) => ({
            ...prevState,
            [changeName]: newValue,
        }));
    };
    /**
     * Изменяет значение ошибки для даты на true.
     * @function
     * @returns {void}
     */
    const changeErrorDate = () => {
        setIsErrorDate(true);
    };
    const onSubmit = () => {
        if (isErrorDate)
            return;
        const body = {
            id: recommendation.id,
            new_massa: customValues.customMassa,
            new_delivery_date: dayjs(customValues.customDate).format('YYYY-MM-DD'),
            use_user_data: customValues.isCustomValues,
        };
        updateRecommendations({ body, lang }).then(({ data }) => {
            if (data) {
                refetchRecommendationsDOList();
                changeOpenRecommendation();
            }
        });
    };
    const switchCustomValues = () => {
        setCustomValues((prevState) => ({
            ...prevState,
            isCustomValues: !prevState.isCustomValues,
        }));
    };
    const textStyle = (isBold) => {
        return isBold ? 'text-bold' : 'text';
    };
    return (_jsx(Modal, { isOpen: isModalOpen, hasOverlay: true, onEsc: changeOpenRecommendation, "data-testid": 'rec-modeling-modal', children: _jsxs("article", { className: s['modal-container'], children: [_jsxs("header", { className: s['modal-header'], children: [_jsx("h3", { children: t('RecommendationModeling') }), _jsx("img", { onClick: changeOpenRecommendation, src: CancelIcon, alt: '\u0418\u043A\u043E\u043D\u043A\u0430 \u0437\u0430\u043A\u0440\u044B\u0442\u0438\u044F \u043C\u043E\u0434\u0430\u043B\u044C\u043D\u043E\u0433\u043E \u043E\u043A\u043D\u0430.' })] }), _jsxs("main", { className: s['modal-main'], children: [_jsx("p", { children: t('PleaseEnterNewValuesScenarioOption') }), _jsxs("div", { className: s['settings'], children: [_jsx("h4", { className: s['scenario-title'], children: t('Scenario') }), _jsx("div", { className: s['new-value-switch'], children: _jsx(Switch, { size: 'm', checked: customValues.isCustomValues, onChange: switchCustomValues, label: t('NewValue'), className: s[`${customValues.isCustomValues ? 'switch-active' : 'switch'}`], "data-testid": 'switch-modeling-modal' }) }), _jsx("div", { className: s['rec-value-switch'], children: _jsx(Switch, { size: 'm', checked: !customValues.isCustomValues, onChange: switchCustomValues, label: t('RecommendedValue'), className: s[`${!customValues.isCustomValues ? 'switch-active' : 'switch'}`], "data-testid": 'switch-modeling-modal' }) }), _jsx("h4", { className: s['volume-title'], children: t('Volume') }), _jsxs("div", { className: s['input-text-container'], children: [_jsx(TextFieldComponent, { filter: 'massa', value: customValues.customMassa === '0'
                                                ? ''
                                                : customValues.customMassa, changeFilter: (newValue) => changeFilter(newValue, 'customMassa'), type: 'number', incrementButtons: false, label: '', placeholder: '0', disabled: !customValues.isCustomValues }), _jsx("span", { className: `${s['input-text-label']} ${customValues.isCustomValues && s['input-text-label-active']}`, children: unit })] }), _jsx("p", { className: `${s['massa-label']} ${s[`${textStyle(!customValues.isCustomValues)}`]}`, children: `${recommendation.massa.toString()} ${unit}` }), _jsx("h4", { className: s['date-title'], children: t('DeliveryTime') }), _jsx("div", { className: `${s['input-date-container']} ${customValues.isCustomValues && s['input-active']}`, children: _jsx(DatePickerComponent, { value: customValues.customDate, type: 'month', changeFilter: (newValue) => changeFilter(newValue, 'customDate'), filter: 'customDate', label: '', placeholder: t('MM.YYYY'), disabled: !customValues.isCustomValues, errorDate: isErrorDate, onErrorDate: changeErrorDate, minDate: minDate }) }), _jsx("p", { className: `${s['date-label']} ${s[`${textStyle(!customValues.isCustomValues)}`]}`, children: deliveryDate })] }), _jsxs("div", { className: s['btns-container'], children: [_jsx("div", { className: s['btn-wrapper'], children: _jsx(ButtonComponent, { clickFunction: changeOpenRecommendation, view: 'ghost', textButton: t('Cancel') }) }), _jsx("div", { className: s['btn-wrapper'], children: _jsx(ButtonComponent, { clickFunction: onSubmit, textButton: t('Confirm'), disabled: isErrorDate && customValues.isCustomValues }) })] })] })] }) }));
};
