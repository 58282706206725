import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { HistoryRecommendation } from '../../views/HistoryRecommendation/HistoryRecommendation';
import { ConsumptionForecast } from '../../views/ConsumptionForecast/ConsumptionForecast';
import { RegisterDocuments } from '../../views/RegisterDocuments/RegisterDocuments';
import { BusinessMetrics } from '../../views/BusinessMetrics/BusinessMetrics';
import { Recommendations } from '../../views/Recommendations/Recommendations';
import { Headers } from '../Headers/Headers';
import { Login } from '../Login/Login';
import { useAppSelector } from '../../store/hook';
export const routesArr = [
    {
        path: 'business_metrics',
        component: _jsx(BusinessMetrics, {}),
        permission: 'api.view_businessmetrics',
    },
    {
        path: 'recommendations',
        component: _jsx(Recommendations, {}),
        permission: 'api.view_recommendation',
    },
    {
        path: 'consumption',
        component: _jsx(ConsumptionForecast, {}),
        permission: 'api.view_forecast',
    },
    {
        path: 'registerDocuments',
        component: _jsx(RegisterDocuments, {}),
        permission: 'api.view_document',
    },
    {
        path: 'historyRecommendation',
        component: _jsx(HistoryRecommendation, {}),
        permission: 'api.view_recommendation',
    },
];
export const LinkRoute = () => {
    const navigate = useNavigate();
    const { permissions } = useAppSelector((state) => state.user);
    /**
     * Загружает разрешения пользователя из локального хранилища и обновляет состояние компонента.
     * Если пользователь или разрешения отсутствуют в локальном хранилище, перенаправляет на страницу входа.
     * @returns {void}
     */
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || !permissions) {
            navigate('/login');
        }
    }, [navigate, permissions]);
    /**
     * Проверяет наличие указанного разрешения в списке разрешений пользователя.
     * @function
     * @param {string} permission - Разрешение, которое нужно проверить.
     * @returns {boolean} - Возвращает true, если разрешение найдено, иначе false.
     */
    const hasPermission = useCallback((permission) => {
        return permissions ? permissions.includes(permission) : [];
    }, [permissions]);
    /**
     * Проверяет, есть ли у пользователя хотя бы одно из указанных разрешений.
     * @function
     * @returns {boolean} - Возвращает true, если есть хотя бы одно разрешение, иначе false.
     */
    const hasAnyPermission = () => {
        return routesArr.some((item) => hasPermission(item.permission));
    };
    return (_jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(Headers, {}), children: hasAnyPermission() ? (routesArr.map((item) => hasPermission(item.permission) && (_jsx(Route, { path: item.path, element: item.component }, item.path)))) : (_jsx(Route, { path: '*', element: _jsx(_Fragment, {}) })) }), _jsx(Route, { path: '/login', element: _jsx(Login, {}) })] }));
};
