// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecommendationsSchedule-module__legend-container__q8lB6{display:flex;flex-wrap:wrap;gap:16px}.RecommendationsSchedule-module__text-left-schedule-forecast__ppCRN{width:36px;position:absolute;top:42%;transform:rotate(-90deg);color:#97b2c4;font-size:12px}.RecommendationsSchedule-module__schedule-container__jDx19{width:100%;display:flex;flex-direction:column;align-items:start;overflow-y:auto;gap:20px;position:relative}.RecommendationsSchedule-module__switch-wrap-schedule__QZLgr{padding:2px 0 0 2px;display:flex;gap:20px}.RecommendationsSchedule-module__year-label__GCTzP{display:flex;justify-content:space-around;margin-top:10px}.RecommendationsSchedule-module__year__dGR71{font-size:14px;color:#97b2c4}`, "",{"version":3,"sources":["webpack://./src/components/RecommendationsSchedule/RecommendationsSchedule.module.scss"],"names":[],"mappings":"AAAA,yDACE,YAAA,CACA,cAAA,CACA,QAAA,CAGF,oEACE,UAAA,CACA,iBAAA,CACA,OAAA,CACA,wBAAA,CACA,aAAA,CACA,cAAA,CAGF,2DACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,eAAA,CACA,QAAA,CACA,iBAAA,CAGF,6DACE,mBAAA,CACA,YAAA,CACA,QAAA,CAGF,mDACE,YAAA,CACA,4BAAA,CACA,eAAA,CAGF,6CACE,cAAA,CACA,aAAA","sourcesContent":[".legend-container {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  gap: 16px;\r\n}\r\n\r\n.text-left-schedule-forecast {\r\n  width: 36px;\r\n  position: absolute;\r\n  top: 42%;\r\n  transform: rotate(-90deg);\r\n  color: #97B2C4;\r\n  font-size: 12px;\r\n}\r\n\r\n.schedule-container {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: start;\r\n  overflow-y: auto;\r\n  gap: 20px;\r\n  position: relative;\r\n}\r\n\r\n.switch-wrap-schedule {\r\n  padding: 2px 0 0 2px;\r\n  display: flex;\r\n  gap: 20px;\r\n}\r\n\r\n.year-label {\r\n  display: flex;\r\n  justify-content: space-around;\r\n  margin-top: 10px;\r\n}\r\n\r\n.year {\r\n  font-size: 14px;\r\n  color: #97B2C4;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legend-container": `RecommendationsSchedule-module__legend-container__q8lB6`,
	"text-left-schedule-forecast": `RecommendationsSchedule-module__text-left-schedule-forecast__ppCRN`,
	"schedule-container": `RecommendationsSchedule-module__schedule-container__jDx19`,
	"switch-wrap-schedule": `RecommendationsSchedule-module__switch-wrap-schedule__QZLgr`,
	"year-label": `RecommendationsSchedule-module__year-label__GCTzP`,
	"year": `RecommendationsSchedule-module__year__dGR71`
};
export default ___CSS_LOADER_EXPORT___;
