export const HISTORY_TABS_RU = [
    {
        label: 'Дозаказ',
        request: 'order',
    },
];
export const HISTORY_TABS_EN = [
    {
        label: 'Additional order',
        request: 'order',
    },
];
export const ORDER_HIST_COLUMNS_RU = [
    {
        title: 'Дата создания',
        accessor: 'created_date',
    },
    {
        title: 'Дата принятия',
        accessor: 'dt_start',
    },
    {
        title: 'Пользователь',
        accessor: 'user',
    },
    {
        title: 'СГ',
        accessor: 'Alabel',
    },
    {
        title: 'ДО',
        accessor: 'BUname',
    },
    {
        title: 'ТГ',
        accessor: 'GMlabel',
    },
    {
        title: 'Объем, БЕИ',
        accessor: 'bmu',
    },
    {
        title: 'Срок поставки',
        accessor: 'delivery_date',
    },
    {
        title: 'Дата окончания',
        accessor: 'dt_end',
    },
];
export const ORDER_HIST_COLUMNS_EN = [
    {
        title: 'Creatу date',
        accessor: 'created_date',
    },
    {
        title: 'Start date',
        accessor: 'dt_start',
    },
    {
        title: 'User',
        accessor: 'user',
    },
    {
        title: 'AG',
        accessor: 'Alabel',
    },
    {
        title: 'BU',
        accessor: 'BUname',
    },
    {
        title: 'PG',
        accessor: 'GMlabel',
    },
    {
        title: 'Volume, BUOM',
        accessor: 'bmu',
    },
    {
        title: 'Delivery time',
        accessor: 'delivery_date',
    },
    {
        title: 'End date',
        accessor: 'dt_end',
    },
];
