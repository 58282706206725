// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentInfo-module__document-wrap__siJEg{width:100%;height:44px;display:flex;justify-content:space-between;align-items:center;gap:16px;background-color:#ecf1f4;padding:0 18px 0 10px;border-radius:6px}.DocumentInfo-module__document-text__PvjOx{width:180px;color:#74858f;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.DocumentInfo-module__document-text__auto-width__Bmb1F{width:auto}.DocumentInfo-module__delete-doc__Scadh{width:22px;height:22px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/DocumentInfo/DocumentInfo.module.scss"],"names":[],"mappings":"AAAA,2CACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,QAAA,CACA,wBAAA,CACA,qBAAA,CACA,iBAAA,CAGF,2CACE,WAAA,CACA,aAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CAEA,uDACE,UAAA,CAIJ,wCACE,UAAA,CACA,WAAA,CACA,cAAA","sourcesContent":[".document-wrap {\r\n  width: 100%;\r\n  height: 44px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  gap: 16px;\r\n  background-color: rgb(236, 241, 244);\r\n  padding: 0 18px 0 10px;\r\n  border-radius: 6px;\r\n}\r\n\r\n.document-text {\r\n  width: 180px;\r\n  color: #74858F;\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n\r\n  &__auto-width {\r\n    width: auto;\r\n  }\r\n}\r\n\r\n.delete-doc {\r\n  width: 22px;\r\n  height: 22px;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"document-wrap": `DocumentInfo-module__document-wrap__siJEg`,
	"document-text": `DocumentInfo-module__document-text__PvjOx`,
	"document-text__auto-width": `DocumentInfo-module__document-text__auto-width__Bmb1F`,
	"delete-doc": `DocumentInfo-module__delete-doc__Scadh`
};
export default ___CSS_LOADER_EXPORT___;
