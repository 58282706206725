import { jsx as _jsx } from "react/jsx-runtime";
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './styles/index.scss';
import { store } from './store';
import { Provider } from 'react-redux';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(_jsx(BrowserRouter, { children: _jsx(Provider, { store: store, children: _jsx(I18nextProvider, { i18n: i18n, children: _jsx(App, {}) }) }) }));
