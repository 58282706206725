import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HOST_API_URL } from '../constants/common.constants';
export const consumptionApi = createApi({
    reducerPath: 'consumptionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: HOST_API_URL,
        prepareHeaders: (headers) => {
            const encodedCredentials = JSON.parse(localStorage.getItem('encodedCredentials'));
            if (encodedCredentials) {
                headers.set('Authorization', `Basic ${encodedCredentials}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getGroupsMaterial: builder.query({
            query: ({ pageName, lang }) => ({
                url: `/prediction_back/api/v1/forecasts/groups_of_materials/?section=${pageName}&page=1&limit=20&lang=${lang}`,
            }),
        }),
        getAnalogGroups: builder.query({
            query: (filter) => ({
                url: `/prediction_back/api/v1/forecasts/analog_groups/?page=1&limit=40`,
                params: filter,
            }),
        }),
        getBusUnitsDoc: builder.query({
            query: ({ pageName, lang }) => ({
                url: `/prediction_back/api/v1/forecasts/bus_units/?section=${pageName}&page=1&limit=30&lang=${lang}`,
            }),
        }),
        getBusUnitsTable: builder.query({
            query: (lang) => ({
                url: `/prediction_back/api/v1/bus_units_from_table/?page=1&limit=40&lang=${lang}`,
            }),
        }),
        getScheduleConsumption: builder.query({
            query: (filter) => ({
                url: `/prediction_back/api/v1/forecasts/forecasts_chart_info/`,
                params: filter,
            }),
        }),
        getForecastCurrentYear: builder.query({
            query: (filter) => ({
                url: `/prediction_back/api/v1/forecasts/forecast_current_year/`,
                params: filter,
            }),
        }),
        getForecastLastYear: builder.query({
            query: (filter) => ({
                url: `/prediction_back/api/v1/forecasts/get_forecast_last_years`,
                params: filter,
            }),
        }),
    }),
});
export const { useGetGroupsMaterialQuery, useGetAnalogGroupsQuery, useGetBusUnitsDocQuery, useGetBusUnitsTableQuery, useGetScheduleConsumptionQuery, useGetForecastCurrentYearQuery, useGetForecastLastYearQuery, } = consumptionApi;
