// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BusinessMetrics-module__business-metrics__g3VlE{background-color:#fff;display:flex;flex-direction:column;gap:24px}.BusinessMetrics-module__business-metrics-contented__SeUnn{padding:36px 24px}`, "",{"version":3,"sources":["webpack://./src/views/BusinessMetrics/BusinessMetrics.module.scss"],"names":[],"mappings":"AAAA,iDACE,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,2DACE,iBAAA","sourcesContent":[".business-metrics {\r\n  background-color: rgba(255, 255, 255);\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 24px;\r\n}\r\n\r\n.business-metrics-contented {\r\n  padding: 36px 24px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"business-metrics": `BusinessMetrics-module__business-metrics__g3VlE`,
	"business-metrics-contented": `BusinessMetrics-module__business-metrics-contented__SeUnn`
};
export default ___CSS_LOADER_EXPORT___;
