/**
 * Функция для создания строки запроса на основе объекта переключателей (yearsObject) и имени параметра (name).
 * @function
 * @param {IValueSwitch} yearsObject - Объект переключателей, содержащий значения переключателей.
 * @param {string} name - Имя параметра, которое будет использоваться в строке запроса.
 * @returns {string} - Строка запроса, сформированная на основе переданных данных.
 */
export const createQueryStringFromObject = (yearsObject, name) => {
    const queryString = Object.entries(yearsObject)
        .filter(([_year, value]) => value === true)
        .map(([year, _value]) => `${name}=${year}`)
        .join('&');
    return queryString;
};
/**
 * Функция для создания строки запроса на основе массива ISelectValue и имени параметра (name).
 * @function
 * @param {ISelectValue[]} arr - Массив объектов типа ISelectValue.
 * @param {string} name - Имя параметра, которое будет использоваться в строке запроса.
 * @returns {string} - Строка запроса, сформированная на основе переданных данных.
 */
export const createQueryString = (arr, name) => {
    let list = arr;
    if (list[0]?.label === 'Все' || list[0]?.label === 'All') {
        list = list.slice(1);
    }
    const queryString = list.map((item) => `${name}=${item.id}`).join('&');
    return queryString;
};
/**
 * Функция для форматирования числа с разделением разрядов пробелами.
 * @function
 * @param {number} number - Число, которое нужно отформатировать.
 * @returns {string} - Отформатированное число в виде строки.
 */
export const formatNumber = (number) => {
    // Обработка нулевого значения
    if (number === 0) {
        return '0';
    }
    // Проверка, является ли число отрицательным
    const isNegative = number < 0;
    const absNumber = Math.abs(number);
    if (absNumber >= 1000000) {
        return ((isNegative ? '-' : '') +
            (absNumber / 1000000).toFixed(1).replace('.', ',').replace(/,0$/, ''));
    }
    if (absNumber >= 1000) {
        return ((isNegative ? '-' : '') +
            (absNumber / 1000).toFixed(1).replace('.', ',').replace(/,0$/, ''));
    }
    if (absNumber >= 100) {
        return ((isNegative ? '-' : '') +
            absNumber.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
    }
    if (absNumber < 10 && absNumber >= 0 && absNumber % 1 === 0) {
        return (isNegative ? '-' : '') + absNumber;
    }
    return ((isNegative ? '-' : '') +
        absNumber.toFixed(absNumber >= 10 ? 0 : 1).replace('.', ','));
};
/**
 * Функция для получения разрядности числа.
 * @function
 * @param {number} number - Число, которое нужно отформатировать.
 * @returns {string} - разрядность.
 */
export const getFormatNumberQuantity = (number, withSpace = true, isLangRu) => {
    let res = '';
    const absNumber = Math.abs(number);
    if (absNumber >= 1000) {
        res = isLangRu ? 'тыс.' : 'th';
    }
    if (absNumber >= 1000000) {
        res = isLangRu ? 'млн' : 'm';
    }
    if (res && withSpace) {
        return res + ' ';
    }
    return res;
};
/**
 * Функция для получения года, месяца и числа из строки вида "ГГГГ-ММ-ДД ЧЧ:мм:сс".
 * @function
 * @param {string} dateTimeString - строка формата "ГГГГ-ММ-ДД ЧЧ:мм:сс".
 * @returns {Object} - Объект с извлеченными компонентами даты и времени.
 * @returns {string} returns.year - Год.
 * @returns {string} returns.month - Месяц.
 * @returns {string} returns.day - День.
 */
export const extractYearMonthDay = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return {
        year: date.getFullYear().toString(),
        month: (date.getMonth() + 1).toString(),
        day: date.getDate().toString(),
    };
};
/**
 * Функция, генерирующая ключи для рендера React.
 * @function
 * @param {string} inputString - некоторая строка.
 * @param {number} index - индекс элемента, для которого генерируем ключ.
 * @returns {string} - псевдоуникальная строка-ключ.
 */
export const generateRenderKey = (inputString, index) => {
    inputString = inputString + '-react-key' + index;
    const shuffled = inputString.split('');
    shuffled.sort(() => Math.random() - 0.5);
    return shuffled.join('').replace(/\s+/g, '') + index;
};
