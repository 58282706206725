import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import s from './Forecast.module.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { formatNumber, getFormatNumberQuantity } from '../../utils/utils';
import { Locale } from '../../constants/enums/locale.enum';
export const Forecast = memo(({ forecast, quantityName, quantityMonth, label = 'месяца' }) => {
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const monthStart = dayjs(forecast.start_date)
        .locale(isLangRu ? 'ru' : 'en')
        .format('MMM');
    const monthEnd = dayjs(forecast.end_date)
        .locale(isLangRu ? 'ru' : 'en')
        .format('MMM');
    if (!forecast?.massa) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: s['forecast-wrap'], "data-testid": 'forecast', children: [_jsxs("div", { className: s['forecast-month'], children: [_jsxs("span", { children: [isLangRu && 'Прогноз на', " ", quantityMonth, " ", label, ' ', !isLangRu && 'forecast'] }), _jsxs("span", { children: [monthStart, " \u2013 ", monthEnd] })] }), _jsxs("div", { className: s['forecast-quantity'], children: [_jsx("span", { children: formatNumber(forecast.massa) }), _jsx("span", { children: getFormatNumberQuantity(forecast.massa, true, isLangRu) +
                            quantityName })] })] }));
});
