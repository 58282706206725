import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { getFormatNumberQuantity, formatNumber } from '../../utils/utils';
import { TYPE_ORDER_RU, TYPE_ORDER_EN, } from './RecommendationsSchedule.constants';
dayjs.locale('ru');
/**
 * Функция для фильтрации данных по году на основе значений из меню.
 * @function
 * @param {  IRecommendationsScheduleColumn[]} data - данные для фильтрации.
 * @param {string[]} years - значения годов в меню.
 * @returns {TProcessedRecommendationsScheduleItem[]} - отфильтрованные значения.
 */
export const filterRecommendationsSchedulesByYears = (data, years) => {
    if (years.length > 0) {
        return data.filter((item) => {
            const year = dayjs(item.month).format('YYYY');
            return years.includes(year);
        });
    }
    else {
        return [];
    }
};
/**
 * Функция для возврата цвета на определенную колонку.
 * @function
 * @param {Datum} datum - данные с графика.
 * @returns {string} - цвет.
 */
export const getColorColumn = (datum) => {
    switch (datum['type']) {
        case 'Потребление':
            return 'rgba(214, 48, 167, 1)';
        case 'Demand':
            return 'rgba(214, 48, 167, 1)';
        case 'Остатки на начало периода РЦ':
            return 'rgba(241, 185, 75, 1)';
        case 'Inventory level at the beginning of the period DC':
            return 'rgba(241, 185, 75, 1)';
        case 'Заказы ДО':
            return 'rgba(0, 120, 210, 1)';
        case 'Orders BU':
            return 'rgba(0, 120, 210, 1)';
        case 'Заказы РЦ':
            return 'rgba(43, 191, 238, 1)';
        case 'Orders DC':
            return 'rgba(43, 191, 238, 1)';
        case 'Рекомендации':
            return 'rgba(75, 194, 130, 1)';
        case 'Recommendations':
            return 'rgba(75, 194, 130, 1)';
        case 'Остатки на начало периода ДО':
            return 'rgba(134, 121, 52, 1)';
        case 'Inventory level at the beginning of the period BU':
            return 'rgba(134, 121, 52, 1)';
        case 'Перераспределения':
            return 'rgba(244, 115, 87, 1)';
        case 'Redistributions':
            return 'rgba(244, 115, 87, 1)';
        default:
            return '';
    }
};
/**
 * Функция для возврата цвета на линии.
 * @function
 * @param {Datum} datum - данные с графика.
 * @returns {string} - цвет.
 */
export const getColorLine = (datum) => {
    switch (datum['type']) {
        case 'forecast':
            return 'rgb(143 36 13)';
        case 'fact':
            return 'rgb(2, 85, 147)';
        default:
            return '';
    }
};
/**
 * Функция для форматирования данных тултипа графика рекомендаций и перераспределений.
 * @param {Datum} datum - данные для форматирования.
 * @returns {TooltipAttrResult} - отформатированные данные для тултипа.
 */
export const recommendationsSchedulesFormatter = (datum, isLangRu) => {
    const isLineType = datum['type'] === 'fact' || datum['type'] === 'forecast';
    const numValue = Number(datum['massa']);
    const quantity = getFormatNumberQuantity(numValue, false, isLangRu);
    const formattedValue = quantity
        ? `${formatNumber(numValue)} ${quantity}`
        : formatNumber(numValue);
    return {
        name: !isLineType
            ? datum['type']
            : datum['type'] === 'fact'
                ? isLangRu
                    ? 'Остатки фактические всего на конец периода'
                    : 'Total actual inventory level at the end of the period'
                : isLangRu
                    ? 'Остатки прогнозные всего на конец периода'
                    : 'Total forecast inventory level at the end of the period',
        value: formattedValue,
    };
};
/**
 * Функция для добавления пустых данных в массив колонок в зависимости от выбранных настроек отображения.
 * @param {IShowLines} isShowLine - настройки отображения линий.
 * @param {IRecommendationsScheduleColumn[]} arr - массив колонок для обновления.
 * @param {Record<string, boolean>} selectedYears - выбранные годы для фильтрации.
 * @returns {IRecommendationsScheduleColumn[]} - массив колонок с добавленными пустыми данными.
 */
export const addEmptyDataFilters = (isShowLine, arr, selectedYears, isLangRu) => {
    const selectedYearsArray = Object.entries(selectedYears)
        .filter(([_year, isSelected]) => isSelected)
        .map(([year]) => parseInt(year));
    const arrNew = [];
    const collMonths = selectedYearsArray.length === 1 ? 12 : 24;
    for (let i = 1; i <= collMonths; i++) {
        const year = String(i <= 12 ? selectedYearsArray[0] : selectedYearsArray[1]);
        const monthNumber = i <= 12 ? i : i - 12;
        const month = `${year}-${monthNumber >= 10 ? monthNumber : '0' + monthNumber}`;
        const typeRecommendations = isLangRu ? 'Рекомендации' : 'Recommendations';
        const typeRedistributions = isLangRu
            ? 'Перераспределения'
            : 'Redistributions';
        const nameRecommendations = 'recommendations';
        const nameRedistributions = 'redistributions';
        const matchingRecommendations = arr.find((arrItem) => arrItem.month === month && arrItem.name === nameRecommendations);
        const matchingRedistributions = arr.find((arrItem) => arrItem.month === month && arrItem.name === nameRedistributions);
        if (isShowLine.recommendations) {
            if (matchingRecommendations) {
                arrNew.push({
                    ...matchingRecommendations,
                    type: typeRecommendations,
                });
            }
            else {
                arrNew.push({
                    massa: 0,
                    year,
                    month,
                    type: typeRecommendations,
                    name: nameRecommendations,
                });
            }
        }
        if (isShowLine.redistributions) {
            if (matchingRedistributions) {
                arrNew.push({
                    ...matchingRedistributions,
                    type: typeRedistributions,
                });
            }
            else {
                arrNew.push({
                    massa: 0,
                    year,
                    month,
                    type: typeRedistributions,
                    name: nameRedistributions,
                });
            }
        }
    }
    return arrNew;
};
/**
 * Функция для определения минимального значения оси Y для рекомендаций.
 * @param {IRecommendationsScheduleColumn[]} columnData - данные столбцов.
 * @param {IRecommendationsScheduleLine[]} dataLine - данные линии.
 * @returns {number} - минимальное значение оси Y.
 */
export const minAxisYRecommendation = (columnData, dataLine) => {
    const consumptionItems = [...columnData]
        .filter((item) => item.type === 'Потребление' || item.type === 'Demand')
        .sort((a, b) => a.massa - b.massa);
    const lineItem = [...dataLine].sort((a, b) => a.massa - b.massa)[0];
    const minValue = consumptionItems.length && lineItem
        ? Math.min(consumptionItems[0]?.massa, lineItem.massa)
        : consumptionItems.length
            ? consumptionItems[0]?.massa
            : lineItem
                ? lineItem.massa
                : 0;
    return minValue;
};
/**
 * Функция для фильтрации элементов данных столбцов, не связанных с потреблением.
 * @param {IRecommendationsScheduleColumn[]} columnData - данные столбцов.
 * @returns {IRecommendationsScheduleColumn[]} - отфильтрованные данные столбцов.
 */
const filterNonConsumptionItems = (columnData) => {
    return columnData.filter((item) => item.type !== 'Потребление' && item.type !== 'Demand');
};
/**
 * Функция для вычисления ежемесячных данных на основе данных столбцов.
 * @param {IRecommendationsScheduleColumn[]} nonConsumptionItems - данные столбцов, не связанных с потреблением.
 * @returns {IRecommendationsScheduleColumn[]} - ежемесячные данные.
 */
const calculateMonthlyData = (nonConsumptionItems) => {
    return nonConsumptionItems.reduce((acc, item) => {
        const existingMonth = acc.find((i) => i.month === item.month);
        if (existingMonth) {
            existingMonth.massa += item.massa;
        }
        else {
            acc.push({ ...item });
        }
        return acc;
    }, []);
};
/**
 * Функция для сортировки данных линии по убыванию массы.
 * @param {IRecommendationsScheduleLine[]} dataLine - данные линии.
 * @returns {IRecommendationsScheduleLine[]} - отсортированные данные линии.
 */
const sortDataLine = (dataLine) => {
    return dataLine.sort((a, b) => b.massa - a.massa);
};
/**
 * Функция для определения максимального значения оси Y для рекомендаций.
 * @param {IRecommendationsScheduleColumn[]} columnData - данные столбцов.
 * @param {IRecommendationsScheduleLine[]} dataLine - данные линии.
 * @returns {number} - максимальное значение оси Y.
 */
export const maxAxisYRecommendation = (columnData, dataLine) => {
    const nonConsumptionItems = filterNonConsumptionItems([...columnData]);
    const lineItem = sortDataLine([...dataLine])[0];
    if (!nonConsumptionItems.length) {
        return lineItem ? lineItem.massa || 0 : 0;
    }
    const monthlyData = calculateMonthlyData(nonConsumptionItems);
    const columnItem = sortDataLine(monthlyData)[0];
    if (lineItem?.massa && (!columnItem || lineItem.massa > columnItem.massa)) {
        return lineItem.massa;
    }
    else if (columnItem?.massa) {
        return columnItem.massa;
    }
    else {
        return 0;
    }
};
/**
 * Функция для обработки и фильтрации данных на основе выбранного года.
 * @param {IRecommendationsScheduleColumn[]} dataSchedule - данные столбцов.
 * @param {IRecommendationsScheduleLine[]} lineData - данные линии.
 * @param {Record<string, boolean>} switchFilterYear - выбранные фильтры по годам.
 * @returns {[IRecommendationsScheduleColumn[], IRecommendationsScheduleLine[]]} - массив отфильтрованных данных.
 */
export const processAndFilterData = (dataSchedule, lineData, switchFilterYear) => {
    const filteredData = filterRecommendationsSchedulesByYears(dataSchedule, Object.keys(switchFilterYear).filter((year) => switchFilterYear[year]));
    const filteredDataLine = filterRecommendationsSchedulesByYears(lineData, Object.keys(switchFilterYear).filter((year) => switchFilterYear[year]));
    return [filteredData, filteredDataLine];
};
/**
 * Функция для фильтрации и сортировки данных столбцов на основе выбранных фильтров и настроек отображения.
 * @param {IRecommendationsScheduleColumn[] | undefined} filteredData - отфильтрованные данные столбцов.
 * @param {Record<string, boolean>} switchFilterYear - выбранные фильтры по годам.
 * @param {IShowLines} showLines - настройки отображения линий.
 * @returns {IRecommendationsScheduleColumn[]} - отфильтрованные и отсортированные данные столбцов.
 */
export const sortAndFilterColumnData = (filteredData, switchFilterYear, showLines, isLangRu) => {
    if (!filteredData)
        return;
    const typeOrder = isLangRu ? TYPE_ORDER_RU : TYPE_ORDER_EN;
    const filteredAndSortedData = filteredData
        .filter((item) => typeOrder.includes(item.type))
        .sort((a, b) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type));
    const filterColumn = filteredAndSortedData.filter((item) => {
        const showLine = showLines[item.name];
        return showLine !== undefined ? showLine : false;
    });
    if (showLines.detailedView === false &&
        (showLines.recommendations || showLines.redistributions)) {
        return addEmptyDataFilters(showLines, filterColumn, switchFilterYear, isLangRu);
    }
    else {
        return filterColumn;
    }
};
export const addLineLastMonths = (dataLine) => {
    if (!dataLine)
        return [];
    const oneMonthsLine = dayjs(dataLine[0]?.month).format('MM');
    if (oneMonthsLine === '01')
        return dataLine;
    const arrNewLineData = [];
    for (let i = 1; i < Number(oneMonthsLine); i++) {
        arrNewLineData.push({
            massa: 0,
            month: `2023-${i > 9 ? i : `0${i}`}`,
            name: 'Фактические остатки на конец периода',
            type: 'fact',
            year: '2023',
        });
    }
    return [...arrNewLineData, ...dataLine];
};
