// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DatePickerComponent-module__date-picker-wrap__Gp0LG{width:100%;position:relative}.DatePickerComponent-module__date-picker-wrap__Gp0LG .TextField_labelPosition_top{width:100%}.DatePickerComponent-module__date-picker-icon-labeled__pQrM_{position:absolute;top:39px;right:8px}.DatePickerComponent-module__date-picker-icon__Gmlun{position:absolute;top:8px;right:8px}`, "",{"version":3,"sources":["webpack://./src/components/UI/DatePicker/DatePickerComponent.module.scss"],"names":[],"mappings":"AAAA,qDACE,UAAA,CACA,iBAAA,CAEA,kFACE,UAAA,CAIJ,6DACE,iBAAA,CACA,QAAA,CACA,SAAA,CAGF,qDACE,iBAAA,CACA,OAAA,CACA,SAAA","sourcesContent":[".date-picker-wrap {\r\n  width: 100%;\r\n  position: relative;\r\n\r\n  & :global(.TextField_labelPosition_top) {\r\n    width: 100%;\r\n  }\r\n}\r\n\r\n.date-picker-icon-labeled {\r\n  position: absolute;\r\n  top: 39px;\r\n  right: 8px;\r\n}\r\n\r\n.date-picker-icon {\r\n  position: absolute;\r\n  top: 8px;\r\n  right: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date-picker-wrap": `DatePickerComponent-module__date-picker-wrap__Gp0LG`,
	"date-picker-icon-labeled": `DatePickerComponent-module__date-picker-icon-labeled__pQrM_`,
	"date-picker-icon": `DatePickerComponent-module__date-picker-icon__Gmlun`
};
export default ___CSS_LOADER_EXPORT___;
