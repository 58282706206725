export var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "admin";
    UserRole["SPECIALIST"] = "specialist";
    UserRole["DATA_LOADER"] = "data_loader";
    UserRole["SUPERVISOR"] = "supervisor";
})(UserRole || (UserRole = {}));
export function getRusRoles(strRole) {
    switch (strRole) {
        case UserRole.ADMIN:
            return 'Администратор';
        case UserRole.SPECIALIST:
            return 'Специалист';
        case UserRole.DATA_LOADER:
            return 'Загрузчик данных';
        case UserRole.SUPERVISOR:
            return 'Руководитель';
        default:
            return 'Неизвестная роль';
    }
}
