import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { minAxisYRecommendation, maxAxisYRecommendation, getColorColumn, getColorLine, recommendationsSchedulesFormatter, } from '../../RecommendationsSchedule/recommendationsSchedule.util';
import { yLabelFormatter, monthTooltipTitleFormatter, } from '../Schedule/scheduleComponent.util';
import { DualAxes } from '@consta/charts/DualAxes';
import dayjs from 'dayjs';
export const ScheduleRecommendation = memo(({ columnData, dataLine, isLangRu }) => {
    return (_jsx(DualAxes, { data: [columnData, dataLine], xField: 'month', yField: ['massa', 'massa'], animation: { appear: { duration: 900 } }, xAxis: {
            type: 'cat',
            label: {
                formatter: (text) => dayjs(text)
                    .locale(isLangRu ? 'ru' : 'en')
                    .format('MMM'),
            },
        }, yAxis: [
            {
                nice: true,
                label: {
                    formatter: (text) => yLabelFormatter(text, isLangRu),
                },
                min: minAxisYRecommendation(columnData, dataLine),
                max: maxAxisYRecommendation(columnData, dataLine),
            },
            {
                nice: true,
                position: 'left',
                label: {
                    formatter: (text) => yLabelFormatter(text, isLangRu),
                    style: {
                        fillOpacity: 0.4,
                    },
                },
                min: minAxisYRecommendation(columnData, dataLine),
                max: maxAxisYRecommendation(columnData, dataLine),
            },
        ], legend: false, geometryOptions: [
            {
                geometry: 'column',
                isStack: true,
                seriesField: 'type',
                groupField: 'name',
                color: getColorColumn,
            },
            {
                geometry: 'line',
                isStack: true,
                seriesField: 'type',
                color: getColorLine,
            },
        ], tooltip: {
            formatter: (datum) => recommendationsSchedulesFormatter(datum, isLangRu),
            title(title) {
                return monthTooltipTitleFormatter(title, isLangRu);
            },
            //Фильтрация пустых значений в тултипе
            customItems: (originalItems) => {
                return originalItems.filter((el) => {
                    return el.value !== null;
                });
            },
        } }));
}, (prevPops, nextProps) => {
    return JSON.stringify(prevPops) === JSON.stringify(nextProps);
});
