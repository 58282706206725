import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState, memo } from 'react';
import { DatePickerComponent } from '../UI/DatePicker/DataPickerComponent';
import { INITIAL_BM_FILTERS_DATA, INITIAL_GROUP_FILTERS_DATA, } from '../../constants/initial.constants';
import { useGetGroupsMaterialQuery, useGetAnalogGroupsQuery, useGetBusUnitsDocQuery, } from '../../api/consumptionApi';
import { useGetAnalogGroupsBusinessQuery, useGetBusUnitBusinessQuery, useGetGroupsMaterialBusinessQuery, } from '../../api/recommendationsApi';
import { MultiSelect } from '../UI/MultiSelect/MultiSelect';
import { SelectComponent } from '../UI/Select/SelectComponent';
import './index.scss';
import { Switch } from '@consta/uikit/Switch';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../constants/enums/locale.enum';
export const LeftFilterMenu = memo(({ filterType, valueSwitch, setValueSwitch, valueFiltersAll, setValueFiltersAll, }) => {
    const { t } = useTranslation();
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const [consumptionFiltersData, setConsumptionFiltersData] = useState(INITIAL_GROUP_FILTERS_DATA);
    const [recommendationsFiltersData, setRecommendationsFiltersData] = useState(INITIAL_GROUP_FILTERS_DATA);
    const [businessMetricsFiltersData, setBusinessMetricsFiltersData] = useState(INITIAL_BM_FILTERS_DATA);
    const [consumptionAnalogSkip, setConsumptionAnalogSkip] = useState(true);
    const lang = localStorage.getItem('lang') || Locale.RUS;
    useEffect(() => {
        setConsumptionFiltersData(INITIAL_GROUP_FILTERS_DATA);
        setRecommendationsFiltersData(INITIAL_GROUP_FILTERS_DATA);
        setBusinessMetricsFiltersData(INITIAL_BM_FILTERS_DATA);
    }, [isLangRu]);
    const { data: groupMaterial, refetch: groupMaterialRefetch } = useGetGroupsMaterialQuery({ pageName: filterType, lang }, { skip: filterType === '' });
    const { data: busUnitsDoc, refetch: busUnitsDocRefetch } = useGetBusUnitsDocQuery({
        pageName: filterType,
        lang,
    }, { skip: filterType === '' });
    const { data: groupMaterialAnalog } = useGetAnalogGroupsQuery({
        groups_materials: valueFiltersAll[filterType]?.commodityGroup.id,
        section: filterType,
        lang,
    }, { skip: consumptionAnalogSkip });
    const { data: groupMaterialBusiness, refetch: groupMaterialRefetchBusiness, } = useGetGroupsMaterialBusinessQuery({ pageName: filterType, lang }, {
        skip: filterType === '',
    });
    const { data: busUnitsBusiness, refetch: busUnitsRefetchBusiness } = useGetBusUnitBusinessQuery({ pageName: filterType, lang }, { skip: filterType === '' });
    const { data: groupMaterialAnalogBusiness } = useGetAnalogGroupsBusinessQuery({
        groups_materials: valueFiltersAll[filterType]?.commodityGroup.id,
        section: filterType,
        lang,
    }, { skip: consumptionAnalogSkip });
    /**
     * Эффект для обработки изменения типа фильтра и выполнения запросов при необходимости.
     * Выполняет перезагрузку данных группы материалов и деловых подразделений документов при изменении типа фильтра.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (filterType === 'recommendations' || filterType === 'consumption') {
            groupMaterialRefetch();
            busUnitsDocRefetch();
        }
        if (filterType === 'business_metrics') {
            groupMaterialRefetchBusiness();
            busUnitsRefetchBusiness();
        }
    }, [
        filterType,
        busUnitsDocRefetch,
        groupMaterialRefetch,
        consumptionAnalogSkip,
        groupMaterialRefetchBusiness,
        busUnitsRefetchBusiness,
    ]);
    /**
     * Функция для изменения состояния фильтров в зависимости от типа фильтра.
     * В зависимости от значения `filterType` обновляет соответствующее состояние фильтров для рекомендаций или потребления.
     * @function
     * @param {IListStock[]} arr - Новый массив значений для обновления фильтров.
     * @param {string} nameArr - Название состояния фильтров, которое нужно обновить.
     * @returns {void}
     */
    const changeStateFilter = useCallback((arr, nameArr) => {
        switch (filterType) {
            case 'recommendations':
                setRecommendationsFiltersData((prevState) => ({
                    ...prevState,
                    [nameArr]: arr,
                }));
                break;
            case 'consumption':
                setConsumptionFiltersData((prevState) => ({
                    ...prevState,
                    [nameArr]: nameArr === 'groupMaterialList'
                        ? arr
                        : [{ label: isLangRu ? 'Все' : 'All', id: 1 }, ...arr],
                }));
                break;
            case 'business_metrics':
                setBusinessMetricsFiltersData((prevState) => ({
                    ...prevState,
                    [nameArr]: nameArr === 'groupMaterialList'
                        ? arr
                        : [{ label: isLangRu ? 'Все' : 'All', id: 1 }, ...arr],
                }));
                break;
        }
    }, [filterType, isLangRu]);
    /**
     * Эффект для обработки изменения состояния `groupMaterial` и обновления фильтров.
     * Если `groupMaterial` содержит элементы, обновляет состояния фильтров для группы материалов.
     * Если `groupMaterial` пуст, сбрасывает состояния фильтров для группы материалов и аналогов.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (groupMaterial?.items.length && filterType !== 'business_metrics') {
            changeStateFilter(groupMaterial.items, 'groupMaterialList');
        }
        else if (!groupMaterial?.items.length) {
            if (filterType === 'recommendations') {
                setRecommendationsFiltersData((prevState) => ({
                    ...prevState,
                    groupMaterialList: [],
                }));
                setValueFiltersAll((prevState) => ({
                    ...prevState,
                    recommendations: {
                        ...prevState.recommendations,
                        commodityGroup: '',
                    },
                }));
            }
            if (filterType === 'consumption') {
                setConsumptionFiltersData((prevState) => ({
                    ...prevState,
                    groupMaterialList: [],
                }));
                setValueFiltersAll((prevState) => ({
                    ...prevState,
                    consumption: {
                        ...prevState.recommendations,
                        commodityGroup: '',
                    },
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupMaterial, setValueFiltersAll, changeStateFilter]);
    useEffect(() => {
        if (groupMaterialBusiness?.items.length &&
            filterType === 'business_metrics') {
            changeStateFilter(groupMaterialBusiness.items, 'groupMaterialList');
        }
        else if (!groupMaterialBusiness?.items.length) {
            setBusinessMetricsFiltersData((prevState) => ({
                ...prevState,
                groupMaterialList: [],
            }));
            setValueFiltersAll((prevState) => ({
                ...prevState,
                business_metrics: {
                    ...prevState.business_metrics,
                    commodityGroup: '',
                },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupMaterialBusiness, setValueFiltersAll, changeStateFilter]);
    /**
     * Эффект для обработки изменения состояния `groupMaterialAnalog` и обновления фильтров.
     * Если `groupMaterialAnalog` содержит элементы, обновляет состояния фильтров для группы материалов.
     * Если `groupMaterialAnalog` пуст, сбрасывает состояния фильтров для группы материалов и аналогов.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (groupMaterialAnalog?.items.length &&
            filterType !== 'business_metrics') {
            changeStateFilter(groupMaterialAnalog.items, 'groupAnalogsList');
        }
        else if (!groupMaterialAnalog?.items.length) {
            if (filterType === 'recommendations') {
                setRecommendationsFiltersData((prevState) => ({
                    ...prevState,
                    groupAnalogsList: [],
                }));
            }
            if (filterType === 'consumption') {
                setConsumptionFiltersData((prevState) => ({
                    ...prevState,
                    groupAnalogsList: [],
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupMaterialAnalog, changeStateFilter]);
    useEffect(() => {
        if (groupMaterialAnalogBusiness?.items.length &&
            filterType === 'business_metrics') {
            changeStateFilter(groupMaterialAnalogBusiness.items, 'groupAnalogsList');
        }
        else if (!groupMaterialAnalogBusiness?.items.length) {
            setBusinessMetricsFiltersData((prevState) => ({
                ...prevState,
                groupAnalogsList: [],
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupMaterialAnalogBusiness, changeStateFilter]);
    /**
     * Эффект для обработки изменения состояния `busUnitsDoc` и обновления фильтров.
     * Если `busUnitsDoc` содержит элементы, обновляет состояния фильтров для группы материалов.
     * Если `busUnitsDoc` пуст, сбрасывает состояния фильтров для группы материалов и аналогов.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (busUnitsDoc?.items.length && filterType !== 'business_metrics') {
            changeStateFilter(busUnitsDoc.items, 'busUnitsDocList');
        }
        else if (!busUnitsDoc?.items.length) {
            if (filterType === 'recommendations') {
                setRecommendationsFiltersData((prevState) => ({
                    ...prevState,
                    busUnitsDocList: [],
                }));
                setValueFiltersAll((prevState) => ({
                    ...prevState,
                    recommendations: { ...prevState.recommendations, choiceDO: '' },
                }));
            }
            if (filterType === 'consumption') {
                setConsumptionFiltersData((prevState) => ({
                    ...prevState,
                    busUnitsDocList: [],
                }));
                setValueFiltersAll((prevState) => ({
                    ...prevState,
                    consumption: { ...prevState.consumption, choiceDO: '' },
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [busUnitsDoc, setValueFiltersAll, changeStateFilter]);
    useEffect(() => {
        if (busUnitsBusiness?.items?.length &&
            filterType === 'business_metrics') {
            changeStateFilter(busUnitsBusiness.items, 'busUnitsDocList');
        }
        else if (!busUnitsBusiness?.items?.length) {
            setBusinessMetricsFiltersData((prevState) => ({
                ...prevState,
                busUnitsDocList: [],
            }));
            setValueFiltersAll((prevState) => ({
                ...prevState,
                business_metrics: { ...prevState.business_metrics, choiceDO: '' },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [busUnitsBusiness, setValueFiltersAll, changeStateFilter]);
    /**
     * Эффект для обработки изменения состояния фильтров `valueFiltersAll`
     * и установки значения `consumptionAnalogSkip` в зависимости от наличия значения `commodityGroup`.
     * Если значение `commodityGroup` в состояниях `valueFiltersAll` не пусто,
     * устанавливает `consumptionAnalogSkip` в `false`. В противном случае, устанавливает `consumptionAnalogSkip` в `true`.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        setConsumptionAnalogSkip(!valueFiltersAll[filterType]?.commodityGroup);
    }, [filterType, valueFiltersAll]);
    /**
     * Эффект для обработки изменения состояния фильтра `valueFiltersAll.consumption` и установки значения `synthesisGroup`.
     * Если значение `commodityGroup` в состоянии `valueFiltersAll.consumption` не пусто,
     * а значение `synthesisGroup` пусто и список `groupAnalogsList` в состоянии `consumptionFiltersData` содержит элементы,
     * устанавливает `synthesisGroup` в состоянии `valueFiltersAll.consumption` с копией списка `groupAnalogsList`.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        const handleGroupAnalogsListChange = (filterType, filtersData) => {
            const commodityGroup = valueFiltersAll[filterType]?.commodityGroup;
            const prevSynthesisGroup = valueFiltersAll[filterType]?.synthesisGroup;
            const newSynthesisGroup = filtersData.groupAnalogsList;
            if (commodityGroup &&
                !prevSynthesisGroup &&
                filterType !== 'recommendations') {
                setValueFiltersAll((prevState) => ({
                    ...prevState,
                    [filterType]: {
                        ...prevState[filterType],
                        synthesisGroup: newSynthesisGroup.length
                            ? [...newSynthesisGroup]
                            : [],
                        choiceDO: filtersData.busUnitsDocList.length
                            ? [...filtersData.busUnitsDocList]
                            : [],
                    },
                }));
            }
            else if (commodityGroup &&
                prevSynthesisGroup &&
                newSynthesisGroup.length) {
                const isPrevSynthesisLabel = prevSynthesisGroup[0]?.label === 'Все' ||
                    prevSynthesisGroup[0]?.label === 'All';
                const idPrevSynthesisGroup = filterType === 'recommendations'
                    ? prevSynthesisGroup.id
                    : prevSynthesisGroup[isPrevSynthesisLabel ? 1 : 0]?.id;
                const isNewSynthesisGroup = newSynthesisGroup.some((item) => item.id === idPrevSynthesisGroup);
                if (!isNewSynthesisGroup) {
                    setValueFiltersAll((prevState) => ({
                        ...prevState,
                        [filterType]: {
                            ...prevState[filterType],
                            synthesisGroup: filterType === 'recommendations'
                                ? ''
                                : [...newSynthesisGroup],
                        },
                    }));
                }
            }
        };
        handleGroupAnalogsListChange('consumption', consumptionFiltersData);
        handleGroupAnalogsListChange('business_metrics', businessMetricsFiltersData);
        handleGroupAnalogsListChange('recommendations', recommendationsFiltersData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        consumptionFiltersData.groupAnalogsList,
        businessMetricsFiltersData.groupAnalogsList,
        recommendationsFiltersData.groupAnalogsList,
        setValueFiltersAll,
    ]);
    /**
     * Функция для обработки изменения фильтра в зависимости от значения `filterType`.
     * Вызывает соответствующую функцию обновления фильтров в зависимости от типа фильтрации.
     * @function
     * @param {ISelectValue | ISelectValue[] | boolean} item - Выбранное значение фильтра.
     * @param {string} filter - Название фильтра, который требуется обновить.
     * @returns {void}
     */
    const changeFilter = (item, filter) => {
        switch (filterType) {
            case 'recommendations':
                changeFilterRecommendations(item, filter);
                break;
            case 'consumption':
                changeFilterConsumption(item, filter, 'consumption');
                break;
            case 'business_metrics':
                changeFilterBusinessMetrics(item, filter, 'business_metrics');
                break;
        }
    };
    /**
     * Функция для обновления фильтрации в разделе "Рекомендации".
     * Обновляет состояние `valueFilters`, заменяя выбранное значение фильтра с именем `filter` на `item`.
     * @function
     * @param {ISelectValue} item - Выбранное значение фильтра.
     * @param {string} filter - Название фильтра, который требуется обновить.
     * @returns {void}
     */
    const changeFilterRecommendations = (item, filter) => {
        setValueFiltersAll((prevState) => ({
            ...prevState,
            recommendations: { ...prevState.recommendations, [filter]: item },
        }));
    };
    /**
     * Функция для обновления фильтрации в разделе "Бизнес метрики".
     * Обновляет состояние `valueFiltersConsumption` в зависимости от значения `filter`.
     * @function
     * @param {ISelectValue[] | boolean | Date} item - Выбранные значения фильтра (может быть массивом или логическим значением).
     * @param {string} filter - Название фильтра, который требуется обновить.
     * @param {string} stateFilter - Название фильтра, который требуется обновить.
     * @returns {void}
     */
    const changeFilterBusinessMetrics = (item, filter, stateFilter) => {
        setValueFiltersAll((prevState) => ({
            ...prevState,
            [stateFilter]: changeStateBusinessMetrics(prevState[stateFilter], item, filter),
        }));
    };
    const changeStateBusinessMetrics = (prevStateConsumption, item, filter) => {
        switch (filter) {
            case 'commodityGroup':
                return { ...prevStateConsumption, [filter]: item };
            case 'synthesisGroup':
                return changeFilterSynthesisGroup(prevStateConsumption, item, filter, businessMetricsFiltersData.groupAnalogsList);
            case 'choiceDO':
                return changeFilterSynthesisGroup(prevStateConsumption, item, filter, businessMetricsFiltersData.busUnitsDocList);
            case 'period':
                return { ...prevStateConsumption, [filter]: item };
        }
    };
    /**
     * Функция для обновления фильтрации в разделе "Прогноз потребления".
     * Обновляет состояние `valueFiltersConsumption` в зависимости от значения `filter`.
     * @function
     * @param {ISelectValue[] | boolean} item - Выбранные значения фильтра (может быть массивом или логическим значением).
     * @param {string} filter - Название фильтра, который требуется обновить.
     * @param {string} stateFilter - Название фильтра, который требуется обновить.
     * @returns {void}
     */
    const changeFilterConsumption = (item, filter, stateFilter) => {
        setValueFiltersAll((prevState) => ({
            ...prevState,
            [stateFilter]: changeStateConsumption(prevState[stateFilter], item, filter),
        }));
    };
    const changeStateConsumption = (prevStateConsumption, item, filter) => {
        switch (filter) {
            case 'commodityGroup':
                return { ...prevStateConsumption, [filter]: item };
            case 'synthesisGroup':
                return changeFilterSynthesisGroup(prevStateConsumption, item, filter, consumptionFiltersData.groupAnalogsList);
            case 'choiceDO':
                return changeFilterSynthesisGroup(prevStateConsumption, item, filter, consumptionFiltersData.busUnitsDocList);
        }
    };
    /**
     * Функция для обновления фильтрации группы синтезов в разделе "Прогноз потребления".
     * Обновляет состояние `value` на основе выбранных значений `item`, `filter` и `arr`.
     * @function
     * @param {IFilterInitial} value - Текущее состояние фильтров.
     * @param {ISelectValue[]} item - Выбранные значения фильтра (массив типа `ISelectValue`).
     * @param {string} filter - Название фильтра, который требуется обновить.
     * @param {IListStock[]} arr - Массив, на основе которого происходит фильтрация.
     * @returns {IFilterInitial} - Обновленное состояние фильтров.
     */
    const changeFilterSynthesisGroup = (value, item, filter, arr) => {
        const returnObj = { ...value, [filter]: [] };
        if (!item) {
            return returnObj;
        }
        const all = isLangRu ? 'Все' : 'All';
        const itemsLabelFind = searchArrLabel(item, all);
        const synthesisLabelFind = searchArrLabel(value[filter], all);
        if (synthesisLabelFind && arr.length !== item.length && !itemsLabelFind) {
            returnObj[filter] = [];
        }
        else if (synthesisLabelFind) {
            returnObj[filter] = item.filter((item) => item.label !== all);
        }
        else if (itemsLabelFind ||
            (!itemsLabelFind &&
                !synthesisLabelFind &&
                item.length + 1 === arr.length)) {
            returnObj[filter] = arr;
        }
        else {
            returnObj[filter] = item;
        }
        return returnObj;
    };
    /**
     * Функция для поиска элемента в массиве по значению свойства "label".
     * @function
     * @param {any} arr - Массив, в котором производится поиск.
     * @param {string} searchName - Искомое значение свойства "label".
     * @returns {any | undefined} - Найденный элемент или `undefined`, если элемент не найден.
     */
    const searchArrLabel = (arr, searchName) => {
        return arr ? arr.find((value) => value.label === searchName) : undefined;
    };
    /**
     * Функция для обновления состояния переключателей (switch).
     * @function
     * @param {boolean} item - Новое значение переключателя.
     * @param {string} year - Год, связанный с переключателем.
     * @returns {void} - Ничего не возвращает.
     */
    const changeSwitch = (item, year) => {
        setValueSwitch((value) => {
            return { ...value, [year]: item };
        });
    };
    return (_jsx("div", { className: 'left-filter-wrap', "data-testid": 'left-filter-wrap', children: filterType !== '' && (_jsxs(_Fragment, { children: [_jsx("div", { className: 'form-wrap', children: _jsx(SelectComponent, { items: filterType === 'recommendations'
                            ? recommendationsFiltersData.groupMaterialList
                            : filterType === 'consumption'
                                ? consumptionFiltersData.groupMaterialList
                                : businessMetricsFiltersData.groupMaterialList, value: filterType === 'recommendations'
                            ? valueFiltersAll.recommendations.commodityGroup
                            : filterType === 'consumption'
                                ? valueFiltersAll.consumption.commodityGroup
                                : valueFiltersAll.business_metrics.commodityGroup, label: t('SelectingProductGroup'), changeFilter: changeFilter, filter: 'commodityGroup' }) }), filterType === 'recommendations' ? (_jsx("div", { className: 'form-wrap', children: _jsx(SelectComponent, { items: valueFiltersAll.recommendations.commodityGroup
                            ? recommendationsFiltersData.groupAnalogsList
                            : [], value: valueFiltersAll.recommendations.synthesisGroup, label: t('ChoosingSynthesisGroup'), changeFilter: changeFilter, filter: 'synthesisGroup' }) })) : (_jsx("div", { className: 'form-wrap', children: _jsx(MultiSelect, { items: filterType === 'consumption'
                            ? valueFiltersAll.consumption.commodityGroup
                                ? consumptionFiltersData.groupAnalogsList
                                : []
                            : valueFiltersAll.business_metrics.commodityGroup
                                ? businessMetricsFiltersData.groupAnalogsList
                                : [], value: filterType === 'consumption'
                            ? valueFiltersAll.consumption.synthesisGroup
                            : valueFiltersAll.business_metrics.synthesisGroup, label: t('ChoosingSynthesisGroup'), changeFilter: changeFilter, filter: 'synthesisGroup' }) })), filterType === 'recommendations' ? (_jsx("div", { className: 'form-wrap', "data-testid": 'recommendations', children: _jsx(SelectComponent, { items: recommendationsFiltersData.busUnitsDocList, value: valueFiltersAll.recommendations.choiceDO, label: t('SelectBU'), changeFilter: changeFilter, filter: 'choiceDO' }) })) : (_jsx("div", { className: 'form-wrap', children: _jsx(MultiSelect, { items: filterType === 'consumption'
                            ? consumptionFiltersData.busUnitsDocList
                            : businessMetricsFiltersData.busUnitsDocList, value: filterType === 'consumption'
                            ? valueFiltersAll.consumption.choiceDO
                            : valueFiltersAll.business_metrics.choiceDO, label: t('SelectBU'), changeFilter: changeFilter, filter: 'choiceDO' }) })), filterType !== 'business_metrics' && (_jsxs("div", { className: 'switch-year-wrap', children: [_jsx("div", { children: t('SelectingDisplayPeriod') }), _jsxs("div", { className: 'switch-wrap', children: [_jsx(Switch, { size: 'm', checked: valueSwitch['2023'], onChange: ({ checked }) => changeSwitch(checked, '2023'), label: '2023' }), _jsx(Switch, { size: 'm', checked: valueSwitch['2024'], onChange: ({ checked }) => changeSwitch(checked, '2024'), label: '2024' })] })] })), filterType === 'business_metrics' && (_jsx("div", { className: 'form-wrap form-wrap-date-picker-color', children: _jsx(DatePickerComponent, { value: valueFiltersAll.business_metrics.period, type: 'month', changeFilter: changeFilter, filter: 'period', label: t('SelectingDisplayPeriod'), placeholder: t('displayPeriod') }) }))] })) }));
});
