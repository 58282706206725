import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useState } from 'react';
import { InfoModalComponent } from '../UI/InfoModal/InfoModalComponent';
import DeleteDoc from '../../assets/resource/deleteDoc.svg';
import s from './DocumentInfo.module.scss';
import { Checkbox } from '@consta/uikit/Checkbox';
import { useAppSelector } from '../../store/hook';
import { useTranslation } from 'react-i18next';
export const DocumentInfo = memo(({ documentInfo, deleteDocumentId, addDocumentIdAccount }) => {
    const { t } = useTranslation();
    const { permissions } = useAppSelector((state) => state.user);
    const editPermission = permissions.includes('api.add_document');
    const [isOpenModalSaveDoc, setIsOpenModalSaveDoc] = useState(false);
    /**
     * Callback-функция для изменения состояния модального окна сохранения документа.
     * @function
     * @returns {void}
     */
    const changeModalIsSaveDoc = useCallback(() => {
        setIsOpenModalSaveDoc((prevState) => !prevState);
    }, []);
    /**
     * Функция для удаления файла.
     * Вызывает функцию deleteDocumentId с передачей идентификатора документа (documentInfo.id).
     * @function
     * @returns {void}
     */
    const deleteFile = () => {
        deleteDocumentId(documentInfo.id);
    };
    /**
     * Функция для добавления документа.
     * Вызывает функцию addDocumentIdAccount с передачей идентификатора документа (documentInfo.id) и флага активности (documentInfo.is_active).
     * @function
     * @returns {void}
     */
    const addDocument = () => {
        addDocumentIdAccount(documentInfo.id, documentInfo.is_active);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s['document-wrap'], "data-testid": 'document-wrap', children: [editPermission && (_jsx("div", { children: _jsx(Checkbox, { size: 'l', checked: documentInfo.is_active, onChange: addDocument, "data-testid": 'addDocument' }) })), _jsx("div", { className: s['document-text'], children: documentInfo.name }), _jsx("div", { className: s['document-text'], children: documentInfo.doc_type }), _jsx("div", { className: `${s['document-text']} ${!editPermission && s['document-text__auto-width']}`, children: documentInfo.created_date }), editPermission && (_jsx("div", { "data-testid": 'delete-doc', className: s['delete-doc'], onClick: changeModalIsSaveDoc, children: _jsx("img", { src: DeleteDoc, alt: '\u0443\u0434\u0430\u043B\u0435\u043D\u0438\u0435' }) }))] }), isOpenModalSaveDoc && (_jsx(InfoModalComponent, { testId: 'infoModal', isModalOpen: isOpenModalSaveDoc, changeModalIsOpen: changeModalIsSaveDoc, text: t('DeleteFile'), isSaveInfo: true, handleFileUpload: deleteFile, cancelButtonName: t('Cancel'), successButtonName: t('Confirm') }))] }));
});
