import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, memo, useCallback, useMemo } from 'react';
import { processAndFilterData, sortAndFilterColumnData, addLineLastMonths, } from './recommendationsSchedule.util';
import { LegendsColor } from '../LegendsColor/LegendsColor';
import s from './RecommendationsSchedule.module.scss';
import { getChartDataUniqueYears } from '../../utils/chart.util';
import { COLUMN_COLOR_LIST_RU, COLUMN_COLOR_LIST_EN, SWITCHES_RECOMMENDATIONS_SCHEDULE_EN, SWITCHES_RECOMMENDATIONS_SCHEDULE_RU, } from './RecommendationsSchedule.constants';
import { EmptyScheduleComponent } from '../EmptyScheduleComponent/EmptyScheduleComponent';
import { Switch } from '@consta/uikit/Switch';
import { ScheduleRecommendation } from '../UI/ScheduleRecommendation/ScheduleRecommendation';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../constants/enums/locale.enum';
export const RecommendationsSchedule = memo(({ dataSchedule, axisYName, switchFilterYear, lineData, setShowLines, showLines, }) => {
    const { t } = useTranslation();
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const [columnData, setColumnData] = useState([]);
    const [dataLine, setDataLine] = useState([]);
    const switchYearLength = Object.keys(switchFilterYear).filter((year) => switchFilterYear[year]).length;
    const [switches, setSwitches] = useState([]);
    useEffect(() => {
        setSwitches(isLangRu
            ? SWITCHES_RECOMMENDATIONS_SCHEDULE_RU
            : SWITCHES_RECOMMENDATIONS_SCHEDULE_EN);
    }, [isLangRu, t]);
    /**
     * Эффект для установки значений для графика.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (!dataSchedule)
            return;
        const [filteredData, filteredDataLine] = processAndFilterData(dataSchedule, lineData, switchFilterYear);
        const newColumnData = sortAndFilterColumnData(filteredData, switchFilterYear, showLines, isLangRu);
        const newLineDataLine = addLineLastMonths(filteredDataLine);
        setColumnData(newColumnData);
        setDataLine(newLineDataLine);
    }, [dataSchedule, lineData, showLines, switchFilterYear, isLangRu]);
    /**
     * Функция для установки отображения линий.
     * @function
     * @param {boolean} item - значение отображения.
     * @param {string} line - линия отображения.
     * @returns {void}
     */
    const changeSwitch = useCallback((item, line) => {
        setShowLines((prevShowLines) => ({
            ...prevShowLines,
            [line]: item,
        }));
    }, [setShowLines]);
    const uniqueYears = getChartDataUniqueYears(columnData.length ? columnData : dataLine);
    const columnListLegend = useMemo(() => {
        if (!isLangRu) {
            return COLUMN_COLOR_LIST_EN.filter((item) => {
                if (item.name === 'line')
                    return true;
                const showLine = showLines[item.name];
                return showLine !== undefined ? showLine : false;
            });
        }
        else {
            return COLUMN_COLOR_LIST_RU.filter((item) => {
                if (item.name === 'line')
                    return true;
                const showLine = showLines[item.name];
                return showLine !== undefined ? showLine : false;
            });
        }
    }, [showLines, isLangRu]);
    return (_jsxs("div", { className: s['schedule-container'], "data-testid": 'schedule-container', children: [_jsx("div", { className: s['switch-wrap-schedule'], children: switches.map(({ key, label }) => (_jsx(Switch, { size: 'm', checked: showLines[key], onChange: ({ checked }) => changeSwitch(checked, key), label: label, "data-testid": `switch-${key}` }, key))) }), _jsx("div", { className: s['legend-container'], style: {
                    width: `${switchYearLength === 1 ? 93.8 : switchYearLength === 2 ? 95.7 : 97}%`,
                    paddingLeft: `${switchYearLength === 1 ? 3.5 : switchYearLength === 2 ? 5.2 : 5.9}%`,
                }, children: (COLUMN_COLOR_LIST_RU || COLUMN_COLOR_LIST_EN) &&
                    columnListLegend.map((item) => (_jsx(LegendsColor, { listColorsLegend: item }, item.color))) }), dataLine.length || columnData.length ? (_jsxs("div", { className: s['wrap-schedule-container-forecast'], style: {
                    width: `${switchYearLength === 1
                        ? 93.8
                        : switchYearLength === 2
                            ? 95.7
                            : 97}%`,
                    paddingLeft: `${switchYearLength === 1
                        ? 3.5
                        : switchYearLength === 2
                            ? 5.2
                            : 5.9}%`,
                }, children: [_jsx("div", { className: s['text-left-schedule-forecast'], style: {
                            left: `${switchYearLength === 1 ? 0.9 : 2.7}%`,
                        }, children: axisYName }), _jsx(ScheduleRecommendation, { columnData: columnData, dataLine: dataLine, isLangRu: isLangRu }), _jsx("div", { className: s['year-label'], children: uniqueYears.map((year) => (_jsx("div", { className: s['year'], children: year }, year))) })] })) : (_jsx(EmptyScheduleComponent, {}))] }));
});
