// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TabsComponent-module__tabs-custom__ecUMl{width:100%;height:28px;background-color:rgba(0,32,51,.05);font-size:14px;display:flex;justify-content:space-between;align-items:center;border-radius:6px;cursor:pointer;user-select:none}.TabsComponent-module__tabs-nav__C1Q2n{color:#002033;padding:3px 12px;transition:all 200ms ease-in-out}.TabsComponent-module__tabs-nav-active__YpMHk{padding:2px 12px;background-color:#0078d2;color:#fff;border-radius:4px;transition:all 200ms ease-in-out}.TabsComponent-module__tabs-nav-nonactive__dAZKa{cursor:not-allowed}`, "",{"version":3,"sources":["webpack://./src/components/UI/Tabs/TabsComponent.module.scss"],"names":[],"mappings":"AAAA,0CACE,UAAA,CACA,WAAA,CACA,kCAAA,CACA,cAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CACA,gBAAA,CAGF,uCACE,aAAA,CACA,gBAAA,CACA,gCAAA,CAEA,8CACE,gBAAA,CACA,wBAAA,CACA,UAAA,CACA,iBAAA,CACA,gCAAA,CAGF,iDACE,kBAAA","sourcesContent":[".tabs-custom {\r\n  width: 100%;\r\n  height: 28px;\r\n  background-color: rgba(0, 32, 51, 0.05);\r\n  font-size: 14px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  border-radius: 6px;\r\n  cursor: pointer;\r\n  user-select: none;\r\n}\r\n\r\n.tabs-nav {\r\n  color: rgba(0, 32, 51, 1);\r\n  padding: 3px 12px;\r\n  transition: all 200ms ease-in-out;\r\n\r\n  &-active {\r\n    padding: 2px 12px;\r\n    background-color: rgba(0, 120, 210, 1);\r\n    color: white;\r\n    border-radius: 4px;\r\n    transition: all 200ms ease-in-out;\r\n  }\r\n\r\n  &-nonactive {\r\n    cursor: not-allowed;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs-custom": `TabsComponent-module__tabs-custom__ecUMl`,
	"tabs-nav": `TabsComponent-module__tabs-nav__C1Q2n`,
	"tabs-nav-active": `TabsComponent-module__tabs-nav-active__YpMHk`,
	"tabs-nav-nonactive": `TabsComponent-module__tabs-nav-nonactive__dAZKa`
};
export default ___CSS_LOADER_EXPORT___;
