import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { useGetRecommendationsCardInfoQuery, useGetRecommendationsCreateMutation, useGetRecommendationsDeleteMutation, useGetWarehousesListQuery, } from '../../api/recommendationsApi';
import { DatePickerComponent } from '../UI/DatePicker/DataPickerComponent';
import { TextFieldComponent } from '../UI/TextField/TextFieldComponent';
import CheckMarkGrey from '../../assets/resource/check-mark-grey.svg';
import { createQueryStringFromObject, formatNumber, getFormatNumberQuantity, } from '../../utils/utils';
import CloseBooking from '../../assets/resource/close-bookind.svg';
import closeSyntezGgroup from '../../assets/resource/closeSyntezGgroup.svg';
import CancelIcon from '../../assets/resource/cancle-icon.svg';
import FlagWhite from '../../assets/resource/flag-white.svg';
import CheckMark from '../../assets/resource/check-mark.svg';
import FlagGrey from '../../assets/resource/flag-grey.svg';
import { Placeholder } from '../Placeholder/Placeholder';
import { useAppSelector } from '../../store/hook';
import { LoaderComponent } from '../UI/Loader/LoaderComponent';
import { SelectComponent } from '../UI/Select/SelectComponent';
import './index.scss';
import { Modal } from '@consta/uikit/Modal';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../constants/enums/locale.enum';
export const SynthesisRecommendations = ({ changeOpenRecommendation, isModalOpen, item, changeSwitchRequest, unit, }) => {
    const { t } = useTranslation();
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const { filterValue, switchValue } = useAppSelector((state) => state.filter);
    const [isFilterAll, setIsFilterAll] = useState(false);
    const [errorDate, setErrorDate] = useState(false);
    const minDate = dayjs().subtract(0, 'month').toDate();
    const [listStock, setListStock] = useState([]);
    const [detailRedistribution, setDetailRedistribution] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [recommendations, setRecommendations] = useState([]);
    const [stringRequest, setStringRequest] = useState('');
    const [loading, setLoading] = useState(true);
    const lang = localStorage.getItem('lang') || Locale.RUS;
    const { data: listStockRequest } = useGetWarehousesListQuery(`bus_unit=${filterValue.choiceDO.id}&lang=${lang}`, { skip: !isFilterAll });
    const { data: detailRedistributionRequest } = useGetRecommendationsCardInfoQuery(stringRequest, { skip: !isFilterAll });
    const [createRecommendations] = useGetRecommendationsCreateMutation();
    const [deleteRecommendations] = useGetRecommendationsDeleteMutation();
    const { permissions } = useAppSelector((state) => state.user);
    const editPermission = permissions.includes('api.add_recommendation');
    /**
     * Callback-функция для определения видимости элемента на основе значений фильтров и переключателей.
     * @function
     * @returns {boolean} - Возвращает true, если элемент должен быть видимым, и false в противном случае.
     */
    const changeIsVisible = useCallback(() => {
        if (filterValue.commodityGroup === '' ||
            filterValue.synthesisGroup === '' ||
            filterValue.choiceDO === '' ||
            !Object.values(switchValue).includes(true)) {
            return false;
        }
        else {
            return true;
        }
    }, [filterValue, switchValue]);
    /**
     * Эффект, устанавливающий состояние isFilterAll на основе значения, возвращаемого функцией changeIsVisible.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        setIsFilterAll(changeIsVisible());
    }, [changeIsVisible]);
    /**
     * Эффект, выполняющий формирование строки запроса (stringRequest)
     * на основе фильтровых значений (filterValue) и переключателей (switchValue).
     * @function
     * @returns {void}
     */
    useEffect(() => {
        const card_type = item.recommendationName === 'Негарантированные запасы РЦ' ||
            item.recommendationName === 'Not guaranteed stocks DC'
            ? 'card_type=non_guaranteed&'
            : 'card_type=without_moving&';
        const group_mtrs = filterValue.commodityGroup !== ''
            ? `group_mtrs=${filterValue.commodityGroup.id}&`
            : '';
        const analog_groups = filterValue.synthesisGroup !== ''
            ? `analog_groups=${filterValue.synthesisGroup.id}&`
            : '';
        const bus_units = filterValue.choiceDO !== ''
            ? `bus_units=${filterValue.choiceDO.id}&`
            : '';
        const years = createQueryStringFromObject(switchValue, 'years');
        setStringRequest(() => `${card_type}${group_mtrs}${analog_groups}${bus_units}${years}&lang=${lang}`);
    }, [filterValue, switchValue, item, lang]);
    /**
     * Функция для преобразования массива запасов (акций) в массив объектов с метками и идентификаторами.
     * @param {Array} arr - Массив запасов (акций) с данными.
     * @returns {Array} - Массив объектов с метками и идентификаторами запасов.
     */
    const changeStocksArr = useCallback((arr) => {
        return arr?.map((item) => ({ label: item.label, id: item.key }));
    }, []);
    /**
     * Эффект, выполняющий установку данных на основе данных listStockRequest.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (listStockRequest?.items?.length) {
            setListStock(changeStocksArr(listStockRequest.items));
            setLoading(false);
        }
        else {
            setListStock([]);
            setLoading(false);
        }
    }, [listStockRequest, changeStocksArr]);
    /**
     * Функция для поиска объекта запаса по его метке (имени).
     * @param {string} nameStock - Метка (имя) запаса для поиска.
     * @returns {Object|string} - Объект запаса с меткой и идентификатором или пустая строка, если запас не найден.
     */
    const searchArrLabel = useCallback((nameStock) => {
        const label = listStock.find((item) => item.label === nameStock);
        return label ? label : '';
    }, [listStock]);
    /**
     * Эффект, выполняющий установку данных на основе данных detailRedistributionRequest.
     * @function
     * @returns {void}
     */
    useEffect(() => {
        if (detailRedistributionRequest?.length) {
            setDetailRedistribution(detailRedistributionRequest);
            setIsActive(detailRedistributionRequest.find((item) => item.is_booked === true)
                ?.is_booked);
            setRecommendations((prevState) => detailRedistributionRequest?.map((item, index) => {
                return {
                    redistribute: item.is_booked && item.redist_quantity
                        ? item.redist_quantity
                        : prevState[index]?.redistribute
                            ? prevState[index].redistribute
                            : '',
                    stock: item.is_booked && item.new_warehouse_name
                        ? searchArrLabel(item.new_warehouse_name)
                        : prevState[index]?.stock
                            ? prevState[index].stock
                            : '',
                    data: item.is_booked && item.delivery_date
                        ? new Date(item.delivery_date.split('-').join(','))
                        : prevState[index]?.data
                            ? prevState[index].data
                            : null,
                };
            }));
            setLoading(false);
        }
        else {
            setDetailRedistribution([]);
            setLoading(false);
        }
    }, [detailRedistributionRequest, searchArrLabel]);
    /**
     * Изменяет значение фильтра в массиве рекомендаций по указанному индексу.
     * @function
     * @param {any} newValue - Новое значение фильтра.
     * @param {string} changeName - Имя фильтра для обновления.
     * @param {number} index - Индекс рекомендации, которую нужно обновить.
     */
    const changeFilter = (newValue, changeName, index) => {
        if (changeName === 'data')
            setErrorDate(false);
        setRecommendations((prevRecommendations) => {
            const updatedRecommendations = [...prevRecommendations];
            updatedRecommendations[index] = {
                ...updatedRecommendations[index],
                [changeName]: newValue,
            };
            return updatedRecommendations;
        });
    };
    /**
     * Проверяет, является ли элемент рекомендации допустимым согласно заданным условиям.
     * @function
     * @param {Object} item - Объект рекомендации для проверки.
     * @param {number} maxNumber - Максимальное значение для свойства 'redistribute'.
     * @returns {boolean} - Возвращает true, если элемент допустим, в противном случае - false.
     */
    const isValidRecommendations = (item, maxNumber) => {
        if (item.redistribute <= maxNumber && item.stock && item.data) {
            return true;
        }
        else {
            return false;
        }
    };
    /**
     * Отправляет запрос на сервер для бронирования, если рекомендация допустима.
     * @function
     * @param {Object} itemInfo - Информация о рекомендации.
     * @param {number} maxNumber - Максимальное значение для свойства 'redistribute'.
     * @param {Object} itemDetail - Детали элемента рекомендации.
     * @returns {void}
     */
    const bookingRecommendations = (itemInfo, maxNumber, itemDetail) => {
        if (!isValidRecommendations(itemInfo, maxNumber))
            return;
        setLoading(true);
        const body = {
            is_active: !!item.is_active,
            receipt_date: itemDetail.receipt_date,
            group_mtr: filterValue.commodityGroup.id,
            analog_group: filterValue.synthesisGroup.id,
            warehouse: itemDetail.old_warehouse_alias,
            new_warehouse: itemInfo.stock.id,
            delivery_year: Number(dayjs(itemInfo.data).format('YYYY')),
            delivery_month: Number(dayjs(itemInfo.data).format('MM')),
            bmu: Number(itemInfo.redistribute),
            business_unit: filterValue.choiceDO.id,
            recommendations_type: item.recommendationName === 'Негарантированные запасы РЦ' ||
                item.recommendationName === 'Not guaranteed stocks DC'
                ? 'non_guaranteed'
                : 'without_moving',
        };
        createRecommendations({ body: [body], lang }).finally(() => setLoading(false));
    };
    /**
     * Удаляет бронирование с указанными характеристиками.
     * @function
     * @param {Object} itemRecommendation - Информация о рекомендации для удаления.
     * @returns {void}
     */
    const deleteRecommendationBooking = (itemRecommendation) => {
        if (!!item.is_active)
            return;
        setLoading(true);
        const urlFilter = {
            warehouse: itemRecommendation.old_warehouse_alias,
            card_type: item.recommendationName === 'Негарантированные запасы РЦ' ||
                item.recommendationName === 'Not guaranteed stocks DC'
                ? 'non_guaranteed'
                : 'without_moving',
            group_mtrs: filterValue.commodityGroup.id,
            analog_groups: filterValue.synthesisGroup.id,
            bus_units: filterValue.choiceDO.id,
            lang,
        };
        deleteRecommendations({ urlFilter }).finally(() => setLoading(false));
    };
    /**
     * Изменяет значение ошибки для даты на true.
     * @function
     * @returns {void}
     */
    const changeErrorDate = () => {
        setErrorDate(true);
    };
    const getButtonClass = (itemDetail, isActive, recommendations) => {
        const isBooked = itemDetail.is_booked;
        const hasValidRecommendations = isValidRecommendations(recommendations, itemDetail.left_over_for_wh);
        if (isBooked) {
            if (isActive) {
                return 'button-wrap-grey';
            }
            else {
                return 'button-close-booking';
            }
        }
        else if (hasValidRecommendations) {
            return 'button-wrap-blue';
        }
        else {
            return 'button-wrap-grey';
        }
    };
    const getImageSource = (itemDetail, isActive, recommendations) => {
        if (itemDetail.is_booked && isActive) {
            return closeSyntezGgroup;
        }
        else if (itemDetail.is_booked) {
            return CloseBooking;
        }
        else if (isValidRecommendations(recommendations, itemDetail.left_over_for_wh)) {
            return FlagWhite;
        }
        else {
            return FlagGrey;
        }
    };
    const includeInCalculation = !item.is_active && isActive;
    return (_jsx(Modal, { isOpen: isModalOpen, hasOverlay: true, onEsc: changeOpenRecommendation, "data-testid": 'synthesisRecommendations', children: _jsxs("div", { className: 'synthesis-recommendations-body-wrap', children: [_jsxs("div", { className: 'synthesis-header-title', children: [_jsxs("div", { className: 'synthesis-title-wrap', children: [_jsx("img", { onClick: changeOpenRecommendation, src: CancelIcon, alt: '\u043A\u0440\u0435\u0441\u0442' }), _jsx("span", { children: item.recommendationName })] }), _jsxs("div", { className: 'recommendations-booked-DO', children: [_jsx("span", { children: t('ReservedForDC') }), _jsxs("span", { children: [item.booked_for_bu && formatNumber(item.booked_for_bu), ' ', item.booked_for_bu &&
                                            getFormatNumberQuantity(item.booked_for_bu, true, isLangRu)] })] }), editPermission && (_jsx("div", { className: 'synthesis-recommendations-buttons-wrap', children: _jsxs("div", { onClick: () => isActive && changeSwitchRequest(), className: `header-button-wrap ${includeInCalculation
                                    ? 'button-wrap-blue'
                                    : item.is_active
                                        ? 'button-close-booking'
                                        : 'button-wrap-grey'}`, children: [_jsx("img", { src: includeInCalculation
                                            ? CheckMark
                                            : item.is_active
                                                ? CloseBooking
                                                : CheckMarkGrey, alt: '\u0433\u0430\u043B\u043E\u0447\u043A\u0430' }), _jsx("span", { children: !item.is_active
                                            ? t('IncludeInCalculation')
                                            : t('ExcludeFromCalculation') })] }) }))] }), !!detailRedistribution.length && !!recommendations.length ? (_jsxs("div", { className: 'synthesis-wrap', children: [_jsxs("div", { className: 'synthesis-wrap-title-name', children: [_jsx("div", { children: t('Leftovers') }), _jsx("div", { children: t('StorageWarehouse') }), _jsx("div", { children: t('ArrivalDeadline') }), _jsx("div", { children: t('Redistribute') }), _jsx("div", { children: t('NewWarehouse') }), _jsx("div", { className: 'synthesis-wrap-title-data', children: t('DeliveryTime') })] }), _jsx("div", { className: 'synthesis-list-info', children: detailRedistribution.map((itemDetail, index) => {
                                return (_jsxs("div", { className: 'synthesis-wrap-recommendations', children: [_jsxs("div", { className: 'synthesis-recommendations-quantity', children: [_jsxs("div", { children: [_jsx("span", { children: itemDetail.left_over_for_wh
                                                                ? formatNumber(itemDetail.left_over_for_wh)
                                                                : 0 }), getFormatNumberQuantity(itemDetail.left_over_for_wh, true, isLangRu) && (_jsxs("span", { children: [' ', getFormatNumberQuantity(itemDetail.left_over_for_wh, true, isLangRu)] }))] }), itemDetail.left_over_booked && (_jsxs("div", { className: 'recommendations-booking-wrap', children: ["+", formatNumber(itemDetail.left_over_booked), itemDetail.left_over_booked &&
                                                            getFormatNumberQuantity(itemDetail.left_over_booked, true, isLangRu), t('reservation')] }))] }), _jsx("div", { className: 'synthesis-wrap-stockName', children: itemDetail.old_warehouse_name }), _jsx("div", { className: 'synthesis-wrap-stockName', children: dayjs(itemDetail.receipt_date).format('MM.YYYY') }), _jsxs("div", { className: 'redistribute-input-wrap', children: [_jsx(TextFieldComponent, { value: recommendations[index].redistribute, type: 'text', changeFilter: (newValue) => changeFilter(newValue, 'redistribute', index), filter: 'redistribute', label: '', placeholder: '0', disabled: editPermission ? itemDetail.is_booked : true, status: itemDetail.left_over_for_wh <
                                                        recommendations[index].redistribute
                                                        ? 'warning'
                                                        : undefined }), _jsx("div", { className: 'redistribute-input-title', children: unit })] }), _jsx("div", { className: 'redistribute-select-wrap', children: _jsx(SelectComponent, { items: listStock, value: recommendations[index].stock, placeholder: t('SelectWarehouse'), changeFilter: (newValue) => changeFilter(newValue, 'stock', index), filter: 'stock', disabled: editPermission ? itemDetail.is_booked : true }) }), _jsx("div", { className: 'redistribute-data-picker-wrap', children: _jsx(DatePickerComponent, { value: recommendations[index].data, type: 'month', changeFilter: (newValue) => changeFilter(newValue, 'data', index), filter: 'data', label: '', placeholder: t('MM.YYYY'), icon: false, disabled: editPermission ? itemDetail.is_booked : true, errorDate: errorDate, onErrorDate: changeErrorDate, minDate: minDate }) }), _jsxs("div", { onClick: () => editPermission && itemDetail.is_booked
                                                ? deleteRecommendationBooking(itemDetail)
                                                : bookingRecommendations(recommendations[index], itemDetail.left_over_for_wh, itemDetail), className: `header-button-wrap ${!isLangRu && 'header-button-wrap-en'} ${getButtonClass(itemDetail, item.is_active, recommendations[index])}`, "data-testid": 'button-action', children: [_jsx("img", { src: getImageSource(itemDetail, item.is_active, recommendations[index]), alt: '\u0444\u043B\u0430\u0433' }), _jsx("span", { children: itemDetail.is_booked
                                                        ? t('CancelReservation')
                                                        : t('Reserve') })] })] }, index));
                            }) })] })) : (_jsx(Placeholder, { title: t('NothingFound'), isImage: false })), loading && (_jsx("div", { className: 'overlay', children: _jsx("div", { className: 'loader-wrap', children: _jsx(LoaderComponent, {}) }) }))] }) }));
};
