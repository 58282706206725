import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Combobox } from '@consta/uikit/Combobox';
import s from './MultiSelect.module.scss';
import { Locale } from '../../../constants/enums/locale.enum';
import { useTranslation } from 'react-i18next';
export const MultiSelect = ({ label, items, value, changeFilter, filter, }) => {
    const { t } = useTranslation();
    const isLangRu = Locale.RUS === localStorage.getItem('lang');
    const isVisible = value.length > 5 || value[0]?.label === 'Все' || value[0]?.label === 'All';
    const displayValue = value[0]?.label === 'Все' || value[0]?.label === 'All'
        ? isLangRu
            ? 'Все'
            : 'All'
        : isVisible
            ? isLangRu
                ? `Выбрано элементов ${value.length}`
                : `Selected items ${value.length}`
            : value;
    return (_jsxs("div", { className: `${s['multiselect']} ${isVisible && s['multiselect-visible']}`, children: [_jsx(Combobox, { label: label, items: items, value: value, labelForEmptyItems: t('SelectPlaceholder'), multiple: true, onChange: ({ value }) => changeFilter(value, filter) }), isVisible && _jsx("div", { className: s['textInput'], children: displayValue })] }));
};
